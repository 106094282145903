import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ImgAutoHeightTextColumn } from './model-imgAutoHeightTextColumn';

@Component({
  selector: 'app-block-img-auto-height-text-column',
  templateUrl: './block-img-auto-height-text-column.component.html',
  styleUrls: ['./block-img-auto-height-text-column.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockImgAutoHeightTextColumnComponent implements OnInit {
  @Input() model: ImgAutoHeightTextColumn;
  constructor() { }

  ngOnInit() {
  }

}
