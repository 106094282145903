import { Component, ElementRef, HostListener, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from '../services/store.service';
import { ItemHeader } from './model/itemheader.model';
import { LocalizeRouterService } from 'localize-router';
import { NavigationHeaderService } from './services/navigation-header.service';
import { GetConfigurationHeaderService } from './services/get-configuration-header.service';
import { TranslateService } from '@ngx-translate/core';
import { lang } from 'moment';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {
  @ViewChild(MobileHeaderComponent, {static: false}) mobileHeaderCmp:MobileHeaderComponent;

  someOneActive: boolean;
  mainMenuData: any;
  topMenuData: any;
  mainMenuDataOrigin: any;
  itemActive: ItemHeader;
  itemMObileActive: false;
  queryParams: any;
  innerWidth: any;
  enableMmenu: boolean;
  isReady = false;
  websiteLogoUrl: string;
  buttonStore: object;
  menuList: Array<object>;
  isChild = false;
  MenuMobile = false;
  innerPosition: number;
  scrollMenu: boolean;
  languages: Array<any>;
  currentLanguage: string;
  homePageUrl: object;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (typeof event.target['className'] === 'string' && !event.target['className'].includes('nav-item')) {
      this.closeNavigation();
    }
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.itemActive) {
        this.closeNavigation();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 1150) {
      this.enableMmenu = true;
    } else {
      this.enableMmenu = false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window.scrollY >= 65) {
      this.scrollMenu = true;
    } else {
      this.scrollMenu = false;
    }
  }

  constructor(private router: Router,
              private storeService: StoreService,
              private eRef: ElementRef,
              private getConfHeaderService: GetConfigurationHeaderService,
              private navigationHeaderService: NavigationHeaderService,
              private localize: LocalizeRouterService,
              private translateService: TranslateService
  ) {
    this.innerPosition = window.scrollY;
  }

  public navigateDesktopMenu(itemClicked) {
    if (this.enableMmenu) {
      const beforeClicked = this.mainMenuData.find((x: ItemHeader) => x.active === true);
      if (beforeClicked) {
        beforeClicked['active'] = false;
      }
      itemClicked.active = !itemClicked.active;
      this.itemActive = itemClicked;
      if (!itemClicked.children) {
        this.router.navigateByUrl(this.translateService.currentLang + '/' + itemClicked.slug);
        this.closeNavigation(itemClicked);
      } else {
        this.someOneActive = true;
      }
    } else {
      if (!itemClicked.children) {
        this.router.navigateByUrl(this.translateService.currentLang + '/' + itemClicked.slug);
      }
    }
  }

  public isSomeOneActive(event) {
    this.someOneActive = event;
  }

  public closeNavigation(item?) {
    this.someOneActive = false;
    if (this.itemActive) {
      this.itemActive.active = false;
    }
  }

  public openMenuMobile() {
    this.mobileHeaderCmp.openMenuMobile();
  }

  ngOnInit() {
    this.languages = this.getConfHeaderService.getLanguageWebsite();
    this.currentLanguage = this.getConfHeaderService.getCurrentLanguage();
    this.innerWidth = window.innerWidth;
    this.enableMmenu = this.innerWidth >= 1150;
    const mainMenu = this.storeService.getMainMenu();
    const topMenu = this.storeService.getTopMenu();
    if (topMenu) {
      this.topMenuData = this.getConfHeaderService.getMenuData(topMenu);
    }
    // this.innerWidth = window.innerWidth;
    if (mainMenu) {
      this.mainMenuData = this.getConfHeaderService.getMenuData(mainMenu);
      this.mainMenuDataOrigin = this.getConfHeaderService.getMenuData(mainMenu);
      this.mainMenuData = this.navigationHeaderService.getActualRoute(this.mainMenuData);
    }
    this.websiteLogoUrl = this.getConfHeaderService.setLogo();
    this.homePageUrl = {
      url: '/' + this.translateService.currentLang
    };
    this.buttonStore = this.getConfHeaderService.getButtonStore();
    this.isReady = true;
  }

  isActive(item) {
    const currentUrl = window.location.href.split('/' + this.translateService.currentLang + '/').pop();
    return currentUrl === item.slug || item.children.some(child => child.slug === currentUrl);
  }
}
