import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-attachment',
  templateUrl: './block-attachment.component.html',
  styleUrls: ['./block-attachment.component.scss']
})
export class BlockAttachmentComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {
  }

}
