import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from './httpErrorHandler.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MetaTagsService } from './meta-tags.service';
import { MenuService } from './menu.service';
import { StateActions } from 'src/app/actions/state.actions';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppErrorHandler } from 'src/app/shared/models/error.handler';
import { catchError, switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { LocalizeRouterService } from 'localize-router';
import { menuDataSubject$, siteStructureSubject$, StoreService, openingHoursSubject$ } from './store.service';
import { BreadcrumbsService } from './breadcrumbs.service';
import { GetOpeningHoursWeekService } from './get-opening-hours-week.service';

@Injectable({
  providedIn: 'root'
})
export class RouteDataService {

  constructor(
    public httpService: HttpService,
    public router: Router,
    public route: ActivatedRoute,
    public metaTagService: MetaTagsService,
    public menuService: MenuService,
    private translateService: TranslateService,
    private location: Location,
    private actions: StateActions,
    protected stateStorage: LocalStorage,
    public httpClient: HttpClient,
    private localize: LocalizeRouterService,
    private storeService: StoreService,
    private getOpeningHoursWeekService: GetOpeningHoursWeekService
  ) { }

  public initAppStorage() {
    return this.menuService.getSiteStructure().pipe(
      tap( (appState: any) => {
        siteStructureSubject$.next(appState);
        menuDataSubject$.next(JSON.parse(appState.data.attributes.menus));
      }), switchMap(() => {
        return this.getOpeningHoursWeekService.getOpeningHours();
      }),
      tap( (hours) => {
        openingHoursSubject$.next(hours);
      })
    );
  }

  public getCurrentSlugFromRoute(): string {
    const lang = this.localize.parser.currentLang;
    let resultRoute;
    let url = window.location.href;
    const dataGet = url.indexOf('?');
    if (dataGet > 0) {
      url = url.substring(0, dataGet);
    }
    const routeSlug = url.split('/' + lang + '/');
    const redirectHomePage = routeSlug.length === 1;
    if (redirectHomePage) {
      const siteStructure = siteStructureSubject$.value;
      const relationshipFePage = siteStructure['data']['relationships'].field_front_page.data;
      if (relationshipFePage) {
        // tslint:disable-next-line: max-line-length
        const dataFePage = siteStructure['included'].filter((singleInclude) => singleInclude.id === relationshipFePage.id);
        resultRoute = dataFePage[0].attributes.field_slug;
      }
    } else {
      resultRoute = routeSlug.pop();
    }
    return resultRoute;
  }

  /**
   * Check if exist the Route item
   * @param {string} route
   * @returns {(Observable<any | AppErrorHandler>)}
   * @memberof MenuService
   */
  getItemRouteData(route: string): Observable<any | AppErrorHandler> {
    // @todo dynamic id!!!!
    const dataGet = route.indexOf('?');
    if (dataGet > 0) {
      route = route.substring(0, dataGet);
    }
    const data: string = '?_format=json&country=' + environment.localeKeyId[this.translateService.currentLang] + '&slug=' + route;
    // tslint:disable-next-line: max-line-length
    return this.httpClient.get<any>(environment.beUrl + environment.localeKeyPrefix[this.translateService.currentLang] + '/' + environment.singlepagecontent + data)
      .pipe(
        tap(resp => this.storeService.savePageData(route, resp)),
        catchError(err => this.handlePageError(err))
      );
  }

  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handlePageError(err: HttpErrorResponse): Observable<any> {
    if (err.status !== 200) {
      return of({error: err.status});
    }
    throw err;
  }

  /**
   *
   * @param {string} route
   * @returns routeObject: item of hidden menu with enpoint for the page
   * @memberof RouteDataService
   */
  public GetItemEndpoint(route) {
    const menuData = this.storeService.getMenu();
    let routeObject = null;
    routeObject = _.filter(menuData['hidden_menu'], (item: any) => {
      return item.field_route === route;
    });
    return routeObject;
  }

  /**
   * Check if there is language in the localStorage, if there isn't save it in the localStorage
   * @memberof RouteDataService
   */
  public checkLanguage() {
    const savedLanguage = localStorage.getItem('locale');
    if (savedLanguage) {
      if (savedLanguage !== this.translateService.currentLang) {
        localStorage.setItem('locale', this.translateService.currentLang);
        this.stateStorage.clear().subscribe(() => {
        });
      }
    } else {
      localStorage.setItem('locale', this.translateService.currentLang);
    }
  }

  public getLanguage() {
    return this.localize.parser.currentLang;
  }
}
