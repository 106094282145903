import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteDataService } from '../services/route-data.service';
import { LocalizeRouterService } from 'localize-router';
import { StoreService } from '../services/store.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-guards',
  template: `<div class="guard-component"></div>`,
  styles: ['.guard-component{ height: 100vh }'],
})
export class GuardsComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routeDataService: RouteDataService,
    private localize: LocalizeRouterService,
    private storeService: StoreService,
    private ngxService: NgxUiLoaderService
  ) { }

  public NotFound() {
    const lang = this.localize.parser.currentLang;
    // console.log('%cnot found!!!', 'color: #00bcd4');
    this.ngxService.stop();
    this.router.navigateByUrl(lang + '/notfound', { skipLocationChange: true });
  }

  public Notavailable() {
    const lang = this.localize.parser.currentLang;
    // console.log('%cError endpoint', 'color: #00bcd4');
    this.router.navigateByUrl(lang + '/notavailable', { skipLocationChange: true });
  }

  ngOnInit() {
    const lang = this.localize.parser.currentLang;
    const cntType = this.activatedRoute.snapshot.data.contentType;
    const existContentType = Object.keys(cntType).length !== 0;
    const errorPage = 'error' in this.activatedRoute.snapshot.data.contentType;
    if (existContentType && !errorPage) {
      const contentType = this.activatedRoute.snapshot.data.contentType.type;
      switch (contentType) {
        case 'single_page_content--project': {
          this.router.navigate(['must__project'], { skipLocationChange: true, relativeTo: this.activatedRoute.parent });
          break;
        }
        case 'single_page_content--media': {
          this.router.navigate(['must__media'], { skipLocationChange: true, relativeTo: this.activatedRoute.parent });
          break;
        }
        case 'single_page_content--person': {
          this.router.navigate(['must__person'], { skipLocationChange: true, relativeTo: this.activatedRoute.parent });
          break;
        }
        case 'single_page_content--topic_page': {
          this.router.navigate(['must__topic_page'], { skipLocationChange: true, relativeTo: this.activatedRoute.parent });
          break;
        }
        case 'single_page_content--offer': {
          this.router.navigate(['must__offer'], { skipLocationChange: true, relativeTo: this.activatedRoute.parent });
          break;
        }
        case 'single_page_content--offer_school': {
          this.router.navigate(['must__school_offer'], { skipLocationChange: true, relativeTo: this.activatedRoute.parent });
          break;
        }
        case 'single_page_content--article':
        case 'single_page_content--press_kit': {
          this.router.navigate(['must__press_kit'], { skipLocationChange: true, relativeTo: this.activatedRoute.parent });
          break;
        }
        case 'single_page_content--editorial_page':
        default: {
          this.router.navigate(['must__editorial'], { skipLocationChange: true, relativeTo: this.activatedRoute.parent });
          break;
        }
      }
    } else {
      const notavailable = 'error' in cntType && cntType.error !== 404;
      notavailable ? this.Notavailable() : this.NotFound();
    }
  }

}
