import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-external-link',
  styleUrls: ['./external-link.component.scss'],
  templateUrl: './external-link.component.html'
})
export class ExternalLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
