import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-module-header',
  templateUrl: './module-header.component.html',
  styleUrls: ['./module-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleHeaderComponent implements OnInit {
  @Input() model;
  constructor(private location: Location) { }

  navigationBack() {
    this.location.back();
  }

  ngOnInit() {
  }

}
