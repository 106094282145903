import { Component, Input, OnInit } from '@angular/core';
import { ProjectHeader } from '../../models/project-header.model';

@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss']
})
export class ProjectHeaderComponent implements OnInit {

  @Input()
  model: ProjectHeader;

  tags: Array<object> = [];

  constructor() { }

  ngOnInit() {

    for (const tag in this.model.tags) {
      if (this.model.tags.hasOwnProperty(tag)) {
        const element = this.model.tags[tag];
        this.tags.push({
          fullname: element['attributes'].name.trim(),
          tagstring: element['attributes'].name.trim().replace(' ', '').toLowerCase(),
          id: element['id']
        });
      }
    }
  }

}
