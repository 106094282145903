import { Component, Input, OnInit } from '@angular/core';
import { PreviewInnerText } from './model-preview-inner-text';

@Component({
  selector: 'app-block-preview-inner-text',
  templateUrl: './block-preview-inner-text.component.html',
  styleUrls: ['./block-preview-inner-text.component.scss']
})
export class BlockPreviewInnerTextComponent implements OnInit {
  @Input() model: PreviewInnerText;
  constructor() { }

  ngOnInit() {
  }

}
