import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModuleHomepageTitle } from './homepage-title';

@Component({
  selector: 'app-module-homepage-title',
  templateUrl: './module-homepage-title.component.html',
  styleUrls: ['./module-homepage-title.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleHomepageTitleComponent implements OnInit {
  @Input()model: ModuleHomepageTitle;

  constructor() { }

  ngOnInit() {
  }

}
