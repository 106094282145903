import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { StoreService } from './store.service';
import { GetFieldService } from './get-field.service';

@Injectable({
  providedIn: 'root'
})
export class GetConfigurationWebsiteService {

  constructor(
    private sharedService: SharedService,
    private storeService: StoreService,
    private getFieldService: GetFieldService
  ) { }

  private setConfigurationsIcon(): object {
    const icons = {
      field_goto_ref_prices: 'far fa-ticket-alt',
      field_goto_ref_calendar: 'far fa-calendar-alt',
      field_goto_ref_info: 'far fa-info-circle',
      field_goto_ref_contacts: 'far fa-comment-alt-lines',
      field_goto_ref_accesibility: 'far fa-universal-access',
    };
    return icons;
  }

  public GetConfigurationsIcon(field: string) {
    let icon = null;
    const ListIcons = this.setConfigurationsIcon();
    if (ListIcons && ListIcons[field]) {
      icon = ListIcons[field];
    }
    return icon;
  }

  private getButtonInfoDatabyIncluded(siteStructure, field: string) {
    let buttonInfoData = null;
    if (siteStructure.data.relationships[field].data) {
      buttonInfoData = this.sharedService.getRelatedContentPage(siteStructure, field);
    }
    return buttonInfoData;
  }

  private getButtonbyIncluded(siteStructure, field: string) {
    let button = null;
    const buttonInfoData = this.getButtonInfoDatabyIncluded(siteStructure, field);
    if (buttonInfoData) {
      button = {
        url: buttonInfoData.attributes.field_slug,
        title: this.getFieldService.fieldTitle(buttonInfoData),
        icon: this.GetConfigurationsIcon(field)
      };
    }
    return button;
  }

  public getButtonInfo(siteStructure) {
    return this.getButtonbyIncluded(siteStructure, 'field_goto_ref_info');
  }

  public getButtonTicket(siteStructure) {
    return this.getButtonbyIncluded(siteStructure, 'field_goto_ref_prices');
  }

  public getButtonHours(siteStructure) {
    return this.getButtonbyIncluded(siteStructure, 'field_goto_ref_timesheet');
  }

  public getButtonCalendar(siteStructure) {
    return this.getButtonbyIncluded(siteStructure, 'field_goto_ref_calendar');
  }

  public getButtonContact(siteStructure) {
    return this.getButtonbyIncluded(siteStructure, 'field_goto_ref_contacts');
  }

  public getButtonAccessibility(siteStructure) {
    return this.getButtonbyIncluded(siteStructure, 'field_goto_ref_accesibility');
  }

  public getConfigurationButtonWebiste() {
    const siteStructure = this.storeService.getSiteStructure();
    const buttonsWebsite = [];
    if (siteStructure) {
      const info = this.getButtonInfo(siteStructure);
      if (info) {
        buttonsWebsite.push(info);
      }
      const ticket = this.getButtonTicket(siteStructure);
      if (ticket) {
        buttonsWebsite.push(ticket);
      }
      const calendar = this.getButtonCalendar(siteStructure);
      if (calendar) {
        buttonsWebsite.push(calendar);
      }
      const accessibility = this.getButtonAccessibility(siteStructure);
      if (accessibility) {
        buttonsWebsite.push(accessibility);
      }
      const contact = this.getButtonContact(siteStructure);
      if (contact) {
        buttonsWebsite.push(contact);
      }
    }
    return buttonsWebsite;
  }
}
