import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { get } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ViewService } from './view.service';
import * as moment from 'moment';
import { DetailsService } from './details.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  BASE_URL = environment.beUrl;
  CALENDAR_URL = '/calendar';

  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
    private http: HttpClient,
    private viewService: ViewService,
    private detailsService: DetailsService
  ) {
    this.BASE_URL = environment.beUrl + '/' + this.translateService.currentLang;
  }

  parseCalendarModule(data, included): Promise<any> {
    const adaptedData = {
      data,
      included
    };
    return of({
      type: 'app-module-calendar',
      alertDetailData: this._getDataFromContent(adaptedData, 'field_ref_alert'),
      ticketDetailData: this._getDataFromContent(adaptedData, 'field_ref_det_tickets', 'far fa-ticket-alt'),
      reservationsDetailData: this._getDataFromContent(adaptedData, 'field_ref_det_reservations', 'far fa-question-circle ')
    }).toPromise();
  }

  getCalendarEventsForCurrentMonth() {
    const today = new Date();
    const url = this.BASE_URL + this.CALENDAR_URL + '/' + today.getFullYear() + '/' + ( today.getMonth() + 1 );
    return this.http.get(url);
  }

  getEventsBlocks(date: Date) {
    const targetDay: any = moment(date);
    const url = this.BASE_URL + this.CALENDAR_URL + '/list';
    const params = {
      _format: 'json',
      start: targetDay.format('YYYY-MM-DD'),
      end: targetDay.add(1, 'day').format('YYYY-MM-DD')
    };
    return this.http.get(url, { params })
      .pipe(
        map(r => {
          return r || [];
        }),
        map(n => this.viewService.getListBlocks(n))
      );
  }

  private _getDataFromContent(data, field: string, icon?: string) {
    let detailBlock = null;
    const contentData = this.sharedService.getRelatedContentPage(data, field);
    if (contentData) {
      this.detailsService.getDetailCta(contentData, data).subscribe(
        arrayListCta => {
          detailBlock.cta = arrayListCta;
        }
      );
      detailBlock = {
        title: get(contentData, 'attributes.field_title_bold.processed'),
        content: get(contentData, 'attributes.field_content.processed'),
        icon
      };
    }
    
    return detailBlock;
  }
}
