import { Injectable } from '@angular/core';
import { ctaDetailBlock, DetailBlock, DetailBlockIconLabel } from 'src/app/shared/blocks/block-detail/model-detail';
import { forkJoin, Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { LocalizeRouterService } from 'localize-router';
import { environment } from 'src/environments/environment';
import { StoreService } from './store.service';
import { GetMediaEntityService } from './get-media-entity.service';
import { get, extend } from 'lodash';
import * as moment from 'moment';
import { GetFieldService } from './get-field.service';

@Injectable({
  providedIn: 'root'
})
export class DetailsService {
  constructor(
    private sharedService: SharedService,
    private localize: LocalizeRouterService,
    private storeService: StoreService,
    private getMediaEntityService: GetMediaEntityService,
    private getFieldService: GetFieldService
  ) { }

  /**
   * Function that define all possible icons of detail blocks, key = field machine name, value = icon class
   * @private
   * @returns {object} object with all icons of details block
   * @memberof DetailsService
   */
  private setDetailsIcon(): object {
    const icons = {
      field_rel_when: 'far fa-clock',
      field_related_price: 'far fa-ticket-alt',
      field_related_where: 'far fa-map-marker-alt',
      field_related_accessibility: 'far fa-universal-access',
      field_related_alert: 'far fa-exclamation-triangle',
      field_related_reservation: 'far fa-map-marker-question',
      field_scienzabile: 'scienziabile-icon',
      field_storage: 'far fa-map-marker-alt',
      field_related_participants: 'fal fa-user-friends'
    };
    return icons;
  }

  /**
   * Function for get icon class by field machine name
   * @param {string} field field machine name
   * @returns {string} class for icon of detail block
   * @memberof DetailsService
   */
  public GetDetailIcon(field: string) {
    let icon = null;
    const ListIcons = this.setDetailsIcon();
    if (ListIcons && ListIcons[field]) {
      icon = ListIcons[field];
    }
    return icon;
  }

  /**
   * the function return object of cta internal, link to related content
   * @private
   * @param {object} relatedContent
   * @returns {ctaDetailBlock} cta internal, with target_blank false
   * @memberof DetailsService
   */
  private getDetailCtaInternal(relatedContent) {
    return new Promise((resolve, reject) => {
      const lang = this.localize.parser.currentLang;
      const ctaInternal: ctaDetailBlock = {
        label: this.getFieldService.fieldTitle(relatedContent),
        // @todo check language to link
        url: environment.localeKeyPrefix[lang] + '/' + relatedContent.attributes.field_slug,
        target: false
      };
      resolve(ctaInternal);
    });
  }

  /**
   * The function return object of external cta, url and label are editorial fields
   * @private
   * @param {object} singleCta
   * @returns {ctaDetailBlock} cta external, with target_blank true
   * @memberof DetailsService
   */
  private getDetailCtaExternal(singleCta) {
    return new Promise((resolve, reject) => {
      const ctaExternal: ctaDetailBlock = {
        label: singleCta.title,
        url: singleCta.uri,
        target: true
      };
      resolve(ctaExternal);
    });
  }

  /**
   * The function concatenate External cta Array with Internal cta Array, return array of all cta of the detail block: internal and external
   * @private
   * @param {object} detailData
   * @param {object} PageData Json api response of single page content
   * @returns {Array<ctaDetailBlock>}
   * @memberof DetailsService
   */
  public getDetailCta(detailData, PageData) {
    return new Observable((observer) => {
      const arrayDetailCta = [];
      if (detailData.attributes.field_ref_rel_external_content) {
        detailData.attributes.field_ref_rel_external_content.forEach(ctaExternal => {
          arrayDetailCta.push(this.getDetailCtaExternal(ctaExternal));
        });
      }
      if (detailData.relationships.field_ref_rel_internal_content && detailData.relationships.field_ref_rel_internal_content.data) {
        detailData.relationships.field_ref_rel_internal_content.data.forEach(ctaInternal => {
          const relatedContent = this.sharedService.getRelatedContentById(PageData, ctaInternal.id);
          if (relatedContent) {
            arrayDetailCta.push(this.getDetailCtaInternal(relatedContent));
          }
        });
      }
      forkJoin(arrayDetailCta).subscribe(
        (cta: Array<ctaDetailBlock>) => {
          observer.next(cta);
          observer.complete();
        }
      );
    });
  }

  public setDetailCustom(PageData, field): Promise<DetailBlock> {
    return new Promise((resolve, reject) => {
      let detailBlockCustom = null;
      if (PageData.data.attributes.field_duration) {
        detailBlockCustom = {
          detail : {
            label: PageData.data.attributes.field_duration,
            icon: this.GetDetailIcon(field)
          }
        };
      }
      resolve(detailBlockCustom);
    });
  }

  /**
   * If the detail block has image the function return the Url
   * @private
   * @param {object} detailObject
   * @param {object} PageData
   * @returns {string}
   * @memberof DetailsService
   */
  private getDetailImage(detailObject, PageData): string {
    let detailImage = null;
    const detailImageData = detailObject.relationships.field_feat_image_media;
    if (detailImageData && detailImageData.data) {
        detailImage = this.getMediaEntityService.getFieldImageUrlbyMeta(detailImageData.data);
    }
    return detailImage;
  }

  public normalizeIncomingEvents(datesArrays) {
    return datesArrays.map(pair => {
      const startDate = moment(pair['start_date'] * 1000);
      const endDate = moment(pair['end_date'] * 1000);
      const label = startDate.format('D MMMM YYYY');
      return {
        label,
        startTime: startDate.format('HH:mm'),
        endTime: endDate.format('HH:mm')
      };
    });
  }

  public normalizeFieldDateExploded(datesArrays) {
    return datesArrays.map(pair => {
      const startDate = moment(pair['value']);
      const endDate = moment(pair['end_value']);
      const label = startDate.format('D MMMM YYYY');
      return {
        label,
        startTime: startDate.format('HH:mm'),
        endTime: endDate.format('HH:mm')
      };
    });
  }

  public setDatesFromOffer(detailBlock, pageDate) {
    const stringDate = get(pageDate, 'data.attributes.field_incoming_events_string');
    if (stringDate) {
      detailBlock.date = {
        label: stringDate,
        icon: 'far fa-calendar-alt'
      };
    }
    const incomingEvents = get(pageDate, 'data.attributes.field_date_exploded', '[]');
    if (incomingEvents) {
      const dates = this.normalizeFieldDateExploded(incomingEvents);
      const duration = get(pageDate, 'data.attributes.field_duration');
      detailBlock.when = {
        dates: dates.slice(0, 5),
        duration
      };
    }
    return detailBlock;
  }

  /**
   * The function set detail object with all property
   * @param {object} detailData the object of related content detail
   * @param {object} [PageData] the jsonapi response
   * @param {object} [type] the type of detail block for get icon
   * @returns {DetailBlock}
   * @memberof DetailsService
   */
  public setDetail(detailData, PageData, type?: string) {
    return new Promise((resolve, reject) => {
      let listCta = null;
      let detailBlock: DetailBlock = {
        title: (detailData.attributes.field_title_bold) ? detailData.attributes.field_title_bold.value : null,
        content: (detailData.attributes.field_content) ? detailData.attributes.field_content.value : null,
        icon: (type) ? this.GetDetailIcon(type) : null,
        image: this.getDetailImage(detailData, PageData)
      };
      if (type && type === 'field_rel_when') {
        detailBlock = extend(detailBlock, this.setDatesFromOffer(detailData, PageData));
      }
      if (type && type === 'field_related_where') {
        const websiteMap = this.storeService.getWebsiteMap();
        if (websiteMap) {
          detailBlock.attachmentLink = [{
            label: 'scarica la mappa',
            url: websiteMap
          }];
        }
      }
      if (type && type === 'field_related_alert') {
        detailBlock.layout = 'alert';
      }
      this.getDetailCta(detailData, PageData).subscribe(
        arrayListCta => {
          listCta = arrayListCta;
          detailBlock.cta = listCta;
        }
      );
      resolve(detailBlock);
    });

  }

}
