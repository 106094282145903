import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModuleVideo } from './video';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-module-video',
  templateUrl: './module-video.component.html',
  styleUrls: ['./module-video.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleVideoComponent implements OnInit {
  @Input()model: ModuleVideo;

  VideoInit: boolean;
  isYoutube: boolean;
  iframeUrl: SafeResourceUrl;
  ytEmbedUrl: string;
  ytPlayUrl: string;
  ytId: string;
  isReady = false;
  constructor(public sanitizer: DomSanitizer) { }


  ngOnInit() {
    this.isYoutube = this.isYoutubeUrl(this.model.url);
    if (this.isYoutube) {

      this.ytId = this.ytVidId(this.model.url);
      this.ytEmbedUrl = 'https://www.youtube-nocookie.com/embed/' + this.ytId;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.ytEmbedUrl);
    }
    this.isReady = true;
  }
  isYoutubeUrl(url) {
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return (url.match(p)) ? true : false;
  }
  ytVidId(url) {
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return (url.match(p)) ? RegExp.$1 : '';
  }

}
