import { AnyAction } from 'redux';
import { StateActions } from './actions/state.actions';

export interface  AppState {
    env: string;
}
export interface IAppState {
    siteStructure: {};
    currentPageData: {};
    alternateLanguages: {};
    breadcrumbs: {};
    menuData: object;
    lastUpdate: Date;
    countries: [];
}

export const INITIAL_STATE: IAppState = {
    siteStructure: {},
    currentPageData: {},
    alternateLanguages: {},
    breadcrumbs: {},
    menuData: {},
    lastUpdate: null,
    countries: []
};


export function rootReducer(state: IAppState, action: AnyAction): IAppState {
    switch (action.type) {
        case StateActions.LOAD_FROM_STORAGE:
            state = action.appState;
            break;
        case StateActions.SET_SITE_STRUCTURE:
            action.structure.data.attributes.menus = {};
            state.siteStructure = action.structure;
            break;
        case StateActions.SET_CURRENT_PAGE_DATA:
            state.currentPageData = action.data;
            break;
        case StateActions.SET_ALTERNATE_LANGUAGES:
            state.alternateLanguages = action.data;
            break;
        case StateActions.SET_SITE_MENUS:
            state.menuData = action.menus;
            break;
        case StateActions.SET_BREADCRUMBS_PAGE_DATA:
            state.breadcrumbs = action.breadcrumbs;
            break;
        case StateActions.SET_SITE_COUNTRIES:
            state.countries = action.countries;
            break;
        case StateActions.SET_ITEM_HIDDEN_MENU:
            // @todo remove from object to array and from array to object
            if (!state.menuData['hidden_menu']) {
                state.menuData['hidden_menu'] = [];
            }
            state.menuData['hidden_menu'].push(action.itemHiddenMenu);
            break;
    }
    if (state) {
        state.lastUpdate = new Date();
    }
    return state;
}
