import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page-title',
  templateUrl: './home-page-title.component.html',
  styleUrls: ['./home-page-title.component.scss']
})
export class HomePageTitleComponent implements OnInit {

  @Input()
  data: any;

  constructor() {
  }

  ngOnInit() {
  }

}
