import { Injectable } from '@angular/core';
import { Action, AnyAction } from 'redux';

@Injectable()
export class StateActions {
  static LOAD_FROM_STORAGE = 'LOAD_FROM_STORAGE';
  static SAVE_TO_STORAGE = 'SAVE_TO_STORAGE';
  static EMPTY_STATE = 'EMPTY_STATE';
  static SET_SITE_STRUCTURE = 'SET_SITE_STRUCTURE';
  static SET_CURRENT_PAGE_DATA = 'SET_CURRENT_PAGE_DATA';
  static SET_BREADCRUMBS_PAGE_DATA = 'SET_BREADCRUMBS_PAGE_DATA';
  static SET_SITE_MENUS = 'SET_SITE_MENUS';
  static SET_SITE_COUNTRIES = 'SET_SITE_COUNTRIES';
  static SET_ALTERNATE_LANGUAGES = 'SET_ALTERNATE_LANGUAGES';
  static SET_ITEM_HIDDEN_MENU = 'SET_ITEM_HIDDEN_MENU';


  loadFromStorage(state: any): AnyAction {
    return { type: StateActions.LOAD_FROM_STORAGE, appState: state };
  }
  saveToStorage(): Action {
    return { type: StateActions.SAVE_TO_STORAGE };
  }
  emptyState(): Action {
    return { type: StateActions.EMPTY_STATE };
  }

  setSiteStructure(siteStructure: []): AnyAction {
    return { type: StateActions.SET_SITE_STRUCTURE, structure: siteStructure };
  }

  setCurrentPageData(pageData: []): AnyAction {
    return { type: StateActions.SET_CURRENT_PAGE_DATA, data: pageData };
  }

  setAlternateLanguages(multilanguageData: []): AnyAction {
    return { type: StateActions.SET_ALTERNATE_LANGUAGES, data: multilanguageData };
  }

  setBreadcrumbsPageData(breadcrumbs: object): AnyAction {
    return { type: StateActions.SET_BREADCRUMBS_PAGE_DATA, breadcrumbs };
  }

  setSiteMenus(menuData: object): AnyAction {
    return { type: StateActions.SET_SITE_MENUS, menus: menuData };
  }

  setSiteCountries(countriesData: object): AnyAction {
    return { type: StateActions.SET_SITE_COUNTRIES, countries: countriesData };
  }

  SetItemHiddenMenu(ItemHiddenMenu: object): AnyAction {
    return { type: StateActions.SET_ITEM_HIDDEN_MENU, itemHiddenMenu: ItemHiddenMenu };
  }
}
