import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { GetFieldService } from './get-field.service';
import { GetCtaService } from './get-cta.service';
import { ImgTextColumn, IconPlusText } from 'src/app/shared/blocks/block-img-text-column/model-imgTextColumn';
import { GetMediaEntityService } from './get-media-entity.service';
import { Slide } from 'src/app/shared/modules/module-slider/model-slider';
import { ImgAutoHeightTextColumn } from 'src/app/shared/blocks/block-img-auto-height-text-column/model-imgAutoHeightTextColumn';
import { GetOfferDataService } from './get-offer-data.service';
import { ImgTextRow } from 'src/app/shared/blocks/block-img-text-row/model-imgTextRow';
import { AlertBlock } from 'src/app/shared/blocks/block-alert/model-alert';
import { DocumentsAndLinks } from 'src/app/shared/blocks/block-documents-and-links/documentsAndLinks.model';
import { get } from 'lodash';
import { MustLinkMapper } from './must-link.service';
import { TextImgColumn } from 'src/app/shared/blocks/block-text-img-column/model-textImgColumn';
import { Title } from '@angular/platform-browser';
import { Iframe } from 'src/app/shared/blocks/block-iframe/iframe.model';
import { openingHoursSubject$, StoreService } from './store.service';
import { ViewService } from './view.service';
import { DetailsService } from './details.service';

@Injectable({
  providedIn: 'root'
})
export class NormalizeBlockPreviewService {

  constructor(
    private getFieldService: GetFieldService,
    private getCtaService: GetCtaService,
    private sharedService: SharedService,
    private getMediaEntityService: GetMediaEntityService,
    private getOfferDataService: GetOfferDataService,
    private storeService: StoreService,
    private viewService: ViewService,
    private detailsService: DetailsService
  ) { }

  private setIcon(): object {
    const icons = {
      field_rel_when: 'far fa-clock',
      field_related_price: 'far fa-ticket-alt',
      field_related_where: 'far fa-map-marker-alt',
      field_related_accessibility: 'far fa-universal-access',
      field_related_alert: 'far fa-exclamation-triangle',
      field_ilab_filter: 'far fa-map-marker-question',
      field_scienzabile: 'scienziabile-icon',
      field_storage: 'far fa-map-marker-alt'
    };
    return icons;
  }

  private GetIcon(field: string) {
    let icon = null;
    const ListIcons = this.setIcon();
    if (ListIcons && ListIcons[field]) {
      icon = ListIcons[field];
    }
    return icon;
  }

  private getIconPlusText(title, keyIcon): IconPlusText {
    return {
      title,
      icon: this.GetIcon(keyIcon),
    };
  }

  private getImagebyPreviewBlock(block, included): string {
    let url = null;
    if (block.relationships.field_background_image_media && block.relationships.field_background_image_media.data) {
      const imageData = block.relationships.field_background_image_media;
      url = this.getMediaEntityService.getFieldImageUrlbyMeta(imageData.data);
    }
    return url;
  }

  private getPreviewImagebyPreviewBlock(block): string {
    let url = null;
    let data = block.relationships != null ? block.relationships : null;
    data = (data == null && (block.data != null && block.data.relationships != null)) ? block.data.relationships : data;
    if (data == null) {
      return null;
    }
    if (data.field_preview_image_media && data.field_preview_image_media.data != null) {
      const imageData = data.field_preview_image_media;
      url = this.getMediaEntityService.getFieldImageUrlbyMeta(imageData.data);
    }
    return url;
  }

  /**
   * Method just for preview block. The method get the related content connected with the block
   * @private
   * @param {*} block
   * @param {*} included
   * @returns
   * @memberof NormalizeBlockPreviewService
   */
  private getRelatedContent(block, included) {
      let relatedContentData = null;
      const entiyPage = this.sharedService.composeEntityPage(block, included);
      const relatedContent = this.sharedService.getRelatedContentPage(entiyPage, 'field_related_content');
      if (relatedContent) {
        relatedContentData = relatedContent;
      }
      return relatedContentData;
  }

  private getPreviewLayout(block, included): string {
    let layout = 'general';
    const relatedContent =  this.getRelatedContent(block, included);
    if (relatedContent) {
      //@todo martina
    }
    return layout;
  }

  private getTaxonomyNameRelated(relatedEntity, taxonomyField): string {
    let taxonomyNameRelated = null;
    const taxonomyData = get(relatedEntity, 'data.relationships.' + taxonomyField);
    if (taxonomyData) { // if has taxonomy
      const taxonomyRelatedData = this.sharedService.getRelatedContentById(relatedEntity, taxonomyData.data.id);
      taxonomyNameRelated = get(taxonomyRelatedData, 'attributes.name');
    }
    return taxonomyNameRelated;
  }

  private getAllTaxonomyNamesRelated(relatedEntity, taxonomyField): string {
    let taxonomyNamesRelated = null;
    const taxonomyDataArray = get(relatedEntity, 'data.relationships.' + taxonomyField);
    if (taxonomyDataArray) { // if has taxonomy
      const taxonomies = this.sharedService.getRelatedContentArrayPage(relatedEntity, taxonomyField);
      if (taxonomies.length > 0) {
        taxonomyNamesRelated = taxonomies.slice(0, 3).map(taxonomy => {
          if (taxonomy !== null) {return taxonomy.attributes.name;
          }}).toString();
      }
    }
    return taxonomyNamesRelated;
  }

  private getSingleTaxonomyRelated(relatedEntity, taxonomyField): IconPlusText {
    let taxonomyRelated = {} as IconPlusText;
    const taxonomyName = this.getTaxonomyNameRelated(relatedEntity, taxonomyField);
    if (taxonomyName) {
      taxonomyRelated = this.getIconPlusText(taxonomyName, taxonomyField);
    }
    return taxonomyRelated;
  }

  private getMultiTaxonomyRelated(relatedEntity, taxonomyField): IconPlusText {
    let taxonomyRelated = {} as IconPlusText;
    const taxonomyName = this.getAllTaxonomyNamesRelated(relatedEntity, taxonomyField);
    if (taxonomyName) {
      taxonomyRelated = this.getIconPlusText(taxonomyName, taxonomyField);
    }
    return taxonomyRelated;
  }

  /**
   * Method that get data of preview block with interface ImgTextColumn (editorial_slide_block--editorial_page_preview_block)
   * @private
   * @param {*} block
   * @param {*} included
   * @returns {ImgTextColumn}
   * @memberof NormalizeBlockPreviewService
   */
  private normalizeBlockImageTextColumn(block, included): ImgTextColumn {
      const cta = this.getCtaService.getCtabyPreviewBlock(block, included);
      const title = this.getFieldService.fieldTitle(block);
      const img = this.getImagebyPreviewBlock(block, included);
      const previewBlock: ImgTextColumn = {
        block_type: 'ImgTextColumn',
        typology: get(block, 'attributes.field_label'),
        title,
        cta,
        img,
        abstractText: (block.attributes.field_content) ? block.attributes.field_content.value : null,
        previewLayout: 'generical'
      };
      return previewBlock;
  }

  private getEventsDateByPreview(relatedContent) {
    let date = null;
    date = {
      title : get(relatedContent, 'attributes.field_incoming_events_string'),
      icon: 'far fa-calendar-alt'
    };
    const fieldIncomingEvents = get(relatedContent, 'attributes.field_incoming_events', '[]');
    if (!date.title && fieldIncomingEvents) {
      const datesArrays = JSON.parse(fieldIncomingEvents).map(JSON.parse);
      if (datesArrays) {
        const allDates = this.detailsService.normalizeIncomingEvents(datesArrays);
        if (allDates.length > 0) {
          date.title = allDates[0].label;
        }
      }
    }

    return (date.title) ? date : null;
  }

  /**
   *
   *  Method that get all data of one offer/offer school page by the preview block
   * @private
   * @param {*} block
   * @param {*} included
   * @returns {ImgTextColumn}
   * @memberof NormalizeBlockPreviewService
   */
  private normalizeBlockImageTextColumnOffer(block, included): ImgTextColumn {
    const entityPage = this.sharedService.composeEntityPage(block, included);
    const relatedContent = this.sharedService.getRelatedContentPage(entityPage, 'field_related_content');
    const isOfferPage = (relatedContent) ? (relatedContent.type === 'single_page_content--offer') : null;
    const entityPageRelated = this.sharedService.composeEntityPage(relatedContent, included);
    const cta = this.getCtaService.getCtaRelatedContentbyPreviewBlock(entityPage);
    const title = this.getFieldService.fieldTitle(relatedContent);
    const img = this.getPreviewImagebyPreviewBlock(entityPageRelated);
    const previewLayout = this.getOfferDataService.getOfferPreviewLayout(entityPageRelated);
    const age = this.getOfferDataService.getOfferAge(entityPageRelated);
    const newOffer = this.getOfferDataService.getOfferNew(entityPageRelated);
    const abstractText = this.getOfferDataService.getAbstract(entityPageRelated);
    const typology = this.getTaxonomyNameRelated(entityPageRelated, 'field_typology');
    // tslint:disable-next-line: max-line-length
    const duration = (relatedContent && (relatedContent.attributes != null) && relatedContent.attributes.field_duration) ? this.getIconPlusText(relatedContent.attributes.field_duration, 'field_duration') : null;
    const location = this.getMultiTaxonomyRelated(entityPageRelated, 'field_ilab_filter');
    // tslint:disable-next-line: max-line-length
    const tag = (isOfferPage) ? this.getMultiTaxonomyRelated(entityPageRelated, 'field_tag') : this.getMultiTaxonomyRelated(entityPageRelated, 'field_subject');
    const dateAndTime = this.getEventsDateByPreview(relatedContent);
    const previewBlock: ImgTextColumn = {
      block_type: 'ImgTextColumn',
      title,
      cta,
      img,
      age,
      new: newOffer,
      previewLayout,
      abstractText,
      typology,
      tag,
      // tslint:disable-next-line: max-line-length
      extraTicket: (relatedContent) && (relatedContent.attributes != null ) && (relatedContent.attributes.field_extra_ticket) ? this.viewService.getIconPreviewBlock(relatedContent.attributes.field_extra_ticket, 'field_extra_ticket') : null,
      // tslint:disable-next-line: max-line-length
      bookable: (relatedContent) && (relatedContent.attributes != null ) && (relatedContent.attributes.field_booking) ? this.viewService.getIconPreviewBlock(relatedContent.attributes.field_booking, 'field_booking') : null,
      note: get(entityPageRelated, 'data.attributes.field_note'),
      dateAndTime,
      // tslint:disable-next-line: max-line-length
      scienziabile: !!((relatedContent) && (relatedContent.relationships != null) && (relatedContent.relationships.field_scienzabile.data)),
      location,
      duration,
      // tslint:disable-next-line: max-line-length
      accessibility: (relatedContent) && (relatedContent.relationships != null) && (relatedContent.relationships.field_accessibility.data.length > 0),
    };
    return previewBlock;
  }

  private normalizeBlockImageAutoHeightTextColumn(block, included): ImgAutoHeightTextColumn {
    const entityPage = this.sharedService.composeEntityPage(block, included);
    // tslint:disable-next-line: max-line-length
    const cta = this.getCtaService.getCtabyPreviewBlock(block, included);
    const title = this.getFieldService.fieldTitle(block);
    const img = this.getImagebyPreviewBlock(block, included);
    const previewBlock: ImgAutoHeightTextColumn = {
      block_type: 'ImgAutoHeightTextColumn',
      title,
      cta,
      img
    };
    return previewBlock;
  }

  private normalizeBlockImageTextRow(block, included): ImgTextRow {
    const cta = this.getCtaService.getCtabyPreviewBlock(block, included);
    const title = this.getFieldService.fieldTitle(block);
    const img = this.getImagebyPreviewBlock(block, included);
    const content = (block.attributes.field_content) ? block.attributes.field_content.value : null;
    const label = block.attributes.field_label || block.attributes.field_type_of_content_label;
    const dateAndTime = (block.attributes.field_date) ? block.attributes.field_date : null;
    const previewBlock: ImgTextRow = {
      block_type: 'ImgTextRow',
      title,
      cta,
      img,
      content,
      label,
      dateAndTime
    };
    return previewBlock;
  }

  private normalizeBlockAlert(block): AlertBlock {
    const title = this.getFieldService.fieldTitle(block);
    const content = (block.attributes.field_content) ? block.attributes.field_content.value : null;
    const previewBlock: AlertBlock  = {
      block_type: 'AlertBlock',
      title,
      content
    };
    return previewBlock;
  }

  private normalizeDocumentAndLink(block, included): DocumentsAndLinks {
    const title = this.getFieldService.fieldTitle(block);
    const relatedContent = this.getCtaService.getCtabyPreviewBlock(block, included);
    const content = get(block, 'attributes.field_content.value');
    const attachmentLink = get(block, 'attributes.field_link', []).map(MustLinkMapper.parseLink);
    const fileDownloadToNormalize = get(block, 'relationships.field_files.data', []).map(MustLinkMapper.parseAttachment);
    /**
     * MustLinkMapper nasce come classe di metodi statici,
     * andrebbe ricostruito qusto flusso
     * perchè MustLinkMapper.parseAttachment ha bisogno dell’injection di getMediaEntityService */
    const fileDownload = fileDownloadToNormalize.map(file => {
      if (file.url) {
        return {
          url: this.getMediaEntityService.setDefaultImageUrl(file.url),
          fileExtention: file.fileExtention,
          label: file.label,
          target: file.target,
          download: file.download
        };
      }
    });
    const ctaExternal = get(block, 'attributes.field_external_url', []).map(MustLinkMapper.parseExternalLink);
    const previewBlock: DocumentsAndLinks  = {
      block_type: 'DocumentsAndLinks',
      title,
      content,
      relatedContent,
      attachmentLink,
      fileDownload,
      ctaExternal
    };
    return previewBlock;
  }

  private normalizeWysiwygText(block, included): TextImgColumn {
    const title = this.getFieldService.fieldTitle(block);
    const content = (block.attributes.field_content) ? block.attributes.field_content.value : null;
    const img = this.getImagebyPreviewBlock(block, included);
    const attachmentToNormalize = get(block, 'relationships.field_files.data', []).map(MustLinkMapper.parseDownload);
    const attachment = attachmentToNormalize.map(file => {
      if (file.url) {
        return {
          url: this.getMediaEntityService.setDefaultImageUrl(file.url),
          fileExtention: file.fileExtention,
          label: file.label,
          target: file.target,
          download: file.download
        };
      }
    });

    const relatedContent = this.sharedService.getRelatedArraybyIncludedObject(block, 'field_related_content', included).map(MustLinkMapper.parseRelatedContent);
    const previewBlock: TextImgColumn  = {
      block_type: 'TextImgColumn',
      title,
      content,
      img,
      attachment,
      relatedContent
    };
    return previewBlock;
  }

  private normalizeIframe(block): Iframe {
    const title =  get(block, 'attributes.field_title_bold');
    const code = block.attributes.field_code;
    const previewBlock: Iframe = {
      block_type: 'Iframe',
      title,
      code,
    };
    return previewBlock;
  }

  private normalizeOpeningHours() {
    const hours = this.storeService.getOpeningHours();
    return  {
      block_type: 'OpeningHours',
      hours,
      today: hours.filter((day: any ) => day.isToday === true)
    };
  }


  private switchTypeBlockPreview(blockData, included) {
    if (blockData === null) {
      return null;
    }
    const type = blockData.type;
    let block = null;
    switch (type) {
      // case 'editorial_slide_block--editorial_slide_block':
      case 'editorial_slide_block--editorial_page_preview_block':
        block = this.normalizeBlockImageTextColumn(blockData, included);
        break;
      case 'editorial_slide_block--offer_preview_block':
      case 'editorial_slide_block--offer_school_preview_block':
        block = this.normalizeBlockImageTextColumnOffer(blockData, included);
        break;
      case 'editorial_slide_block--media_preview_block':
          block = this.normalizeBlockImageAutoHeightTextColumn(blockData, included);
          break;
      case 'editorial_slide_block--editorial_slide_block':
      case 'editorial_slide_block--press_kit_preview_block':
      case 'editorial_slide_block--article_preview_block':
      case 'editorial_slide_block--article_topic_person':
        block = this.normalizeBlockImageTextRow(blockData, included);
        break;
      case 'editorial_slide_block--detail_block':
      case 'editorial_slide_block--alert_block':
        block = this.normalizeBlockAlert(blockData);
        break;
      case 'editorial_slide_block--documents_links_block':
        block = this.normalizeDocumentAndLink(blockData, included);
        break;
      case 'editorial_slide_block--wysiwyg_text':
        block = this.normalizeWysiwygText(blockData, included);
        break;
      case 'editorial_slide_block--iframe_block':
        block = this.normalizeIframe(blockData);
        break;
      case 'editorial_slide_block--opening_days_block':
        block = this.normalizeOpeningHours();
        break;
      default:
        block = this.normalizeBlockImageTextColumn(blockData, included);
        break;
    }
    return block;
  }

  public getPreviewBlock(blockData, included) {
    return this.switchTypeBlockPreview(blockData, included);
  }

  private normalizeEditorialSlideBlock(dataSlide): Slide{
    const imageData = dataSlide.relationships.field_background_image_media.data;
    const slide = {
      title: (dataSlide.attributes.field_title_bold) ? dataSlide.attributes.field_title_bold.value : null,
      cta: '',
      url: '',
      image: (imageData) ? this.getMediaEntityService.getFieldImageUrlbyMeta(imageData) : null
    };
    return slide;
  }

  public getEditorialSlideBlock(data) {
    return this.normalizeEditorialSlideBlock(data);
  }
}
