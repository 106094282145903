import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BlockDataNormalizerService } from 'src/app/core/services/block-data-normalizer.service';
import { ModuleDataNormalizerService } from 'src/app/core/services/module-data-normalizer.service';
import { forkJoin } from 'rxjs';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'app-module-topic-masonry',
  templateUrl: './module-topic-masonry.component.html',
  styleUrls: ['./module-topic-masonry.component.scss']
})
export class ModuleTopicMasonryComponent implements OnInit, OnChanges {
  @Input() model;
  blockSwitch: Array<object>;

  options: NgxMasonryOptions = {};

  constructor(
    private dataNormalizerService: BlockDataNormalizerService,
    private moduleDataNormalizerService: ModuleDataNormalizerService
  ) { }

  ngOnInit() {
    this.options = {
      gutter: 40,
      transitionDuration: '0s'
    };
    this.loadGridData();
  }

  ngOnChanges() {
    this.loadGridData();
  }


  loadGridData() {
    const promiseArray = [];
    this.model.blocks.forEach(singleBlock => {
        switch (singleBlock.type) {
          case 'editorial_slide_block--project_preview_block':
          case 'editorial_slide_block--media_preview_block':
              promiseArray.push(this.moduleDataNormalizerService.multipurpose_preview_outer_text([singleBlock], this.model.includes));
              break;
          case 'editorial_slide_block--article_topic_person':
              promiseArray.push(this.moduleDataNormalizerService.multipurpose_block_preview_card([singleBlock], this.model.includes));
              break;
          case 'editorial_slide_block--editorial_page_preview_block':
              promiseArray.push(this.moduleDataNormalizerService.multipurpose_preview_inner_text([singleBlock], this.model.includes));
              break;
          default:
              break;
        }
    });
    forkJoin(promiseArray).subscribe(
      (data: Array<object>) => {
        this.blockSwitch = data;
      }
    );
  }

}
