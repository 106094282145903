import { Component, Input, OnInit } from '@angular/core';
import { Project } from './../../models/project.model';

@Component({
    selector: 'app-project-carousel-navigation',
    templateUrl: './project-carousel-navigation.component.html',
    styleUrls: ['./project-carousel-navigation.component.scss']
})

export class ProjectCarouselNavigationComponent implements OnInit {
    @Input() project: Project;


    constructor() {}
    ngOnInit() {
    }
}
