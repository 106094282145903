import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModuleTextImageTwoCols } from './text-image-two-cols';

@Component({
  selector: 'app-module-text-image-two-cols',
  templateUrl: './module-text-image-two-cols.component.html',
  styleUrls: ['./module-text-image-two-cols.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleTextImageTwoColsComponent implements OnInit {
  @Input()model: ModuleTextImageTwoCols;

  constructor() { }

  ngOnInit() {
  }

}
