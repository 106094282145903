import { Injectable } from '@angular/core';
import { FooterSocials } from '../footer/footersocials.model';
import { siteStructureSubject$ } from './store.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
footerSocials: FooterSocials = {};

  constructor(
    private sharedService: SharedService
  ) { }

  getFooterSocials() {
    const siteStructure = siteStructureSubject$.value
    this.footerSocials = {};
    if (siteStructure['data']) {
      this.footerSocials.facebook = siteStructure['data']['attributes']['field_facebook'];
      this.footerSocials.twitter = siteStructure['data']['attributes']['field_twitter'];
      this.footerSocials.linkedin = siteStructure['data']['attributes']['field_linkedin'];
      this.footerSocials.instagram = siteStructure['data']['attributes']['field_instagram'];
      this.footerSocials.youtube = siteStructure['data']['attributes']['field_youtube'];
    }
    return this.footerSocials;
  }

  public getFieldInfoLegalLinks(siteStructure) {
    if (siteStructure.data.relationships.field_info_legal_links) {
      const fieldInfoLegalLinks = this.sharedService.getRelatedContentArrayPage(siteStructure, 'field_info_legal_links');
      if (fieldInfoLegalLinks) {
        return fieldInfoLegalLinks.map(link => {
          return {
            label: link.attributes.field_title,
            url: link.attributes.field_slug
          };
        });
      }
    }
  }

}
