import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-preview-switch',
  templateUrl: './block-preview-switch.component.html',
  styleUrls: ['./block-preview-switch.component.scss']
})
export class BlockPreviewSwitchComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {
  }

}
