import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetOfferDataService {

  constructor() { }

  public getOfferPreviewLayout(offer) {
    let layout = 'generical';
    let data = offer.attributes != null ? offer.attributes : null;
    data = (data == null && (offer.data != null && offer.data.attributes != null)) ? offer.data.attributes : data;
    if (data != null && data.field_preview_layout != null) {
      layout = data.field_preview_layout;
    }
    return layout;
  }

  public getOfferAge(offer) {
    let age = null;
    let data = offer.attributes != null ? offer.attributes : null;
    data = (data == null && (offer.data != null && offer.data.attributes != null)) ? offer.data.attributes : data;
    if (data != null && data.field_age != null) {
      age = data.field_age;
    }
    return age;
  }

  public getOfferNew(offer) {
    let offerNew = false;
    let data = offer.attributes != null ? offer.attributes : null;
    data = (data == null && (offer.data != null && offer.data.attributes != null)) ? offer.data.attributes : data;
    if (data != null && data.field_new != null) {
      offerNew = data.field_new;
    }
    return offerNew;
  }

  public getAbstract(offer) {
    let offerAbstract = null;
    let data = offer.attributes != null ? offer.attributes : null;
    data = (data == null && (offer.data != null && offer.data.attributes != null)) ? offer.data.attributes : data;
    if (data != null && data.field_abstract != null) {
      offerAbstract = data.field_abstract.value;
    }
    return offerAbstract;
  }
}
