import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-module-tab',
  templateUrl: './module-tab.component.html',
  styleUrls: ['./module-tab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleTabComponent implements OnInit {
  @Input() model;
  module: Array<object>;
  constructor() { }

  ngOnInit() {
    if (this.model.tabs) {
      this.model.tabs[0].active = true;
      this.model.tabs.forEach(tab => {
        tab.content = tab.content.filter(item => item !== undefined);
      });
    }
  }

  selectTab(tab) {
    // deactivate all tabs
    this.model.tabs.forEach(el => el.active = false);

    // activate the tab the user has clicked on.
    tab.active = true;
  }

}
