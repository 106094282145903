import { Component, Input, OnInit } from '@angular/core';
import { DocumentsAndLinks } from './documentsAndLinks.model';

@Component({
  selector: 'app-block-documents-and-links',
  templateUrl: './block-documents-and-links.component.html',
  styleUrls: ['./block-documents-and-links.component.scss']
})
export class BlockDocumentsAndLinksComponent implements OnInit {
  @Input() model: DocumentsAndLinks;
  constructor() { }

  ngOnInit() {
  }

}
