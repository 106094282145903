import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AlertBlock } from './model-alert';

@Component({
  selector: 'app-block-alert',
  templateUrl: './block-alert.component.html',
  styleUrls: ['./block-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockAlertComponent implements OnInit {
  @Input() model: AlertBlock;
  constructor() { }

  ngOnInit() {
  }

}
