import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (environment.name === 'qa') {
            const reqHeaders = new HttpHeaders();
            reqHeaders.append('Authorization', 'Basic ' + btoa(environment.auth.username + ':' + environment.auth.password));
            return next.handle(request.clone({
                headers: reqHeaders,
                withCredentials: true
            }));
        }
        return next.handle(request);
    }
}
