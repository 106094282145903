import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MegaMenuService } from 'src/app/core/services/mega-menu.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { HeaderComponent } from '../../header.component';

@Component({
  selector: 'app-m-menu',
  templateUrl: './m-menu.component.html',
  styleUrls: ['./m-menu.component.scss']
})
export class MMenuComponent implements OnInit, OnChanges {
  @Input() model;
  mMenu: object;
  constructor(
    private megaMenuService: MegaMenuService,
    public translateservice: TranslateService,
    private localize: LocalizeRouterService,
    private headerComponent: HeaderComponent
  ) { }

  ngOnInit() {
  }

  closeNavigation() {
    this.headerComponent.closeNavigation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.model) {
      this.megaMenuService.getMegaMenu(this.model).then(
        (megaMenu: object) => {
          this.mMenu = megaMenu;
        }
      );
    }
  }

}
