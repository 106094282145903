import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModuleContent } from './content';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-content-module',
  templateUrl: './content-module.component.html',
  styleUrls: ['./content-module.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentModuleComponent implements OnInit {
  @Input()model: ModuleContent;
  htmlContent: any;
  environment = environment;

  constructor(@Inject(DOCUMENT)
    private document: Document,
  ) { }

  ngOnInit() {
    this.htmlContent = this.model.content.replace(/(src=")(\/besrv\/)(.*?)"/, '$1' + this.environment.beUrl + '/$3"');
  }

}
