import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-table',
  templateUrl: './block-table.component.html',
  styleUrls: ['./block-table.component.scss']
})
export class BlockTableComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {
  }

}
