import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-block-cta',
  templateUrl: './block-cta.component.html',
  styleUrls: ['./block-cta.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockCtaComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {
  }

}
