import {Component, Inject, OnInit, ViewChild, PLATFORM_ID, ElementRef, Renderer2, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {Lightbox} from 'ngx-lightbox';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {DAYS_OF_WEEK} from 'angular-calendar';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppErrorHandler} from './shared/models/error.handler';
import {catchError} from 'rxjs/operators';
import {HttpService} from './core/services/httpErrorHandler.service';
import {head} from 'lodash';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewInit {
    notFound = false;
    selectedLang: object = {};
    socialBar = false;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public lightbox: Lightbox,
        public httpClient: HttpClient,
        public httpService: HttpService,
        public elementRef: ElementRef,
        private renderer: Renderer2,
        private translateService: TranslateService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
    }


    ngOnInit(): void {
        console.log('🔭 Museo della Scienza e della Tecnologia - env: ' + environment.name);

        moment.updateLocale(this.translateService.currentLang, {
            week: {
                dow: DAYS_OF_WEEK.MONDAY,
                doy: 0
            }
        });
    }

    ngAfterViewInit() {
        this.getSiteAdvancedSettings('head').subscribe((headSettings) => {
            const fragment = document.createRange().createContextualFragment(headSettings.scripts);
            document.head.append(fragment);
        });
    }

    getSiteAdvancedSettings(key: any): Observable<any | AppErrorHandler> {
        return this.httpClient.get<any>(environment.apiURL + environment.advancedSettings + key + '?format=_json')
            .pipe(catchError(err => this.httpService.handleError(err)));
    }

}
