import { IMustLink } from 'src/app/shared/blocks/block-documents-and-links/documentsAndLinks.model';
import { get } from 'lodash';

export class MustLinkMapper {

    static parseExternalLink(data: any): IMustLink {
        return {
            url: data.uri,
            label: data.title,
            target: true
        };
    }
    static parseLink(data: any): IMustLink {
        return {
            url: data.uri,
            label: data.title,
            target: true,
            openLink: true
        };
    }
    static parseDownload(data: any): IMustLink {
        if (!data) {
          return null;
        }
        const url = data.meta.url;
        const fileExtention = url.split('.').pop();
        const labelFileExtention = url.split('.').shift();
        const labelNormalize = labelFileExtention.split('/').pop();
        const fileName = labelNormalize.split('%').shift();
        // todo ask if you can receive the field title
        const label = data.meta.description;
        return {
            url,
            fileExtention,
            label : (label) ? label : fileName,
            target: true,
            download: true
        };
    }

    static parseAttachment(data: any): IMustLink {
        if (!data) {
          return null;
        }
        const url = data.meta.url;
        const fileExtention = url.split('.').pop();
        // todo ask if you can receive the field title
        const label = data.meta.description;
        return {
            url,
            fileExtention,
            label,
            target: true,
            download: true
        };
    }

    static downloadFile(url) {
        const link = document.createElement("a");
        link.download = '';
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static parseRelatedContent(data: any): IMustLink {
        return{
            url: get(data, 'attributes.field_slug'),
            // tslint:disable-next-line: max-line-length
            label: (data && data.attributes.field_title_bold) ? get(data, 'attributes.field_title_bold.value') : get(data, 'attributes.field_title')
        };
    }
}
