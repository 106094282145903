import { Component, Input, OnInit } from '@angular/core';
import { LightboxImage } from 'src/app/core/services/model.lightboximage';
import { Lightbox } from 'ngx-lightbox';
import { MediaLightboxService } from 'src/app/core/services/media-lightbox.service';
import { ModuleSingleImage } from './model-single-image-module';

@Component({
  selector: 'app-module-single-image',
  templateUrl: './module-single-image.component.html',
  styleUrls: ['./module-single-image.component.scss']
})
export class ModuleSingleImageComponent implements OnInit {
  @Input()model: ModuleSingleImage;

  isReady = false;
  albums = [];
  constructor(private lightbox: Lightbox,
              private mediaLightboxService: MediaLightboxService) { }

  ngOnInit() {
    this.isReady = true;
    /** @todo if it's ok remove comment */
    // const lbImage: LightboxImage = {
    //   src: this.model.image
    // };
    // this.mediaLightboxService.setLightboxImage(lbImage);
  }

  openLightbox() {
    const lbImage: LightboxImage = {
      src: this.model.image
    };
    const index = this.mediaLightboxService.getLightboxImageIndex(lbImage);
    this.albums = this.mediaLightboxService.getLightboxAlbums();
    this.lightbox.open(this.albums, index);
  }

}
