import * as _ from 'lodash';

export interface CtaRepeater {
    label: string;
    url: string;
    target_blank: boolean;
}

export interface Slide {
    title: string;
    subtitle?: string;
    cta: string;
    image?: string;
    url: string;
    target_blank?: boolean;
    date?: string;
    CtaRepeater?: Array<CtaRepeater>;
}

export interface Slider {
    counter?: boolean;
    slides?: any;
    config?: object;
}

export class SliderContent implements Slider {
    constructor(
        public counter?: boolean,
        public slides?: Array<Slide>,
        public config?: object
        ) {
        this.counter = counter ? counter : true;
        this.slides = slides;
        this.config = config ? config : {
            loop: true,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
            dots: true,
            navSpeed: 700,
            autoHeight: true,
            autoplay: true,
            nav : false,
            items: 1,
            margin: 40,
            navText: ['', ''],
            responsive: {
                0: {
                    items: 1,
                    loop: true
                },
                400: {
                    items: 1,
                    loop: true
                },
            }
        };
    }

    public extendConfig(newConfig) {
        _.merge(this.config, newConfig);
    }
}

export default SliderContent;
