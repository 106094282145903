import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { get, values } from 'lodash';

export const breadcrumbSubject = new BehaviorSubject(null);

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  static parseBreadcrumbs(pageContent: any) {
    const fieldName = 'label';
    const parent = get(pageContent, 'data.parent');
    const breadcrumbs: any[] = [{
      label: 'Home',
      slug: '/'
    }];

    switch (pageContent.type) {
      case 'single_page_content--offer':
      case 'single_page_content--offer_school':
        const bList = values(pageContent.breadcrumbs).map(b => ({
          label: b[fieldName]
        }));
        bList.shift();
        breadcrumbs.push(...bList);
        break;
      case 'single_page_content--article':
      case 'single_page_content--press_kit':
        if (pageContent.breadcrumbs.taxonomy.label) {
          breadcrumbs.push({
            label: get(pageContent, 'breadcrumbs.taxonomy.label')
          });
        } else {
          breadcrumbs.push({
            label: get(pageContent, 'breadcrumbs.type.label')
          });
        }
        break;
      case 'single_page_content--editorial_page':
        if (parent) {
          breadcrumbs.push({
            label: parent[fieldName],
            slug: parent.slug
          });
        }
        breadcrumbs.push({
          label: pageContent['content-title']
        });
        break;
    }

    breadcrumbSubject.next(breadcrumbs);
  }
}
