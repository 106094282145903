import { Component, OnInit } from '@angular/core';
import { StoreService } from '../services/store.service';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {
  externalCss;

  constructor(
    private storeService: StoreService
  ) { }

  ngOnInit() {
    const siteStructure = this.storeService.getSiteStructure();
    if (siteStructure.data.attributes.field_additional_css) {
      this.externalCss = siteStructure.data.attributes.field_additional_css.uri;
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = this.externalCss;
      head.appendChild(style);
    }
  }

}
