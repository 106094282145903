import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-repeat-article-link',
  styleUrls: ['./repeat-article-link.component.scss'],
  templateUrl: './repeat-article-link.component.html'
})
export class RepeatArticleLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
