import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-unstructured',
  templateUrl: './project-unstructured.component.html',
  styleUrls: ['./project-unstructured.component.scss']
})
export class ProjectUnstructuredComponent implements OnInit {
  @Input() model;

  constructor() { }

  ngOnInit() {

  }

}
