import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SliderBoxes } from './model-slider-boxes';

@Component({
  selector: 'app-block-slider-boxes',
  templateUrl: './block-slider-boxes.component.html',
  styleUrls: ['./block-slider-boxes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockSliderBoxesComponent implements OnInit {
  @Input() model: SliderBoxes;

  constructor() { }

  ngOnInit() {
  }

}
