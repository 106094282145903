import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MustLinkMapper } from '../core/services/must-link.service';

@Directive({
  selector: '[appMustLink]'
})
export class MustLinkDirective {
  @Input('appMustLink')
  model: any;

  constructor(private router: Router) { }

  @HostListener('click', ['$event'])
  clicked(event: Event) {
    event.preventDefault();
    if (this.model) {
      this.navigateTo(this.model);
    }
    return;
  }

  private navigateTo(dataLink) {
     if (dataLink.target && dataLink.target === true) {
      let url = dataLink.url;
      if (!url.startsWith('http') && !url.startsWith('https')) {
        url = environment.hostName + dataLink.url;
      }
      if (dataLink.download && dataLink.download === true) {
        MustLinkMapper.downloadFile(url);
      } else if (dataLink.openLink && dataLink.openLink === true) {
        window.open(dataLink.url, '_self');
      }
      else {
        window.open(url, '_blank');
      }
    }
    else {
      this.router.navigateByUrl(dataLink.url);
    }
  }
}
