import { Component, OnInit, Input } from '@angular/core';
import { GetConfigurationWebsiteService } from 'src/app/core/services/get-configuration-website.service';
import { StoreService } from 'src/app/core/services/store.service';
import { LocalizeRouterService } from 'localize-router';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements OnInit {
  @Input() model;
  openingHoursToday;
  gotTo;
  constructor(
    private getConfigurationWebsiteService: GetConfigurationWebsiteService,
    private storeService: StoreService,
    private localize: LocalizeRouterService
  ) { }

  getTodayHours() {
    this.openingHoursToday = this.model.filter((day: any ) => day.isToday === true);
  }

  getLinkCalendarPage() {
    let calendarPage = null;
    const lang = this.localize.parser.currentLang;
    const siteStructure = this.storeService.getSiteStructure();
    const contactButton = this.getConfigurationWebsiteService.getButtonHours(siteStructure);
    if (contactButton) {
      contactButton.url = lang + '/' + contactButton.url;
      calendarPage = contactButton;
    }
    return calendarPage;
  }

  ngOnInit() {
    this.getTodayHours();
    this.gotTo = this.getLinkCalendarPage();
  }

}
