import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-title-line',
  templateUrl: './block-title-line.component.html',
  styleUrls: ['./block-title-line.component.scss']
})
export class BlockTitleLineComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {

  }

}
