export const environment = {
    name: 'prod',
    production: true,
    beUrl: 'https://www.museoscienza.org/besrv',
    apiURL: 'https://www.museoscienza.org/besrv',
    hostName: 'https://www.museoscienza.org',
    siteStructure: 'jsonapi/country_site/country/',
    imageDefaultPath: 'sites/default/files',
    singlepagecontent: 'singlepagecontent',
    api: 'api.json',
    group: 'jsonapi/single_page_content/group',
    project: 'jsonapi/single_page_content/project',
    article: 'jsonapi/single_page_content/article',
    editorial: 'jsonapi/single_page_content/editorial',
    topic: 'jsonapi/single_page_content/topic_page',
    advancedSettings: '/api/site-advanced-settings/',
    auth: {
        username: 'doing_usr',
        password: '?Jm!+JM75~Lv'
    },
    localeKey: {
        en: '816bc9c6-a062-40b8-a6e7-c186051b01f5',
        it: '2332b4e1-a1ed-4dfb-96ee-591ecd495139'
    },
    localeKeyId: {
        en: '3',
        it: '1'
    },
    localeKeyPrefix: {
        en: '/en',
        it: '/it'
    },
    storageLifespan: 10,
    catIds: {
        press: '6616f0e8-52f4-47f5-bd20-94f94db44121'
    }
};
