import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddHeaderInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Since HttpRequest is Readonly, if we need to edit edit we have clone the HttpRequest object
    // We are setting the custom header named x-company-name with the value capgemini for all the HTTP Requests
    const clonedRequest = req.clone({
      // setHeaders:{'x-company-Authorization':'Basic ZG9pbmdfdXNyOj9KbSErSk03NX5Mdg=='}
      setHeaders: { Authorization: 'Basic ZG9pbmdfdXNyOj9KbSErSk03NX5Mdg==' }

    });

    return next.handle(clonedRequest);
  }
}
