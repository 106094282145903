import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { breadcrumbSubject } from '../../../../core/services/breadcrumbs.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  breadCrumbsSubscription;
  constructor() {
    this.breadCrumbsSubscription = breadcrumbSubject.asObservable().subscribe(breadcrumbs => {
      this.items = [...breadcrumbs];
    });
  }

  items: any[];

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.breadCrumbsSubscription.unsubscribe();
  }

}
