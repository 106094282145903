import { Component, Input, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { HomeHero } from './model-homeHero';

@Component({
  selector: 'app-module-home-hero',
  templateUrl: './module-home-hero.component.html',
  styleUrls: ['./module-home-hero.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleHomeHeroComponent implements OnInit {
  @Input() model: HomeHero;
  windowWidth;
  enableVideo: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.enableVideo = this.getVideoStatus();
  }
  constructor() { }

  private getVideoStatus() {
    this.windowWidth = window.innerWidth;
    return (this.windowWidth >= 720) ? this.enableVideo = true : this.enableVideo = false;
  }

  ngOnInit() {
    this.enableVideo = this.getVideoStatus();
  }

}
