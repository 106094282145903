import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-module-text',
  styleUrls: ['./module-text.component.scss'],
  templateUrl: './module-text.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ModuleTextComponent implements OnInit {

  @Input()
  model: any;

  constructor() { }

  ngOnInit() {
  }

}
