import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';
import { GetConfigurationHeaderService } from '../../services/get-configuration-header.service';
import { NavigationHeaderService } from '../../services/navigation-header.service';
import { ItemHeader } from '../../model/itemheader.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { StoreService, openingHoursSubject$ } from 'src/app/core/services/store.service';
import { TranslateService } from '@ngx-translate/core';

export interface ConfigHeaderService {
  infoButton?: object;
  ticketButton?: object;
  calendarButton?: object;
  contactButton?: object;
}
@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('openClose', [
        state('open', style({
          opacity: 1
        })),
        state('closed', style({
          opacity: 0
        })),
        transition('open => closed', [
          animate ('10s'),
        ])
    ]),
    trigger('openCloseItem', [
      state('openItem', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('closedItem', style({
        opacity: 0,
        transform: 'scale(0.8)'
      })),
      transition('closedItem => openItem', [
        animate('0.7s')
      ])
  ])
]
})

export class MobileHeaderComponent implements OnInit {
  @Input() mainMenuData;
  someOneActive: boolean;
  mainMenuDataOrigin: any;
  itemActive: ItemHeader;
  itemMObileActive: false;
  queryParams: any;
  innerWidth: any;
  enableMmenu: boolean;
  isReady = false;
  websiteLogoUrl: string;
  buttonStore: object;
  menuList: Array<object>;
  isChild = false;
  MenuMobile = false;
  innerPosition: number;
  scrollMenu: boolean;
  languages: Array<any>;
  currentLanguage: string;
  configHeaderService: ConfigHeaderService;
  logoMobile: any;
  searchModel = {};
  topMenuData;
  openingHours;
  alternativeLanguage;
  constructor(
    private router: Router,
    private localize: LocalizeRouterService,
    private navigationHeaderService: NavigationHeaderService,
    private getConfHeaderService: GetConfigurationHeaderService,
    private storeService: StoreService,
    private getConfigurationHeaderService: GetConfigurationHeaderService,
    private translateService: TranslateService
  ) {
    this.currentLanguage = this.getConfHeaderService.getCurrentLanguage();
    this.languages = this.getConfHeaderService.getLanguageWebsite();
    this.searchModel['show'] = false;
  }

  ngOnInit() {
    this.alternativeLanguage = this.getConfigurationHeaderService.getAlternativeLanguage();
    this.mainMenuDataOrigin = this.mainMenuData;
    this.buttonStore = this.getConfHeaderService.getButtonStore();
    this.configHeaderService = this.getConfHeaderService.getItemsBottomNavigation();
    this.logoMobile = this.getConfHeaderService.getLogoMobile();
    const topMenu = this.storeService.getTopMenu();
    if (topMenu) {
      this.topMenuData = this.getConfHeaderService.getMenuData(topMenu);
    }
    this.openingHours = openingHoursSubject$.value;
  }

  switchLanguage(lang) {
    this.translateService.use(lang).subscribe(() => {
      this.currentLanguage = lang;
      this.router.navigateByUrl(lang);
  });
  }


  /**
   * Function on click on item of menu.
   * @private
   * @param {*} itemClicked
   * @memberof HeaderComponent
   */
  public NavigateMenuMobile(itemClicked) {
    const hasChildren = (itemClicked.children && itemClicked.children.length > 0) ? true : false;
    if (hasChildren) {
      this.ShowChildrenMenu(itemClicked);
    } else {
      this.closeMobileMenu();
      this.ResetMobileMenu();
      this.NavigatetoLinkMobileMenu(itemClicked);
    }
  }

  public openMenuMobile() {
    this.MenuMobile = !this.MenuMobile;
    this.backToOriginMenu();
  }

  private closeMobileMenu() {
    this.MenuMobile = false;
    this.ResetMobileMenu();
  }

  private ShowChildrenMenu(item) {
    this.mainMenuData = item.children;
    this.mainMenuData.title = item['content-title'];
    this.mainMenuData.title = {
      label: item['content-title'],
      'slug': item['slug']
    };
    this.isChild = (this.mainMenuDataOrigin !== this.mainMenuData) ? true : false;
  }

  public NavigatetoLinkMobileMenu(item) {
    const lang = this.localize.parser.currentLang;
    this.router.navigateByUrl(lang + '/' + item['slug']);
  }

  private ResetMobileMenu() {
    this.isChild = false;
    this.mainMenuData = this.mainMenuDataOrigin;
  }

  private backToOriginMenu() {
    this.mainMenuData = this.mainMenuDataOrigin;
    this.isChild = false;
  }

  private langSwitch(lang) {
    this.navigationHeaderService.langSwitch(lang);
  }

  openSearch() {
    this.searchModel['show'] = true;
  }

  closeSearch() {
      this.searchModel['show'] = false;
  }
}
