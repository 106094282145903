import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ImgTextRow } from './model-imgTextRow';

@Component({
  selector: 'app-block-img-text-row',
  templateUrl: './block-img-text-row.component.html',
  styleUrls: ['./block-img-text-row.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockImgTextRowComponent implements OnInit {
  @Input() model: ImgTextRow;
  constructor() { }

  ngOnInit() {
  }

}
