import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-block-opening-hour',
  templateUrl: './block-opening-hour.component.html',
  styleUrls: ['./block-opening-hour.component.scss']
})
export class BlockOpeningHourComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {
    
  }

}
