import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CtaBlock } from 'src/app/shared/blocks/block-cta/model-block-cta';
import { NgxMasonryOptions } from 'ngx-masonry';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ViewService } from 'src/app/core/services/view.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter, flatMap, map } from 'lodash';

export interface View {
  type: string;
  title: string;
  layout: string;
  boxes: object;
  cta: CtaBlock;
  counter: object;
  content: string;
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewComponent implements OnInit {
  @Input() model;
  filtersForm: FormGroup;
  currentPage = 0;
  hasNextPage: boolean;
  maxNumberFilters = 8;
  noResult: boolean;
  @ViewChild('view', {static: false} ) viewContainer;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private viewService: ViewService,
    private ngxService: NgxUiLoaderService
  ) { }
  options: NgxMasonryOptions = {};
  pagesCache = [];

  ngOnInit() {
    /** @todo we need to have one call less  (we have 2 times the call for second page) */
    this.hasNextPage = true;
    this.pagesCache[this.currentPage] = this.model.boxes;
    this._checkNextPage();
  }

  next() {
    this.currentPage += 1;
    this.pagination();
  }

  prev() {
    this.currentPage -= 1;
    this.pagination();
  }

  private showFilters(indexParent) {
    this.model.filters[indexParent].open = true;
  }

  private hideFilters(indexParent) {
    this.model.filters[indexParent].open = false;
  }

  private pagination() {
    if (this.pagesCache[this.currentPage]) {
      this.model.boxes = [...this.pagesCache[this.currentPage]];
      if (this.currentPage === (this.pagesCache.length - 1)) {
        this._checkNextPage();
      }
    } else {
      this._loadData();
    }
  }

  deleteFilters() {
    map(this.model.filters, n => {
      n.options.forEach( o => delete o.selected);
    });
    this.applyFilters();
  }

  applyFilters() {
    this.currentPage = 0;
    this.pagesCache = [];
    this._loadData();
  }

  // FIXME we need to make another call everytime we change page in order to check next page status
  // THIS IS WRONG!
  private _checkNextPage() {
    const pageNumber = this.currentPage + 1;
    const selectedOptions = filter(flatMap(map(this.model.filters, n => n.options)), 'selected');
    this.viewService.getViewListContent(this.model.viewAPI, selectedOptions, pageNumber)
      .subscribe((response: any) => {
        if (response && response.length) {
          this.pagesCache[pageNumber] = [...this.viewService.getListBlocks(response)];
          this.hasNextPage = true;
        } else {
          this.hasNextPage = false;
        }
      });
  }

  private _loadData() {
    const selectedOptions = filter(flatMap(map(this.model.filters, n => n.options)), 'selected');
    this.viewService.getViewListContent(this.model.viewAPI, selectedOptions, this.currentPage)
      .subscribe(response => {
        this.noResult = false;
        if (response.length <= 0) {
          this.noResult = true;
        }
        this.model.boxes = [...this.viewService.getListBlocks(response)];
        this.pagesCache[this.currentPage] = this.model.boxes;
        this._checkNextPage();
        this.modalService.dismissAll();
      });
  }

  open(content) {
    this.modalService.open(content, { centered: true, size: 'lg' }).result.then((result) => {}, (reason) => {});
  }

}
