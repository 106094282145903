import { Component, OnInit, ViewEncapsulation, ɵConsole, Output, EventEmitter } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CustomDateFormatter } from './custom-calendar-date-formatter.provider';
import { calendarSubject$ } from '../../../core/subjects/must-subjects';
import { CalendarService } from '../../../core/services/calendar.service';
import * as moment from 'moment';

const HAS_EVENT = 'has-event';
const IS_CLOSE = 'is-close';
const IS_SELECTED = 'is-selected';
@Component({
  selector: 'app-block-calendar',
  templateUrl: './block-calendar.component.html',
  styleUrls: ['./block-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class BlockCalendarComponent implements OnInit {
  @Output() dayCanged = new EventEmitter<boolean>();
  view = CalendarView.Month;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  daysData: any;
  locale: string;
  disablePrev = false;
  disableNext = false;
  constructor(private translateService: TranslateService, private calendarService: CalendarService) {}

  ngOnInit() {
    this.locale = this.translateService.currentLang;
    
    this.checkDateInUrl();

    calendarSubject$.next(this.viewDate);
    this.calendarService.getCalendarEventsForCurrentMonth().subscribe(data => {
      this.daysData = data;
      this.refresh.next();
    });
    this._checkPrevMonth();
  }

  checkDateInUrl() {
    const params = new URLSearchParams(window.location.search);
    if (params.get('date') && params.get('date').match(/^\d{4}-\d{2}-\d{2}$/) != null) {
      let urlDate = moment(params.get('date'), 'YYYY-MM-DD');
      if (urlDate.isValid()) {
        let monthStartDate = moment().startOf('month');
        this.viewDate = urlDate.isBefore(monthStartDate) ? moment().toDate() : urlDate.toDate();
      }
    }
  }

  onDayChange(day) {
    this.dayCanged.emit(true);
    if (!day.inMonth) {
      return;
    }
    this.viewDate = day.date;
    calendarSubject$.next(day.date);
  }

  changePeriod() {
    calendarSubject$.next(this.viewDate);
    this._checkPrevMonth();
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      day.cssClass = '';
      const timestamp = day.date.getTime() / 1000;
      const cssClasses = [];
      if (this.daysData) {
        if (this.daysData[timestamp] && this.daysData[timestamp]['has_events'] && this.daysData[timestamp]['is_open']) {
          day.cssClass = HAS_EVENT;
        } else if (this.daysData[timestamp] && !this.daysData[timestamp]['is_open']) {
          day.cssClass = IS_CLOSE;
        }
      }
      if (day.date.toDateString() === this.viewDate.toDateString()) {
        day.cssClass += ' ' + IS_SELECTED;
      }
    });
  }

  private _checkPrevMonth() {
    const now = moment().startOf('month');
    const currentMonth = moment(this.viewDate).startOf('month');
    const deltaMonths = currentMonth.diff(now, 'months');
    this.disablePrev = deltaMonths === 0;
    this.disableNext = deltaMonths === 2;
  }

}
