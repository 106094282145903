import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ViewService } from '../../../core/services/view.service';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import * as moment from 'moment';

export interface FilterValue {
  value: number;
  label: string;
}
export interface ViewFilter {
  id: string;
  label: string;
  values: Array<FilterValue>;
}

@Component({
  selector: 'app-module-view-module',
  templateUrl: './module-view-module.component.html',
  styleUrls: ['./module-view-module.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleViewModuleComponent implements OnInit {
  @Input() model: any;
  viewMoreLabel: string;
  filtersList: Array<ViewFilter>;

  selectedItems: Array<any> = [];

  pageSize = 12;
  results: any[] = [];
  page = 1;

  paginationConfig = { itemsPerPage: this.pageSize, currentPage: this.page };

  viewData: any;
  filtersString: string;

  contentType: string;

  constructor(private viewService: ViewService, private translateservice: TranslateService, private localize: LocalizeRouterService) {
    this.translateservice.get('view more').subscribe(
      label => {
        this.viewMoreLabel = label;
      });
  }

  ngOnInit() {
    this.getFilters();
    this.getData();
    this.filtersList = [];

    this.filtersList.forEach(element => {
      this.selectedItems[element['id']] = null;
    });
  }

  private getRelatedImage(data, jsonapiIncluded) {
    let image: object;
    if (data.relationships.field_preview_image && data.relationships.field_preview_image.data) {
      image = jsonapiIncluded.filter((singleinclude) => singleinclude.id === data.relationships.field_preview_image.data.id);
    }
    return image;
  }
  private getRelatedFile(data, jsonapiIncluded) {
    let file = [];
    if (data.relationships.field_files && data.relationships.field_files.data.length && data.relationships.field_files.data[0]) {
      file = jsonapiIncluded.filter((singleinclude) => singleinclude.id === data.relationships.field_files.data[0].id);
    }
    return file;
  }
  private getFilters() {
    const filters = this.model.view.length ? this.model.view[0].attributes.display.default.display_options.filters : {};

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (filters[key]['type'] === 'select') {
          const element = filters[key];
          this.viewService.getTaxonomyFilter(element.vid).subscribe((data) => {
            const filterValues = [];
            data.data.forEach(fElement => {
              filterValues.push({
                value: fElement.attributes.drupal_internal__tid,
                label: fElement.attributes.name
              });
            });
            this.filtersList.push({
              id: element.vid,
              label: data.included.length ? data.included[0].attributes.name : element.vid,
              values: filterValues
            });
          });
        }
      }
    }
  }
  private getFiltersString() {
    this.filtersString = '';
    const filtersArray = [];
    for (const key in this.selectedItems) {
      if (this.selectedItems.hasOwnProperty(key) && this.selectedItems[key] !== null) {
        const element = this.selectedItems[key];
        filtersArray.push('filter[field_' + key + '.tid][value]=' + element['value']);
      }
    }
    const filters = this.model.view[0].attributes.display.default.display_options.filters;

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (filters[key]['type'] === 'textfield') {
          if (filters[key]['value'].length) {
            const value = filters[key]['value'][0];
            const expIdentifier = filters[key]['expose']['identifier'];
            filtersArray.push('filter[' + expIdentifier + '.tid]=' + value);
          }
        }
      }
    }
    this.filtersString = filtersArray.join('&');
  }

  public prev() {
    this.page--;
    this.getData();
  }
  public next() {
    this.page++;
    this.getData();
  }

  private isPress(item, objectData) {
    let press = false;
    if (item.relationships.field_categories.data) {
      const categoryData = objectData.included.filter((singleinclude) => singleinclude.id === item.relationships.field_categories.data.id);
      const category = categoryData[0].attributes.name;
      if (category === 'Press') {
        press = true;
      }
    }
    return press;
  }

  private getData() {
    if (this.model.view.length) {
      const fType = this.model.view[0].attributes.display.default.display_options.filters.type;
      this.getFiltersString();
      this.contentType = fType.value[Object.keys(fType.value)[0]];
      this.viewData = this.viewService.getViewData(
        fType.entity_type,
        this.contentType,
        this.filtersString,
        this.page
      );
      this.viewData.subscribe((data) => {
        this.results = [];
        data.data.forEach(element => {
          let previewImage;
          let previewFile;
          let result: any;
          switch (this.contentType) {
            case 'article':
              previewImage = this.getRelatedImage(element, data.included);
              const isPress = this.isPress(element, data);
              if (isPress) {
                result = {
                  title: (element.attributes.field_title_bold) ? element.attributes.field_title_bold.value : null,
                  label: 'nota stampa',
                  text: (element.attributes.field_abstract) ? element.attributes.field_abstract.value : null,
                  url: this.localize.parser.currentLang + '/' + element.attributes.fe_route,
                  target_blank: (element.attributes.field_cta_target !== 'Blank') ? false : true,
                  preview_type: 'preview_inner_text_icon',
                };
              } else {
                result = {
                  title: (element.attributes.field_title_bold) ? element.attributes.field_title_bold.value : null,
                  label: (element.attributes.field_label) ? element.attributes.field_label : null,
                  text: (element.attributes.field_abstract) ? element.attributes.field_abstract.value : null,
                  image: previewImage ? environment.beUrl + previewImage[0].attributes.uri.url : '',
                  url: this.localize.parser.currentLang + '/' + element.attributes.fe_route,
                  target_blank: (element.attributes.field_cta_target !== 'Blank') ? false : true,
                  icon: element.attributes.field_magazine_icon ?
                    ((element.attributes.field_magazine_icon === 'False') ? false : true) : false,
                  preview_type: 'preview_card',
                };
              }
              break;
            case 'project':
              previewImage = this.getRelatedImage(element, data.included);
              result = {
                title: (element.attributes.field_title_bold) ? element.attributes.field_title_bold.value : null,
                label: (element.attributes.field_label) ? element.attributes.field_label : null,
                text: (element.attributes.field_abstract) ? element.attributes.field_abstract.value : null,
                image: previewImage ? environment.beUrl + previewImage[0].attributes.uri.url : '',
                url: this.localize.parser.currentLang + '/' + element.attributes.fe_route,
                target_blank: (element.attributes.field_cta_target !== 'Blank') ? false : true,
                media: null,
                preview_type: 'preview_outer_text',
              };
              break;
            case 'media':
              previewImage = this.getRelatedImage(element, data.included);
              result = {
                title: (element.attributes.field_title_bold) ? element.attributes.field_title_bold.value : null,
                label: (element.attributes.field_label) ? element.attributes.field_label : null,
                text: (element.attributes.field_abstract) ? element.attributes.field_abstract.value : null,
                image: previewImage ? environment.beUrl + previewImage[0].attributes.uri.url : '',
                url: this.localize.parser.currentLang + '/' + element.attributes.fe_route,
                target_blank: (element.attributes.field_cta_target !== 'Blank') ? false : true,
                media: element.attributes.field_video ? 'Play' : 'Camera',
                preview_type: 'preview_outer_text',
              };
              break;
            case 'editorial_page':
              previewImage = this.getRelatedImage(element, data.included);
              result = {
                title: (element.attributes.field_title) ? element.attributes.field_title : null,
                image: previewImage ? environment.beUrl + previewImage[0].attributes.uri.url : '',
                url: this.localize.parser.currentLang + '/' + element.attributes.fe_route,
                target_blank: (element.attributes.field_cta_target !== 'Blank') ? false : true,
                cta: this.viewMoreLabel,
                preview_type: 'preview_inner_text',
              };
              break;
            case 'event_block':
              previewFile = this.getRelatedFile(element, data.included);
              result = {
                title: element.attributes.field_title_bold ? element.attributes.field_title_bold.processed : null,
                text: element.attributes.field_description ? element.attributes.field_description : null,
                date: element.attributes.field_date ? moment(element.attributes.field_date) : moment(),
                file: previewFile.length ? previewFile[0] : null,
                preview_type: 'preview_event_row',
              };
              break;
          }
          this.results.push(result);
        });
      });
    }
  }

  onChange(event) {
    this.page = 1;
    this.getData();
  }
  onRemove(event) {
    this.getData();
  }
}
