import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-button',
  styleUrls: ['./text-button.component.scss'],
  templateUrl: './text-button.component.html'
})
export class TextButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
