import { EventEmitter, Injectable, Output } from '@angular/core';
import { MediaService, ModuleGallery } from './single-page-media.service';
import { environment } from '../../../environments/environment';
import { CarouselImagesContent } from '../../shared/modules/module-carousel-images/model-carousel-imagescontent';
import { ModuleSingleImage } from '../../shared/modules/module-single-image/model-single-image-module';
import { ModuleContent } from '../../shared/modules/content-module/content';
import { ModuleTextImageTwoCols } from '../../../app/shared/modules/module-text-image-two-cols/text-image-two-cols';
import { PreviewOuterText } from '../../../app/shared/blocks/block-preview-outer-text/model-preview-outer-text';
import { PreviewCard } from '../../../app/shared/blocks/block-preview-card/model-preview-card';
import { PreviewInnerText } from '../../../app/shared/blocks/block-preview-inner-text/model-preview-inner-text';
import { TranslateService } from '@ngx-translate/core';
import { ModuleHomepageTitle } from '../../../app/shared/modules/module-homepage-title/homepage-title';
import CarouselContent from '../../../app/shared/modules/module-carousel/model-carouselcontent';
import SliderContent, { Slide, Slider } from '../../../app/shared/modules/module-slider/model-slider';
import { CommonService } from './common.service';
import { LocalizeRouterService } from 'localize-router';
import { StoreService } from './store.service';
import { forkJoin } from 'rxjs';
import { get } from 'lodash';
import { Attachment } from 'src/app/shared/blocks/block-attachment/model-attachment';
import { Iframe } from 'src/app/shared/blocks/block-iframe/iframe.model';
import { ModuleVideo } from 'src/app/shared/modules/module-video/video';
import { ModelQuote } from 'src/app/shared/modules/module-quote/model-quote';
import { InfoBox } from 'src/app/shared/blocks/block-info-boxes/model-infobox';
import { Papa } from 'ngx-papaparse';
import { HttpClient } from '@angular/common/http';
import { GetDataCsvService } from './get-data-csv.service';
import { ModelOnlyTextAndLink } from 'src/app/shared/modules/module-only-text-and-link/model.only-text-and-link';
import { Accordion, AccordionElement } from 'src/app/shared/modules/module-accordion/model-accordion';
import { ThematicBlocks } from 'src/app/shared/modules/module-thematic-blocks/model.thematic-blocks';
import { SharedService } from './shared.service';
import { NormalizeCtaService } from './normalize-cta.service';
import { GetCtaService } from './get-cta.service';
import { HomeHero } from 'src/app/shared/modules/module-home-hero/model-homeHero';
import { GetConfigurationWebsiteService } from './get-configuration-website.service';
import { GetMediaEntityService } from './get-media-entity.service';
import { NormalizeBlockPreviewService } from './normalize-block-preview.service';
import { SliderBoxes } from 'src/app/shared/blocks/block-slider-boxes/model-slider-boxes';
import { View } from 'src/app/feature/view/view.component';
import { ViewService } from './view.service';
import { ModelTitle } from 'src/app/shared/modules/module-title/model-title';
import { GetFieldService } from './get-field.service';
import { chunk } from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ModuleDataNormalizerService {
  viewMoreLabel: string;
  @Output() breadcrumbs: EventEmitter<any> = new EventEmitter();
  constructor(
    public mediaService: MediaService,
    public translateservice: TranslateService,
    public commonService: CommonService,
    public localize: LocalizeRouterService,
    private sanitizer: DomSanitizer,
    private papa: Papa,
    public httpClient: HttpClient,
    public getDataCsvService: GetDataCsvService,
    private getMediaEntityService: GetMediaEntityService,
    public normalizeCtaService: NormalizeCtaService,
    private getCtaService: GetCtaService,
    private getConfigurationWebsiteService: GetConfigurationWebsiteService,
    private sharedService: SharedService,
    private normalizeBlockPreviewService: NormalizeBlockPreviewService,
    private viewService: ViewService,
    private getFieldService: GetFieldService
  ) {
    this.translateservice.get('view more').subscribe(
      label => {
        this.viewMoreLabel = label;
      });
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private getRelatedImage(data, jsonapiIncluded) {
    let image = [];
    if (data.relationships.field_image && data.relationships.field_image.data) {
      image = jsonapiIncluded.filter((singleinclude) => singleinclude.id === data.relationships.field_image.data.id);
    }

    return image;
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private getBackgroundImage(data, jsonapiIncluded) {
    let imageData = [];
    if (data[0].relationships && data[0].relationships.field_background_image) {
      if (data[0].relationships.field_background_image.data) {
        imageData = jsonapiIncluded.filter(
          (singleinclude) => singleinclude.id === data[0].relationships.field_background_image.data.id
        );
      }
    }

    return imageData;
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private isPress(data, jsonapiIncluded) {
    if (data.length && typeof data[0].attributes.field_press_preview !== 'undefined') {
      return data[0].attributes.field_press_preview;
    } else {
      return false;
    }
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private getRelatedView(data, jsonapiIncluded) {
    let view: object;
    if (data.relationships.field_view && data.relationships.field_view.data) {
      view = jsonapiIncluded.filter((singleinclude) => singleinclude.id === data.relationships.field_view.data.id);
    }
    return view;
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private getRelatedSingleCta(data, jsonapiIncluded) {
    const cta = {
      cta: '',
      url: ''
    };
    if (data.relationships.field_related_content && data.relationships.field_related_content.data.length > 0) {
      const related = jsonapiIncluded.filter((singleinclude) =>
        singleinclude.id === data.relationships.field_related_content.data[0].id);
      if (related.length) {
        cta.cta = (related[0].attributes.field_title_bold) ? related[0].attributes.field_title_bold.value : this.viewMoreLabel;
        cta.url = this.localize.parser.currentLang + '/' + related[0].attributes.fe_route;
      }
    }
    if (data.relationships.field_cta_related_content && data.relationships.field_cta_related_content.data.length > 0) {
      const related = jsonapiIncluded.filter((singleinclude) =>
        singleinclude.id === data.relationships.field_cta_related_content.data[0].id);
      if (related.length) {
        cta.cta = (related[0].attributes.field_cta_label) ? related[0].attributes.field_cta_label : this.viewMoreLabel;
        cta.url = this.localize.parser.currentLang + '/' + related[0].attributes.fe_route;
      }
    }
    return cta;
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private getRelatedSinglePreviewCta(data, jsonapiIncluded) {
    const cta = {
      cta: '',
      url: ''
    };
    if (!data.attributes.field_alternative_cta_link) {
      const internalCta = this.getRelatedSingleCta(data, jsonapiIncluded);
      cta.url = internalCta.url;
      cta.cta = data.attributes.field_cta_label ? data.attributes.field_cta_label : null;
    } else {
      cta.url = data.attributes.field_alternative_cta_link ? data.attributes.field_alternative_cta_link.uri : null;
      cta.cta = data.attributes.field_alternative_cta_link ? data.attributes.field_alternative_cta_link.title : null;
    }
    return cta;
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private getRelatedCtaList(data, jsonapiIncluded) {
    const ctasList = [];
    if (data.relationships.field_cta_related_content && data.relationships.field_cta_related_content.data) {
      data.relationships.field_cta_related_content.data.forEach(singleCta => {
        const cta = jsonapiIncluded.filter((singleinclude) => singleinclude.id === singleCta.id);
        if (cta.length > 0) {
          if (cta[0].attributes.field_external_link) {
            ctasList.push({
              url: cta[0].attributes.field_external_link ? cta[0].attributes.field_external_link.uri : '',
              cta: cta[0].attributes.field_cta_label ? cta[0].attributes.field_cta_label : cta[0].attributes.title,
              target: cta[0].attributes.field_link_target ? true : false
            });
          } else if (cta[0].relationships.field_related_content && cta[0].relationships.field_related_content.data.length > 0) {
            // tslint:disable-next-line: max-line-length
            const ctaInternal = jsonapiIncluded.filter((singleinclude) => singleinclude.id === cta[0].relationships.field_related_content.data[0].id);

            if (ctaInternal.length > 0) {
              ctasList.push({
                url: this.localize.parser.currentLang + '/' + ctaInternal[0].attributes.fe_route,
                cta: cta[0].attributes.field_cta_label ? cta[0].attributes.field_cta_label : cta[0].attributes.title,
                target: (cta[0].attributes.field_link_target && cta[0].attributes.field_link_target[0] === '1') ? true : false
              });
            }

          }
        }
      });
    }
    return ctasList;
  }


  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private getCtaEditorialBlocks(data, jsonapiIncluded) {
    const cta = {
      url: '',
      cta: ''
    };
    if (data.relationships.field_slides_editorial_blocks && data.relationships.field_slides_editorial_blocks.data) {
      data.relationships.field_slides_editorial_blocks.data.forEach(singleCta => {
        const ctaContent = jsonapiIncluded.filter((singleinclude) => singleinclude.id === singleCta.id);
        if (!ctaContent[0].field_alternative_cta_link) {
          const internalCta = this.getRelatedSingleCta(ctaContent[0], jsonapiIncluded);
          cta.url = internalCta.url;
          cta.cta = internalCta.cta;
        } else {
          cta.url = ctaContent[0].field_alternative_cta_link.uri;
          cta.cta = ctaContent[0].field_alternative_cta_link.title;
        }
      });
    }
    return cta;
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private getRelatedCtaListModule(data, jsonapiIncluded) {
    const ctaArray = [];
    if (data.relationships.field_related_content && data.relationships.field_related_content.data) {
      data.relationships.field_related_content.data.forEach(singleCta => {
        const ctaContent = jsonapiIncluded.filter((singleinclude) => singleinclude.id === singleCta.id);
        ctaContent.forEach(singleCtaContent => {
          ctaArray.push(
            {
              url: this.localize.parser.currentLang + '/' + singleCtaContent.attributes.fe_route,
              // tslint:disable-next-line: max-line-length
              cta: (singleCtaContent.attributes.field_title_bold) ? singleCtaContent.attributes.field_title_bold.value : singleCtaContent.attributes.field_title
            }
          );
        });
      });
    }
    return ctaArray;
  }

  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private getRelatedBackgroundImage(data, jsonapiIncluded) {
    // @todo: replace all get background image field of
    let image: object;
    if (data.relationships.field_background_image && data.relationships.field_background_image.data) {
      image = jsonapiIncluded.filter((singleinclude) => singleinclude.id === data.relationships.field_background_image.data.id);
    }
    return image;
  }

  /**
   * gallery_only_images_module
   * @param {object} data data of node--gallery_only_images_module of field_unstructured_modules
   * @param {Array} jsonapi_inlcuded included of all entity
   * @return {object} data for module carousel
   */
  public gallery_only_images_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      let moduleGallery = {} as ModuleGallery;
      const imagesData = (data.relationships.field_multiimg_upload_media.data) ? data.relationships.field_multiimg_upload_media.data : null;
      if (imagesData) {
        moduleGallery.images = imagesData.map(field => ({
          image: this.getMediaEntityService.getFieldImageUrlbyMeta(field),
          description: this.getFieldService.getDescriptionImage(field),
          alt: this.getFieldService.getAltImage(field)
        }));
      }
      const imagesContent = new CarouselImagesContent(true, moduleGallery.images, 'gallery');
      imagesContent.extendConfig({
        loop: true,
        center: true,
        margin: 20,
        responsive: {
          0: {
            items: 1.4
          },
          500: {
            items: 1.4,
            margin: 15
          },
          600: {
            margin: 20,
            items: 1.4
          }
        }
      });
      let module = {};
      if (imagesContent.images.length > 0) {
        module = {
          type: 'app-block-slider-boxes',
          title: (data.attributes.field_title_bold) ? data.attributes.field_title_bold.value : null,
          boxes: imagesContent,
          palette: (data.attributes.field_background) ? data.attributes.field_background : 'white'
        };
      }
      resolve(module);
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public video_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const coverImage = this.getRelatedImage(data, jsonapiIncluded);
      const module: ModuleVideo = {
        type: 'app-module-video',
        title: (data.attributes.field_title_bold) ? data.attributes.field_title_bold.value : null,
        url: data.attributes.field_video_url ? data.attributes.field_video_url.uri : '',
        color: data.attributes.field_background ? data.attributes.field_background.toLowerCase() : '',
        label: data.attributes.field_content ? data.attributes.field_content.processed : '',
        background: data.attributes.field_background ? data.attributes.field_background.toLowerCase() : '',
        hero: data.attributes.field_hero ? data.attributes.field_hero : false
      };
      resolve(module);
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public single_image_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const image = (data.relationships.field_image_media.data) ? data.relationships.field_image_media.data : null
      const module = {
        image: (image) ? this.getMediaEntityService.getFieldImageUrlbyMeta(image[0]) : null,
        type: 'app-module-single-image',
        layoutStyle: data.attributes.field_layout_style
      };
      resolve(module);
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public single_image_block(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const image = this.getRelatedImage(data, jsonapiIncluded);
      const module = {
        image: image.length ? environment.beUrl + image[0].attributes.uri.url : '',
        type: 'app-block-single-image'
      };
      resolve(module);
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public content_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const ctasList = this.getCtaService.getCtabyRelatedContent(data, jsonapiIncluded);
      const module = {
        type: 'app-content-module',
        title: data.attributes.field_title_bold ? data.attributes.field_title_bold.value : '',
        content: data.attributes.field_content ? data.attributes.field_content.processed : '',
        ctas: ctasList,
        background: data.attributes.field_background.toLowerCase()
      };
      resolve(module);
    });
  }

  /**
   * simple_slider_module
   *
   * @param {object} data
   * @param {Array} jsonapiIncluded
   * @memberof ModuleDataNormalizerService
   */
  public simple_slider_module(data, jsonapiIncluded): Promise<SliderBoxes> {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      let module: SliderBoxes = null;
      const slidesField = data.relationships.field_card;
      if (slidesField.data) {
        const slidesData = this.sharedService.getRelatedArraybyIncludedObject(data, 'field_card', jsonapiIncluded);
        if (slidesData) {
          const slides = slidesData.map(slide => {
            return this.normalizeBlockPreviewService.getPreviewBlock(slide, jsonapiIncluded);
          });
          const boxes = new CarouselContent(false, slides, 'simple-slider');
          boxes.extendConfig({
            loop: false,
            autoHeight: false,
            items: 3,
            responsive: {
              0: {
                items: 1,
                dots: true
              },
              500: {
                items: 1
              },
              600: {
                margin: 10,
                items: 3
              }
            }
          });
          module = {
            type: 'app-module-slider-blocks',
            title: (data.attributes.field_title_bold) ? data.attributes.field_title_bold.value : null,
            link: {
              label: data.attributes.field_cta_label,
              url: null,
              target: data.attributes.field_link_target
            },
            palette: (data.attributes.field_background === 'White') ? 'white' : 'light',
            boxes,
            layout: data.attributes.field_slider_layout,
          };
        }
        resolve(module);
      }
    });
  }

  /**
   * simple_slider_module_hp
   *
   * @param {object} data
   * @param {Array} jsonapiIncluded
   * @memberof ModuleDataNormalizerService
   */
  public simple_slider_module_hp(data, jsonapiIncluded): Promise<SliderBoxes> {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      let module: any = null;
      const slidesField = data.relationships.field_slides_editorial_blocks;
      if (slidesField.data) {
        const slidesData = this.sharedService.getRelatedArraybyIncludedObject(data, 'field_slides_editorial_blocks', jsonapiIncluded);
        if (slidesData) {
          const slides = slidesData.map(slide => {
            return this.normalizeBlockPreviewService.getPreviewBlock(slide, jsonapiIncluded);
          });
          module = new CarouselContent(false, slides, 'simple-slider-hp', {
            loop: true,
            autoHeight: false,
            items: 1,
            resize: true
          });
          module.extendConfig({
            loop: true,
            autoHeight: false,
            items: 1,
            responsive: {
              0: {
                items: 1,
                dots: true
              },
              500: {
                items: 1
              },
              600: {
                margin: 10,
                items: 1
              }
            }
          });
          module.type = 'app-hero_carosuel_hp_module';
        }
        resolve(module);
      }
    });
  }

  /**
   *
   *
   * @param {object} data
   * @param {Array} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public hero_carosuel_hp_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      if (data.relationships.field_slides_editorial_blocks.data.length > 0) {
        const Images = Array<Slide>();
        const editorialBlocks = data.relationships.field_slides_editorial_blocks.data;
        const editorialBlocksWithImage = editorialBlocks.map((editorial) => jsonapiIncluded.filter((singleinclude) => singleinclude.id === editorial.id));
        if (editorialBlocksWithImage && editorialBlocksWithImage[0].length > 0) {
          const slide: Slide = editorialBlocksWithImage[0].map(s => {
            return this.normalizeBlockPreviewService.getEditorialSlideBlock(s);
          });
        }
        editorialBlocks.forEach(block => {
          const editorial = jsonapiIncluded.filter((singleinclude) => singleinclude.id === block.id);
          // @todo url and cta repeater subtitle
          if (editorial.length > 0) {
            const title = editorial[0].attributes.field_title_bold.value;
            const coverImage = this.getRelatedBackgroundImage(editorial[0], jsonapiIncluded);
            // const ctaContent = this.getCtaEditorialBlocks(data, jsonapiIncluded);
            if (coverImage) {
              const slide: Slide = {
                title,
                cta: 'ctaContent.cta',
                url: 'ctaContent.url',
                // tslint:disable-next-line: max-line-length
                target_blank: (editorial[0].attributes.field_cta_target && editorial[0].attributes.field_cta_target === 'Self') ? false : true,
                image: environment.beUrl + coverImage[0].attributes.uri.url,
                // tslint:disable-next-line: max-line-length
                date: (editorial[0].type === 'editorial_slide_block--article_topic_person') ? this.commonService.getDate(editorial[0].attributes.created) : null
              };
              Images.push(slide);
            }
          }
        });

        const sliderContent = new SliderContent(true, Images);
        if (Images.length <= 1) {
          sliderContent.extendConfig({
            loop: false,
            autoplay: false,
            responsive: {
              0: {
                items: 1,
                loop: false
              },
              400: {
                items: 1,
                loop: false
              },
            }
          });
        }
        const moduleSlider: Slider & { type?: string } = sliderContent;
        moduleSlider.type = 'app-hero_carosuel_hp_module';
        resolve(moduleSlider);
      } else {
        resolve(false);
      }
    });
  }

  /**
   * multipurpose_module
   * @param {object} data data of node--multipurpose_module of field_unstructured_modules
   * @param {Array} jsonapiIncluded included of all entity
   * @return {object} data for module carousel
   */
  public multipurpose_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const palette = (data.attributes.field_background) ? data.attributes.field_background.toLowerCase() : 'white';
      const structure = (data.attributes.field_fe_layout_mp);
      const title = get(data.attributes, 'field_title_bold.value');
      const module = {
        type: 'app-module-multipurpose',
        module: [],
        palette,
        title,
        structure };
      const fieldSlide = data.relationships.field_editorial_slide_block;
      if (fieldSlide && fieldSlide.data.length > 0) {
        const slidesData = this.sharedService.getRelatedArraybyIncludedObject(data, 'field_editorial_slide_block', jsonapiIncluded);
        if (slidesData) {
          const slides = slidesData.map(slide => {
            return this.normalizeBlockPreviewService.getPreviewBlock(slide, jsonapiIncluded);
          });
          const groupby = (structure === 'three') ? 3 : 4;
          module.module =  chunk(slides, groupby);
        }
      }
      resolve(module);
    });
  }

  /**
   * multipurpose_preview_outer_text
   * @param {object} data data of editorial_slide_block--project_preview_block, editorial_slide_block--media_preview_block
   * @return {object} data for block preview outer text
   */
  public multipurpose_preview_outer_text(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const imageData = this.getBackgroundImage(data, jsonapiIncluded);
      // tslint:disable-next-line: max-line-length
      const ctaContent = this.getRelatedSinglePreviewCta(data[0], jsonapiIncluded);
      const slideBlockPreviewOuterText: PreviewOuterText = {
        image: imageData.length ? environment.beUrl + imageData[0].attributes.uri.url : '',
        label: (data[0].attributes.field_label) ? data[0].attributes.field_label : null,
        title: (data[0].attributes.field_title_bold) ? data[0].attributes.field_title_bold.value : null,
        // @todo correct field for url
        url: ctaContent.url,
        media: (data[0].attributes.field_icon) ? data[0].attributes.field_icon : null,
        preview_type: 'preview_outer_text',
        target_blank: (data[0].attributes.field_cta_target !== 'Blank') ? false : true
      };
      const slideValues = Object.values(slideBlockPreviewOuterText);
      const emptyField = slideValues.filter((single) => single === null);

      if (emptyField) {
        const fieldRelatedContent = jsonapiIncluded.filter(
          (singleinclude) => singleinclude.id === data[0].relationships.field_related_content.data.id
        );
        for (const key in slideBlockPreviewOuterText) {
          if (slideBlockPreviewOuterText.hasOwnProperty(key)) {
            const value = slideBlockPreviewOuterText[key];
            if (value === null) {
              if (key === 'title') {
                slideBlockPreviewOuterText.title = fieldRelatedContent[0].attributes.field_title_bold.value;
              }
            }
          }
        }
      }
      resolve(slideBlockPreviewOuterText);
    });
  }

  /**
   * multipurpose_preview_inner_text
   * @param {object} data data of editorial_slide_block--editorial_page_preview_block
   * @return {object} data for block preview inner text
   */
  public multipurpose_preview_inner_text(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const imageData = this.getBackgroundImage(data, jsonapiIncluded);
      // tslint:disable-next-line: max-line-length
      const ctaContent = this.getRelatedSinglePreviewCta(data[0], jsonapiIncluded);
      const slideBlockPreviewInnerText: PreviewInnerText = {
        image: imageData.length ? environment.beUrl + imageData[0].attributes.uri.url : '',
        label: (data[0].attributes.field_label) ? data[0].attributes.field_label : null,
        title: (data[0].attributes.field_title_bold) ? data[0].attributes.field_title_bold.value : null,
        cta: ctaContent.cta,
        // @todo correct field for url
        url: ctaContent.url,
        preview_type: 'preview_inner_text',
        target_blank: (data[0].attributes.field_cta_target !== 'Blank') ? false : true
      };
      const slideValues = Object.values(slideBlockPreviewInnerText);
      const emptyField = slideValues.filter((single) => single === null);

      if (emptyField) {
        const fieldRelatedContent = jsonapiIncluded.filter(
          (singleinclude) => singleinclude.id === data[0].relationships.field_related_content.data.id
        );
        for (const key in slideBlockPreviewInnerText) {
          if (slideBlockPreviewInnerText.hasOwnProperty(key)) {
            const value = slideBlockPreviewInnerText[key];
            if (value === null) {
              if (key === 'title') {
                slideBlockPreviewInnerText.title = fieldRelatedContent[0].attributes.field_title_bold.value;
              }
              if (key === 'cta') {
                this.translateservice.get('view more').subscribe(
                  ctaLabel => {
                    slideBlockPreviewInnerText.cta = ctaLabel;
                  }
                );
              }
            }
          }
        }
      }
      resolve(slideBlockPreviewInnerText);
    });
  }

  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public multipurpose_preview_inner_text_icon(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const imageData = this.getBackgroundImage(data, jsonapiIncluded);
      // tslint:disable-next-line: max-line-length
      const ctaContent = this.getRelatedSinglePreviewCta(data[0], jsonapiIncluded);
      const slideBlockPreviewInnerText: PreviewInnerText = {
        image: imageData.length ? environment.beUrl + imageData[0].attributes.uri.url : '',
        label: (data[0].attributes.field_label) ? data[0].attributes.field_label : null,
        title: (data[0].attributes.field_title_bold) ? data[0].attributes.field_title_bold.value : null,
        cta: ctaContent.cta,
        // @todo correct field for url
        url: ctaContent.url,
        preview_type: 'preview_inner_text_icon',
        target_blank: (data[0].attributes.field_cta_target !== 'Blank') ? false : true
      };
      const slideValues = Object.values(slideBlockPreviewInnerText);
      const emptyField = slideValues.filter((single) => single === null);

      if (emptyField) {
        const fieldRelatedContent = jsonapiIncluded.filter(
          (singleinclude) => singleinclude.id === data[0].relationships.field_related_content.data.id
        );
        for (const key in slideBlockPreviewInnerText) {
          if (slideBlockPreviewInnerText.hasOwnProperty(key)) {
            const value = slideBlockPreviewInnerText[key];
            if (value === null) {
              if (key === 'title') {
                slideBlockPreviewInnerText.title = fieldRelatedContent.length ?
                  fieldRelatedContent[0].attributes.field_title_bold.value : null;
              }
              if (key === 'cta') {
                this.translateservice.get('view more').subscribe(
                  ctaLabel => {
                    slideBlockPreviewInnerText.cta = ctaLabel;
                  }
                );
              }
            }
          }
        }
      }
      resolve(slideBlockPreviewInnerText);
    });
  }
  /**
   *
   *
   * @private
   * @param {*} data
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private preview_documents_links_external_magazine_url(data) {
    const ArrayExternalMagazineUrl: Attachment[] = [];
    const magazineAttachment = data[0].attributes.field_external_magazine_url;
    magazineAttachment.forEach(singleMagazineAttachment => {
      const previewMagazineAttachment: Attachment = {
        title: singleMagazineAttachment.title,
        url: singleMagazineAttachment.uri,
        background: (data[0].attributes.field_background === 'White') ? 'white' : 'light',
        target_blank: true,
        icon: 'chevron-red',
        image: true
      };
      ArrayExternalMagazineUrl.push(previewMagazineAttachment);
    });
    return ArrayExternalMagazineUrl;
  }
  /**
   *
   *
   * @private
   * @param {*} data
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private preview_documents_links_external_url(data) {
    const ArrayExternalUrl: Attachment[] = [];
    const externalAttachment = data[0].attributes.field_external_url;
    externalAttachment.forEach(singleExternalAttachment => {
      const previewMagazineAttachment: Attachment = {
        title: singleExternalAttachment.title,
        url: singleExternalAttachment.uri,
        target_blank: true,
        icon: 'external-link',
        image: false
      };
      ArrayExternalUrl.push(previewMagazineAttachment);
    });
    return ArrayExternalUrl;
  }
  /**
   *
   *
   * @private
   * @param {*} data
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private preview_documents_links_internal_url(data) {
    const ArrayInternallUrl: Attachment[] = [];
    const internalAttachmentLinks = data[0].attributes.field_link;
    internalAttachmentLinks.forEach(singleInternalLink => {
      const previewInternalAttachment: Attachment = {
        title: singleInternalLink.title,
        url: singleInternalLink.uri,
        target_blank: false,
        icon: 'chevron-red',
        image: false
      };
      ArrayInternallUrl.push(previewInternalAttachment);
    });
    return ArrayInternallUrl;
  }
  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private preview_documents_links_attachment_files(data, jsonapiIncluded) {
    const AttachmentFiles: Attachment[] = [];
    const fieldFiles = data[0].relationships.field_files.data;
    fieldFiles.forEach(file => {
      const dataFile = jsonapiIncluded.filter((singleinclude) => singleinclude.id === file.id);
      const filePreview: Attachment = {
        title: (file.meta.description) ? file.meta.description : dataFile[0].attributes.filename,
        url: environment.beUrl + dataFile[0].attributes.uri.url,
        type: dataFile[0].attributes.uri.url.split(/[.]/).pop(),
        target_blank: true,
        icon: 'download',
        image: false
      };
      AttachmentFiles.push(filePreview);
    });
    return AttachmentFiles;
  }
  /**
   *
   *
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  private preview_documents_links_related_content(data, jsonapiIncluded) {
    const relatedContent: Attachment[] = [];
    const fieldRelatedContent = data[0].relationships.field_related_content.data;
    fieldRelatedContent.forEach(content => {
      const dataContent = jsonapiIncluded.filter((singleinclude) => singleinclude.id === content.id);
      const singleRelatedContent: Attachment = {
        title: dataContent[0].attributes.field_title_bold ? dataContent[0].attributes.field_title_bold.value : null,
        url: this.localize.parser.currentLang + '/' + dataContent[0].attributes.fe_route,
        label: dataContent[0].attributes.field_subtitle,
        target_blank: false,
        icon: 'chevron-red',
        image: false
      };
      relatedContent.push(singleRelatedContent);
    });
    return relatedContent;
  }

  /**
   * multipurpose_preview_documents_links
   * @param {object} data data of editorial_slide_block--documents_links_block
   * @return {object} data for block preview download
   */
  public multipurpose_preview_documents_links(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const previewDocumentsLinks = {
        preview_type: 'attachment',
        title: (data[0].attributes.field_title_bold) ? data[0].attributes.field_title_bold.value : null,
        attachments: []
      };
      if (!previewDocumentsLinks.title) {
        this.translateservice.get('Documents').subscribe(label => {
          if (label) {
            previewDocumentsLinks.title = label;
          }
        });
      }
      if (data[0].relationships.field_files.data.length >= 0) {
        const attachmentFiles: Array<Attachment> = this.preview_documents_links_attachment_files(data, jsonapiIncluded);
        Array.prototype.push.apply(previewDocumentsLinks.attachments, attachmentFiles);
      }
      if (data[0].attributes.field_link) {
        const internallUrl: Array<Attachment> = this.preview_documents_links_internal_url(data);
        Array.prototype.push.apply(previewDocumentsLinks.attachments, internallUrl);
      }
      if (data[0].relationships.field_related_content.data && data[0].relationships.field_related_content.data.length >= 0) {
        const relatedContent: Array<Attachment> = this.preview_documents_links_related_content(data, jsonapiIncluded);
        Array.prototype.push.apply(previewDocumentsLinks.attachments, relatedContent);
      }
      if (data[0].attributes.field_external_url) {
        const externalUrl: Array<Attachment> = this.preview_documents_links_external_url(data);
        Array.prototype.push.apply(previewDocumentsLinks.attachments, externalUrl);
      }
      if (data[0].attributes.field_external_magazine_url) {
        const externalMagazineUrl: Array<Attachment> = this.preview_documents_links_external_magazine_url(data);
        Array.prototype.push.apply(previewDocumentsLinks.attachments, externalMagazineUrl);
      }
      resolve(previewDocumentsLinks);
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public multipurpose_iframe(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const slideIframe: Iframe = {
        title: data[0].attributes.name,
        code: data[0].attributes.field_code,
        block_type: 'iframe'
      };
      resolve(slideIframe);
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public multipurpose_infobox(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const slideInfoBox: InfoBox = {
        preview_type: 'infobox',
        startNumber: data.length ? data[0].attributes.field_start_number : 0,
        endNumber: data.length ? data[0].attributes.field_end_number : 0,
        prefix: data.length ? data[0].attributes.field_number_prefix : '',
        suffix: data.length ? data[0].attributes.field_number_suffix : '',
        label: data.length ? data[0].attributes.field_number_label : '',
        description: data.length ? data[0].attributes.field_number_description : ''
      };
      resolve(slideInfoBox);
    });
  }

  /**
   * multipurpose_block_preview_card
   * @param {object} data data of editorial_slide_block--article_topic_person
   * @return {object} data for block preview card
   */
  public multipurpose_block_preview_card(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const imageData = this.getBackgroundImage(data, jsonapiIncluded);
      // tslint:disable-next-line: max-line-length
      // const relatedContentforLink = jsonapiIncluded.filter((singleinclude) => singleinclude.id === data[0].relationships.field_related_content.data.id);
      const ctaContent = this.getRelatedSinglePreviewCta(data[0], jsonapiIncluded);

      const slideBlockPreviewOuterText: PreviewCard = {
        title: (data[0].attributes.field_title_bold) ? data[0].attributes.field_title_bold.value : null,
        label: (data[0].attributes.field_label) ? data[0].attributes.field_label : null,
        text: (data[0].attributes.field_text) ? data[0].attributes.field_text.value : null,
        image: imageData.length ? environment.beUrl + imageData[0].attributes.uri.url : '',
        // @todo correct field for url
        url: ctaContent.url,
        target_blank: (data[0].attributes.field_cta_target !== 'Blank') ? false : true,
        icon: (data[0].attributes.field_magazine_icon === 'False') ? false : true,
        preview_type: 'preview_card'
      };


      const slideValues = Object.values(slideBlockPreviewOuterText);
      const emptyField = slideValues.filter((single) => single === null);

      if (emptyField) {
        const fieldRelatedContent = jsonapiIncluded.filter(
          (singleinclude) => singleinclude.id === data[0].relationships.field_related_content.data[0].id);
        for (const key in slideBlockPreviewOuterText) {
          if (slideBlockPreviewOuterText.hasOwnProperty(key)) {
            const value = slideBlockPreviewOuterText[key];
            if (value === null) {
              if (key === 'title' && fieldRelatedContent.length) {
                slideBlockPreviewOuterText.title = fieldRelatedContent[0].attributes.field_title_bold.value;
              }
              if (key === 'text' && fieldRelatedContent.length) {
                // @todo correct field for text
                slideBlockPreviewOuterText.text = (fieldRelatedContent[0].attributes.field_subtitle)
                  ? fieldRelatedContent[0].attributes.field_subtitle.value : null;
              }
            }
          }
        }
      }
      resolve(slideBlockPreviewOuterText);
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public text_image_2_cols_module(data, jsonapiIncluded) {
    return new Promise((resolve) => {
      const hypeLayout = data.attributes.field_hype_content_layout;
      const imageData = (data.relationships.field_image_media.data) ? data.relationships.field_image_media.data : null
      this.getCtaService.getAllCtaCallToActionModule(data, jsonapiIncluded).subscribe(ctaList => {
        const singleImage: ModuleSingleImage = {
        title: '',
        image: (imageData) ? this.getMediaEntityService.getFieldImageUrlbyMeta(imageData[0]) : null
        };
  
        const singleContent: ModuleContent = {
          title: data.attributes.field_title_bold ? data.attributes.field_title_bold.value : '',
          content: data.attributes.field_content ? data.attributes.field_content.processed : '',
          ctas: ctaList
        };
  
        const module: ModuleTextImageTwoCols = {
          type: 'app-module-text-image-two-cols',
          image: singleImage,
          content: singleContent,
          hypeLayout,
          inverted: data.attributes.field_invert_order ? true : false,
          background: data.attributes.field_background.toLowerCase()
        };
        resolve(module);
      });
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public section_home_page_title_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const coverImage = this.getRelatedImage(data, jsonapiIncluded);
      const module: ModuleHomepageTitle = {
        type: 'app-module-homepage-title',
        title: data.attributes.field_title_bold.value,
        layout: (data.attributes.field_layout) ? data.attributes.field_layout.toLowerCase().replace(/\s/g, '') : null,
        image: coverImage.length ? environment.beUrl + coverImage[0].attributes.uri.url : '',
        vertical_background: data.attributes.field_vertical_background.toLowerCase(),
        horizontal_background: data.attributes.field_horizontal_background.toLowerCase()
      };
      resolve(module);
    });
  }

  public title_module(data, jsonapiIncluded) {
    return new Promise((resolve) => {
      let moduleTitle = {} as ModelTitle;
      const fieldImage = data.relationships.field_image_media.data;
      moduleTitle = {
        title: this.getFieldService.fieldTitle(data),
        type: 'app-module-title',
        layout : data.attributes.field_layout,
        subtitle : data.attributes.field_subtitle,
        // tslint:disable-next-line: max-line-length
        image_url: (fieldImage && data.attributes.field_layout !== 'no_img') ? this.getMediaEntityService.getFieldImageUrlbyMeta(fieldImage[0]) : null
      };
      resolve(moduleTitle);
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public view_module(data, jsonapiIncluded, counter = 0): Promise<View> {
    return new Promise((resolve, reject) => {
      let moduleBlocks = null;
      let blocks = null;
      const title = this.viewService.getViewTitle(data);
      const layout = this.viewService.getViewLayout(data);
      // TODO VIEWPOINT
      const viewAPI = this.viewService.getViewEndpoint(counter);
      this.viewService.getViewListContent(viewAPI).subscribe(
        responseView => {
          if (!responseView.error) {
            blocks = this.viewService.getListBlocks(responseView);
            moduleBlocks = this.viewService.getModulePreviewBlocks(blocks, layout);
          }
          const filters = this.viewService.getFilters(counter);
          const layoutWithCta: boolean = this.viewService.checkIsLayoutWithCta(layout);
          const cta = (layoutWithCta) ? this.viewService.getViewCtaLayout(data, jsonapiIncluded) : null;
          const content = data.attributes.field_content ? data.attributes.field_content.processed : '';
          const viewModule = {
            type: 'app-module-view-module',
            title,
            layout,
            cta,
            boxes: moduleBlocks,
            counter: null, /** @todo */
            filters,
            viewAPI,
            content
          };
          resolve(viewModule);
        }
      );
    });
  }

  /**
   *
   *
   * @param {object} data
   * @param {Array} jsonapiIncluded
   * @memberof ModuleDataNormalizerService
   */
  public slider_text_boxes_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      if (data.relationships.field_slides_editorial_blocks.data.length > 0) {
        const slides = [];
        if (data.relationships.field_slides_editorial_blocks.data.length > 0) {
          const fieldCard = data.relationships.field_slides_editorial_blocks.data;
          fieldCard.forEach(card => {
            const editorialSlideBlock = jsonapiIncluded.filter((singleinclude) => singleinclude.id === card.id);
            if (editorialSlideBlock.length) {
              // const with data for overwrite

              switch (editorialSlideBlock[0].type) {
                case 'editorial_slide_block--project_preview_block':
                case 'editorial_slide_block--media_preview_block':
                  this.multipurpose_preview_outer_text(editorialSlideBlock, jsonapiIncluded).then(
                    res => {
                      slides.push(res);
                    }
                  );
                  break;
                case 'editorial_slide_block--article_topic_person':
                  const isPress = this.isPress(editorialSlideBlock, jsonapiIncluded);
                  if (isPress) {
                    this.multipurpose_preview_inner_text_icon(editorialSlideBlock, jsonapiIncluded).then(
                      res => {
                        slides.push(res);
                      }
                    );
                  } else {
                    this.multipurpose_block_preview_card(editorialSlideBlock, jsonapiIncluded).then(
                      res => {
                        slides.push(res);
                      }
                    );
                  }
                  break;
                case 'editorial_slide_block--editorial_page_preview_block':
                  this.multipurpose_preview_inner_text(editorialSlideBlock, jsonapiIncluded).then(
                    res => {
                      slides.push(res);
                    }
                  );
                  break;
                case 'editorial_slide_block--iframe_block':
                  this.multipurpose_iframe(editorialSlideBlock, jsonapiIncluded).then(
                    res => {
                      slides.push(res);
                    }
                  );
                  break;
                case 'editorial_slide_block--documents_links_block':
                  this.multipurpose_preview_documents_links(editorialSlideBlock, jsonapiIncluded).then(
                    res => {
                      slides.push(res);
                    }
                  );
                  break;
                default:
                  break;
              }
            }
          });
        }

        const boxes = new CarouselContent(false, slides);
        boxes.extendConfig({
          autoHeight: false,
          responsive: {
            0: {
              items: 1
            },
            500: {
              items: 1
            },
            600: {
              margin: 40,
              items: 3
            }
          }
        });
        const ctaContent = this.getRelatedCtaListModule(data, jsonapiIncluded);
        const module = {
          type: 'app-block-slider-text-boxes',
          teaser: (data.attributes.field_content) ? data.attributes.field_content.value : null,
          title: (data.attributes.field_title_bold) ? data.attributes.field_title_bold.value : null,
          links: ctaContent,
          palette: (data.attributes.field_background === 'White') ? 'white' : 'light',
          boxes
        };
        resolve(module);
      }
    });
  }

  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns array for module attachments
   * @memberof ModuleDataNormalizerService
   */
  public attachments_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const module: any = {};
      // @todo if is possible remove method
      if (data.relationships.field_attachments.data.length > 0) {
        module.attachments = [];
        module.type = 'app-node--attachments';
        this.translateservice.get('download').subscribe(
          label => {
            if (data.attributes.field_title_bold) {
              module.title = data.attributes.field_title_bold.value;
            } else if (label) {
              module.title = label;
            }
            const fieldsAttachments = data.relationships.field_attachments.data;
            fieldsAttachments.forEach(singleAttachment => {
              // tslint:disable-next-line: max-line-length
              const attachments = jsonapiIncluded.filter((singleinclude) => singleinclude.id === singleAttachment.id);
              if (attachments) {
                module.attachments.push(
                  {
                    title: (singleAttachment.meta.description) ?
                      singleAttachment.meta.description : singleAttachment.attributes.uri.url.split(/[/]/).pop(),
                    type: attachments[0].attributes.uri.url.split(/[.]/).pop(),
                    icon: 'fa fa-download',
                    url: environment.beUrl + attachments[0].attributes.uri.url
                  }
                );
              }
            });
            resolve(module);
          }
        );
      }
    });
  }

  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns module, object for module quote
   * @memberof ModuleDataNormalizerService
   */
  public text_quote_module(data, jsonapiIncluded) {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      const module: ModelQuote = {
        type: 'app-node--text_quote_module',
        content: ''
      };
      if (data.attributes.body.value) {
        module.content = data.attributes.body.value;
        module.palette = (data.attributes.field_background === 'Light') ? 'light' : 'white';
        if (data.attributes.field_author) {

          module.author = data.attributes.field_author.value;
        }
      }
      resolve(module);
    });
  }

  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public only_text_and_link_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const module: ModelOnlyTextAndLink = {
        type: 'app-module-only-text-and-link',
        content: data.attributes.body ? data.attributes.body.processed : '',
        palette: data.attributes.field_background ? data.attributes.field_background.toLowerCase() : 'white'
      };
      resolve(module);
    });
  }

  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public thematic_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const ctaContent = this.sharedService.getRelatedArraybyIncludedObject(data, 'field_cta_related_content', jsonapiIncluded) || [];
      const blocksSlide = { module: [] };
      const field = data.relationships.field_slides_editorial_blocks;
      if (field.data) {
        const blockSlideData = this.sharedService.getRelatedArraybyIncludedObject(data, 'field_slides_editorial_blocks', jsonapiIncluded);
        const blockSlide = blockSlideData.map(slide => {
          return this.normalizeBlockPreviewService.getPreviewBlock(slide, jsonapiIncluded);
        });
        blocksSlide.module = blockSlide.slice(0, 2);
      }
      const thematicBlockCtaLinks = ctaContent.map(cta => {
        return {
          label: this.getFieldService.fieldTitle(cta),
          url: get(cta, 'attributes.field_slug')
        };
      });
      const module: ThematicBlocks = {
        type: 'app-module-thematic-blocks',
        teaser: (data.attributes.field_content) ? data.attributes.field_content.value : null,
        title: (data.attributes.field_title_bold) ? data.attributes.field_title_bold.value : null,
        links: thematicBlockCtaLinks,
        palette: (data.attributes.field_background === 'Dark') ? 'Dark' : 'White',
        boxes: blocksSlide.module
      };
      resolve(module);
    });
  }

  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public accordion_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const slides: Array<AccordionElement> = [];
      if (data.relationships.field_slides_editorial_blocks.data.length > 0) {
        if (data.relationships.field_slides_editorial_blocks.data.length > 0) {
          const fieldCard = data.relationships.field_slides_editorial_blocks.data;
          fieldCard.forEach(card => {
            const editorialSlideBlock = jsonapiIncluded.filter((singleinclude) => singleinclude.id === card.id);
            if (editorialSlideBlock && editorialSlideBlock.length > 0) {
              if (editorialSlideBlock[0].type === 'editorial_slide_block--accordion_block') {
                const slide: AccordionElement = {
                  title: editorialSlideBlock[0].attributes.field_title_bold.processed,
                  content: editorialSlideBlock[0].attributes.field_text.processed
                };
                slides.push(slide);
              }
            }
          });
        }
      }

      const module: Accordion = {
        type: 'app-module-accordion',
        id: data.id,
        palette: data.attributes.field_background ? data.attributes.field_background.toLowerCase() : 'white',
        slides
      };
      resolve(module);
    });
  }

  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public infographic_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const slides = [];
      const promises = [];
      const module = {
        type: 'app-module-infographic-module',
        title: data.attributes.field_title_bold.processed,
        content: (data.attributes.body) ? data.attributes.body.processed : null,
        color: data.attributes.field_background.toLowerCase(),
        slides
      };
      if (data.relationships.field_editorial_slide_block.data.length > 0) {
        const fieldCard = data.relationships.field_editorial_slide_block.data;

        fieldCard.forEach(card => {
          const editorialSlideBlock = jsonapiIncluded.filter((singleinclude) => singleinclude.id === card.id);
          if (editorialSlideBlock.length) {
            promises.push(this.multipurpose_infobox(editorialSlideBlock, jsonapiIncluded));
            // const with data for overwrite
            // switch (editorialSlideBlock[0].type) {
            //   case 'editorial_slide_block--project_preview_block':
            //   case 'editorial_slide_block--media_preview_block':
            //     promises.push(this.multipurpose_preview_outer_text(editorialSlideBlock, jsonapiIncluded));
            //     break;
            //   case 'editorial_slide_block--article_topic_person':
            //     promises.push(this.multipurpose_block_preview_card(editorialSlideBlock, jsonapiIncluded));
            //     break;
            //   case 'editorial_slide_block--editorial_page_preview_block':
            //     promises.push(this.multipurpose_preview_inner_text(editorialSlideBlock, jsonapiIncluded));
            //     break;
            //   case 'editorial_slide_block--iframe_block':
            //     promises.push(this.multipurpose_iframe(editorialSlideBlock, jsonapiIncluded));
            //     break;
            //   case 'editorial_slide_block--info_boxes_block':
            //     promises.push(this.multipurpose_infobox(editorialSlideBlock, jsonapiIncluded));
            //     break;
            //   default:
            //     break;
            // }
          }
        });
      }

      if (promises.length) {
        forkJoin(promises).subscribe((slidesRes) => {
          slidesRes.forEach(element => {
            module.slides.push(element);
          });
          resolve(module);
        });
      } else {
        resolve(module);
      }
    });
  }
  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public tabs_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const tabsModule = {
        type: 'app-node--tabs_module',
        id: data.id,
        tabs: [],
        title: (data.attributes.field_title_bold) ? data.attributes.field_title_bold.value : null,
        palette: (data.attributes.field_background) ? data.attributes.field_background.toLowerCase() : 'white'
      };
      if (data.relationships.field_slides_editorial_blocks.data.length > 0) {
        const tabData = data.relationships.field_slides_editorial_blocks.data;
        tabData.forEach(singleTabData => {
          const blocks = jsonapiIncluded.filter((singleinclude) => singleinclude.id === singleTabData.id);
          const tab = {
            title: blocks.length ? blocks[0].attributes.field_title_bold.value : '',
            content: []
          };
          let index = 0;
          /** @todo change logic of index, we need to use it now for the sort of Array  */
          // if tab has text
          if (blocks.length && (blocks[0].attributes.field_text)) {
            const count = index++;
            tab.content[count] = {
              type: 'app-text',
              data: get(blocks[0], 'attributes.field_text.value')
            };
          }
          // if tab has csv file
          // @todo move function getCsv in external service and complete tabs module function
          if (blocks.length && blocks[0].relationships.field_csv_file.data) {
            const count = index++;
            const csvData = blocks[0].relationships.field_csv_file.data;
            const csv = jsonapiIncluded.filter((singleinclude) => singleinclude.id === csvData.id);
            if (csv.length > 0) {
              const csvUrl = environment.hostName + csv[0].attributes.uri.url;
              this.getDataCsvService.getCsv(csvUrl).subscribe((response: any) => {
                this.papa.parse(response, {
                  skipEmptyLines: true,
                  complete: (result) => {
                    const keys = result.data[0];
                    delete result.data[0];
                    tab.content[count] = {
                      type: 'app-table',
                      keys,
                      data: result.data
                    };
                  }
                });
              });
            }
          }
          if (blocks.length && (blocks[0].attributes.field_text_bottom)) {
            const count = index++;
            tab.content[count] = {
              type: 'app-text-bottom',
              data: get(blocks[0], 'attributes.field_text_bottom.value')
            };
          }
          tabsModule.tabs.push(tab);
        });
      }
      resolve(tabsModule);
    });
  }

  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public key_fact_goals_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      const module = {
        type: 'app-key-fact-goals-module',
        palette: (data.attributes.field_background) ? data.attributes.field_background.toLowerCase() : 'white',
        images: [],
        titleImages: (data.attributes.field_goals_description) ? data.attributes.field_goals_description.processed : null,
        boxes: {
          title: data.attributes.field_title_bold.processed,
          content: data.attributes.field_content.processed,
          slides: []
        }
      };
      if (data.relationships.field_upload_multiple_images.data) {
        const imagesData = data.relationships.field_upload_multiple_images.data;
        imagesData.forEach(element => {
          const image = jsonapiIncluded.filter((singleinclude) => singleinclude.id === element.id);
          if (image.length) {
            module.images.push(environment.beUrl + image[0].attributes.uri.url);
          }
        });
      }
      if (data.relationships.field_key_fact_goals_module.data) {
        const keyFactGoalsArray = data.relationships.field_key_fact_goals_module.data;
        keyFactGoalsArray.forEach(element => {
          const keyFactGoalsData = jsonapiIncluded.filter((singleinclude) => singleinclude.id === element.id);
          this.multipurpose_infobox(keyFactGoalsData, jsonapiIncluded).then(
            box => {
              if (box) {
                module.boxes.slides.push(box);
              }
            }
          );
        });
      }
      resolve(module);
    });
  }

  public call_to_action_module(data, jsonapiIncluded) {
    return new Promise((resolve, reject) => {
      this.getCtaService.getAllCtaCallToActionModule(data, jsonapiIncluded).subscribe(
        cta => {
          const moduleCta = {
            type: 'app-module-cta',
            title: (data.attributes.field_title_bold) ? data.attributes.field_title_bold.value : null,
            content: (data.attributes.field_content) ? data.attributes.field_content.value : null,
            cta,
            background: (data.attributes.field_background === 'Light') ? 'Light' : 'White',
          };
          resolve(moduleCta);
        }
      );
    });
  }

  /**
   *
   *
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public spacer_module() {
    return new Promise((resolve, reject) => {
      const spacerModule = {
        type: 'app-module-spacer',
      };
      resolve(spacerModule);
    });
  }

  public getYoutubeUrl(data) {
    let iframeUrl = null;
    if (data.attributes.field_video_url) {
      const fieldVideoUrl = data.attributes.field_video_url;
      const VideoUrlArray = fieldVideoUrl.uri.split('/');
      const VideoId = VideoUrlArray[VideoUrlArray.length - 1];
      // tslint:disable-next-line: max-line-length
      const iframeUrlString = 'https://www.youtube.com/embed/' + VideoId + '?autoplay=1&mute=1&controls=0&loop=1&fs=0&disablekb=1&showinfo=0&iv_load_policy=3&rel=0&modestbranding=1&nologo=1/&playlist=' + VideoId;
      iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrlString);
    }
    return iframeUrl;
  }

  /**
   *
   *
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public home_hero_module(data, jsonapiIncluded): Promise<HomeHero> {
    return new Promise((resolve, reject) => {
      this.getCtaService.getAllCtaHomeHeroModule(data, jsonapiIncluded).subscribe(
        cta => {
          const info = this.getConfigurationWebsiteService.getConfigurationButtonWebiste();
          const video = this.getYoutubeUrl(data);
          const homeHero: HomeHero = {
            type: 'app-module-home-hero',
            label: (data.attributes.field_fe_title_label) ? data.attributes.field_fe_title_label : null,
            title: (data.attributes.field_title_bold) ? data.attributes.field_title_bold.value : null,
            content: (data.attributes.field_content) ? data.attributes.field_content.value : null,
            img: (data.relationships.field_image_media.data[0]) ? this.getMediaEntityService.getFieldImageUrlbyMeta(data.relationships.field_image_media.data[0]) : null,
            video,
            cta,
            infoButtons: (info.length > 0) ? info : null
          };
          resolve(homeHero);
        }
      );
    });
  }

}
