import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppErrorHandler } from '../../shared/models/error.handler';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(public httpClient: HttpClient) {
  }

  getData(path: string): Observable<any | AppErrorHandler> {
    return this.httpClient.get<any>(environment.apiURL + path)
        // .pipe(catchError(err=>{ return ErrorObservable.create(err)}));
        // using Custom Error Handler
        .pipe(catchError(err => this.handleError(err)));
  }

  handleError(err: HttpErrorResponse): Observable<AppErrorHandler> {
    const appError = new AppErrorHandler();
    appError.status = err.status;
    appError.statusText = err.statusText;
    appError.url = err.url;
    appError.message = `${err.url} ${err.message}`;
    // creating Observable<AppErrorHandler> from HttpErrorResponse
//    return ErrorObservable.create(appError);
    return throwError(appError);
  }
}
