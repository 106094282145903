import { Injectable } from '@angular/core';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GetFieldService {

  constructor() { }

  /**
   * The function return field_textual_content of entity page
   * @param {object} entityPage
   * @returns {string}
   * @memberof GetFieldService
   */
  public fieldTextualContent(entityPage): string {
    let fieldTextualContentData = null;
    if (entityPage.data.attributes.field_textual_content) {
      fieldTextualContentData = entityPage.data.attributes.field_textual_content.value;
    }
    return fieldTextualContentData;
  }

  public fieldSubtitle(entityPage): string {
    let fieldSubtitle = null;
    if (entityPage.data.attributes.field_subtitle) {
      fieldSubtitle = entityPage.data.attributes.field_subtitle.value;
    }
    return fieldSubtitle;
  }

  public getDescriptionImage(data): string {
    let descriptionImage = get(data, 'meta.description.value');
    return descriptionImage;
  }

  public getAltImage(data): string {
    let altImage = null;
    if (data.meta.description) {
      altImage = data.meta.alt;
    }
    return altImage;
  }

  /**
   * Method for get field title of entity page, because a single page can have field title or field title bold.
   * @param {object} entityPage
   * @returns {string}
   * @memberof GetFieldService
   */
  public fieldTitle(entityPage): string {
      let title = null;
      if (entityPage && entityPage.attributes.field_title) {
        title = entityPage.attributes.field_title;
      } else if (entityPage && entityPage.attributes.field_title_bold) {
        title =  entityPage.attributes.field_title_bold.value;
      }
      return title;
  }

  public fieldTitleView(data): string {
    let title = null;
    if (data.field_title_bold) {
      title =  data.field_title_bold;
    } else if (data.field_title) {
      title = data.field_title;
    }
    if (data.field_title_preview) {
      title = data.field_title_preview;
      console.log('use field_title_preview');
    }
    return title;
}

  public fieldTarget(entity): boolean {
    let target = false;
    let targetField = null;
    if(entity.data) {
      targetField = entity.data.attributes.field_link_target;
    } else if(entity.attributes.field_link_target) {
      targetField = entity.attributes.field_link_target;
    } 
    if (targetField === 'blank') {
      target = true;
    }
    return target;
  }

  public fieldCtaLabel(entity): string {
    let fieldCtaLabel = null;
    if (entity.data) {
      fieldCtaLabel = entity.data.attributes.field_cta_label;
    } else if (entity.attributes.field_cta_label) {
      fieldCtaLabel = entity.attributes.field_cta_label;
    } else if (entity.attributes.field_call_to_action_label) {
      fieldCtaLabel = entity.attributes.field_call_to_action_label;
    }
    return fieldCtaLabel
  }
  }
