import { Component, Input, OnInit } from '@angular/core';
import { ProjectIntro } from '../../models/project-intro.model';

@Component({
  selector: 'app-project-intro',
  templateUrl: './project-intro.component.html',
  styleUrls: ['./project-intro.component.scss']
})
export class ProjectIntroComponent implements OnInit {

  @Input()
  model: ProjectIntro;

  constructor() { }

  ngOnInit() {
  }

}
