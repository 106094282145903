import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Iframe } from './iframe.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-block-iframe',
  templateUrl: './block-iframe.component.html',
  styleUrls: ['./block-iframe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockIframeComponent implements OnInit {
  @Input() model: Iframe;

  iframeCode: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.iframeCode = this.sanitizer.bypassSecurityTrustHtml(this.model.code);
  }

}
