import { Component, Input, OnInit } from '@angular/core';
import { ModuleInfographic } from './module-infographic.model';

@Component({
  selector: 'app-module-infographic-module',
  templateUrl: './module-infographic-module.component.html',
  styleUrls: ['./module-infographic-module.component.scss']
})
export class ModuleInfographicModuleComponent implements OnInit {
  @Input()model: ModuleInfographic;

  constructor() { }

  ngOnInit() {
  }

}
