import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '../services/store.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RouteDataService } from '../services/route-data.service';
import { tap } from 'rxjs/operators';
import { MetaTagsService } from '../services/meta-tags.service';

@Injectable({
  providedIn: 'root'
})
export class PageDataResolver implements Resolve<any> {
  constructor(
    private translateService: TranslateService,
    private storeService: StoreService,
    private httpClient: HttpClient,
    private routeDataService: RouteDataService,
    private metaTagsServicemetatag: MetaTagsService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const slug = this.routeDataService.getCurrentSlugFromRoute();
    const pageMetaData = this.storeService.getPageData(slug);
    const url = environment.apiURL + environment.localeKeyPrefix[this.translateService.currentLang] + '/' + pageMetaData.parameters;
    return this.httpClient.get<any>(url)
      .pipe(
        tap(resp => {
          this.storeService.saveCurrentPage(resp);
        }),
        tap(resp => {
          this.metaTagsServicemetatag.setMetaData(resp);
        })
      );
  }
}
