import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GetFieldService } from './get-field.service';
import { GetMediaEntityService } from './get-media-entity.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  constructor(
    private meta: Meta,
    private getFieldService: GetFieldService,
    private getMediaEntityService: GetMediaEntityService,
    private titleService: Title
    ) { }

  setMetaData(data): boolean {
    if (data.data.attributes.field_meta_description) {
      this.meta.addTag({ name: 'description', content: data.data.attributes.field_meta_description});
      this.meta.addTag({ name: 'og:description', content: data.data.attributes.field_meta_description.trim() });
      this.meta.addTag({ name: 'twitter:description', content: data.data.attributes.field_meta_description.trim() });
    }
    this.titleService.setTitle(this.getFieldService.fieldTitle(data.data));
    this.meta.addTag({ name: 'title', content: this.getFieldService.fieldTitle(data.data)}, true);
    this.meta.addTag({ name: 'og:title', content: this.getFieldService.fieldTitle(data.data) });
    let image = environment.hostName + '/assets/images/must.svg';
    if (data.data.relationships.field_preview_image_media && data.data.relationships.field_preview_image_media.data) {
      const imageData = data.data.relationships.field_preview_image_media;
      image = this.getMediaEntityService.getFieldImageUrlbyMeta(imageData.data);
    }
    this.meta.addTag({ name: 'og:image', content: image });
    this.meta.addTag({ name: 'twitter:image', content: environment.hostName + image });
    this.meta.addTag({ name: 'twitter:title', content: this.getFieldService.fieldTitle(data.data) });
    return true;
  }
}
