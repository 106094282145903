import { Component, Input, OnInit } from '@angular/core';
import { LightboxImage } from 'src/app/core/services/model.lightboximage';
import { Lightbox } from 'ngx-lightbox';
import { MediaLightboxService } from 'src/app/core/services/media-lightbox.service';
import { BlockSingleImage } from './model-single-image-block';

@Component({
  selector: 'app-block-single-image',
  templateUrl: './block-single-image.component.html',
  styleUrls: ['./block-single-image.component.scss']
})
export class BlockSingleImageComponent implements OnInit {
  @Input() model: BlockSingleImage;
  @Input() imageLightbox: true;
  isReady = false;
  albums = [];
  constructor(
    private lightbox: Lightbox,
    private mediaLightboxService: MediaLightboxService
    ) {
      this.imageLightbox = (this.imageLightbox) ? this.imageLightbox : true;
     }

  ngOnInit() {
    this.isReady = true;
    const lbImage: LightboxImage = {
      src: this.model.image
    };
    this.mediaLightboxService.setLightboxImage(lbImage);
  }

  openLightbox() {
    if (this.imageLightbox) {
      const lbImage: LightboxImage = {
        src: this.model.image
      };
      const index = this.mediaLightboxService.getLightboxImageIndex(lbImage);
      this.albums = this.mediaLightboxService.getLightboxAlbums();
      this.lightbox.open(this.albums, index);
    }
  }
}
