import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appMustRouterLink]'
})
export class MustRouterLinkDirective {

  constructor(private router: Router, private translateService: TranslateService, private elRef: ElementRef) {}

  @HostListener('click', ['$event'])
  clicked(event: Event) {
    event.preventDefault();
    const url = this.translateService.currentLang + '/' + this.elRef.nativeElement.getAttribute('href');
    this.router.navigateByUrl(url);
    return;
  }

}
