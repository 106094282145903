import * as _ from 'lodash';

export interface CarouselContentProperties {
    counter?: boolean;
    slides?: Array<object>;
    theme?: string;
    config?: object;
}

export class CarouselContent implements CarouselContentProperties {
    constructor(public counter?: boolean,
                public slides?: Array<object>,
                public theme?: string,
                public config?: object) {
        this.counter = counter ? counter : false;
        this.slides = slides || [];
        this.theme = theme ? theme : 'salini-standard';
        this.config = config ? config : {
            loop: true,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
            dots: false,
            navSpeed: 700,
            autoHeight: true,
            nav : false,
            margin: 10,
            navText: ['', ''],
            responsive: {
                0: {
                    items: 1
                },
                500: {
                    items: 1
                },
                600: {
                    margin: 40,
                    items: 3
                }
            }
        };
    }

    public extendConfig(newConfig) {
        _.merge(this.config, newConfig);
    }
}

export default CarouselContent;
