import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-module-details',
  templateUrl: './module-details.component.html',
  styleUrls: ['./module-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleDetailsComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {
  }

}
