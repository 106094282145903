import { Component, EventEmitter, Input, OnInit, Output, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { siteStructureSubject$ } from '../../../services/store.service';
import { SharedService } from '../../../services/shared.service';
import { MustLinkMapper } from '../../../services/must-link.service';

@Component({
    selector: 'app-search-panel',
    templateUrl: './search-panel.component.html',
    styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit, AfterViewChecked {
    @Input() model;
    @Output() closeSearch = new EventEmitter<boolean>();
    @ViewChild('searchInput', {static: false}) searchElement: ElementRef;
    query: string;
    ctaModel: any;
    error: boolean;
    constructor(private router: Router, private translateService: TranslateService, private sharedService: SharedService) { }

    ngOnInit() {
      const siteStructure = siteStructureSubject$.value;
      const routeEducation = this.sharedService.getRelatedContentPage(siteStructure, 'field_school_page');
      this.ctaModel = MustLinkMapper.parseRelatedContent(routeEducation);
    }

    ngAfterViewChecked() {
        if (this.model.show) {
            this.searchElement.nativeElement.focus();

        }
    }

    doSearch() {
      if (this.query && this.query.length > 3) {
        const url = '/' + this.translateService.currentLang + '/search?q=' + this.query;
        this.router.navigateByUrl(url, {});
        this.error = false;
        this.setClose();
      } else {
        this.error = true;
      }
    }

    setClose() {
       this.closeSearch.emit(true);
    }
}
