import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { NormalizeCtaService } from './normalize-cta.service';
import { forkJoin, Observable, of } from 'rxjs';
import { CtaBlock } from 'src/app/shared/blocks/block-cta/model-block-cta';
import { LocalizeRouterService } from 'localize-router';
import { GetFieldService } from './get-field.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetCtaService {

  constructor(
    private sharedService: SharedService,
    private normalizeCtaService: NormalizeCtaService,
    private localize: LocalizeRouterService,
    private getFieldService: GetFieldService
  ) { }



  public getFieldCtaExternalArray(data, field: string, classStyle?): Promise<Array<object>> {
    return new Promise((resolve) => {
      const fieldCTaRelatedExt = data.attributes[field];
      const classCta = classStyle ? classStyle : null;
      const ctaRelatedExternal: [] = fieldCTaRelatedExt.map(singlectaDataRelatedExternal => {
        return this.normalizeCtaService.normalizeFieldCtaExternal(singlectaDataRelatedExternal, classCta);
      });
      resolve(ctaRelatedExternal);
    });
  }

  public getFieldCtaExternalSingle(data, field: string, classStyle?): Promise<CtaBlock> {
    return new Promise((resolve) => {
      const fieldCTaRelatedExt = data.attributes[field];
      const classCta = classStyle ? classStyle : null;
      const ctaRelatedExternal = this.normalizeCtaService.normalizeFieldCtaExternal(fieldCTaRelatedExt, classCta);
      resolve(ctaRelatedExternal);
    });
  }

  public getFieldCtaRelatedInternal(data, jsonapiIncluded) {
    return new Promise((resolve) => {
      const entityPage = this.sharedService.composeEntityPage(data, jsonapiIncluded);
      const relatedContentData = this.sharedService.getRelatedContentArrayPage(entityPage, 'field_cta_related_int_btn');
      const ctaRelatedInternal: [] = relatedContentData.map(singlerelatedContentData => {
        return this.normalizeCtaService.normalizeCtaRelatedContent(singlerelatedContentData, 'primary');
      });
      resolve(ctaRelatedInternal);
    });
  }

  public getFieldCtaRelatedInternalSecondary(data, jsonapiIncluded) {
    return new Promise((resolve) => {
      const entityPageSecondary = this.sharedService.composeEntityPage(data, jsonapiIncluded);
      const relatedContentDataSecondary = this.sharedService.getRelatedContentArrayPage(entityPageSecondary, 'field_cta_related_int_link');
      const ctaRelatedInternalSecondary: [] = relatedContentDataSecondary.map(singlerelatedContentDataSecondary => {
        return this.normalizeCtaService.normalizeCtaRelatedContent(singlerelatedContentDataSecondary, 'secondary');
      });
      resolve(ctaRelatedInternalSecondary);
    });
  }

  public getAllCtaCallToActionModule(data, jsonapiIncluded): Observable<Array<CtaBlock>> {
    return new Observable((observer) => {
      const allCta = [];
      if (data.attributes.field_cta_related_ext_btn) {
        allCta.push(this.getFieldCtaExternalArray(data, 'field_cta_related_ext_btn', 'primary'));
      }
      if (data.attributes.field_cta_related_ext_link) {
        allCta.push(this.getFieldCtaExternalArray(data, 'field_cta_related_ext_link', 'secondary'));
      }
      if (data.relationships.field_cta_related_int_btn.data) {
        allCta.push(this.getFieldCtaRelatedInternal(data, jsonapiIncluded));
      }
      if (data.relationships.field_cta_related_int_link.data) {
        allCta.push(this.getFieldCtaRelatedInternalSecondary(data, jsonapiIncluded));
      }
      forkJoin(allCta).subscribe(
        (allCtaArrays) => {
          let cta = null;
          if (allCtaArrays.length > 0) {
            cta = [].concat.apply([], allCtaArrays);
            const primary = cta.filter(c => c.class === 'primary');
            const secondary = cta.filter(c => c.class === 'secondary');
            cta = primary.concat.apply(primary, secondary);
            //cta = cta.sort((a) => (a.class === 'secondary') ? 1 : -1);
          }
          observer.next(cta);
          observer.complete();
        }
      );
    });
  }

  /**
   *
   * The method get cta of a module, if the module has cta like reference single page content
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */
  public getCtabyRelatedContent(data, jsonapiIncluded) {
    let ctasListSinglePage = [];
    if (data.relationships.field_single_page_content.data) {
      const CtaDatabyRelatedContent = this.sharedService.getRelatedArraybyIncludedObject(data, 'field_single_page_content', jsonapiIncluded);
      if (CtaDatabyRelatedContent && CtaDatabyRelatedContent.length > 0) {
        ctasListSinglePage = CtaDatabyRelatedContent.map(singleCtaData => {
          return this.normalizeCtaService.normalizeCtaRelatedContent(singleCtaData);
        });
      }
    }
    return ctasListSinglePage;
  }

  private getUrlCtaRelatedContentbyPreviewBlock(data): string {
    let url = null;
    const language = this.localize.parser.currentLang;
    const relatedContent = this.sharedService.getRelatedContentPage(data, 'field_related_content');
    if (relatedContent) {
      url = language + '/' + relatedContent.attributes.field_slug;
    }
    return url;
  }

  private getLabelCtaRelatedContentbyPreviewBlock(data): string {
    return this.getTitleRelatedContentbyPreviewBlock(data);
  }

  public getTitleRelatedContentbyPreviewBlock(data): string {
    let title = null;
    const relatedContent = this.sharedService.getRelatedContentPage(data, 'field_related_content');
    if (relatedContent) {
      if (relatedContent.attributes.field_cta_label_preview) {
        title = relatedContent.attributes.field_cta_label_preview;
      } else {
        title = this.getFieldService.fieldTitle(relatedContent);
      }
    }
    return title;
  }

  public getCtaRelatedContentbyPreviewBlock(entityData): CtaBlock {
    const customLabel = entityData.data.attributes.field_cta_label;
    let ctaRelatedContent: CtaBlock = null;
    const url = this.getUrlCtaRelatedContentbyPreviewBlock(entityData);
    const label = (customLabel) ? customLabel : this.getLabelCtaRelatedContentbyPreviewBlock(entityData);
    if (url) {
      ctaRelatedContent = {
        label,
        url,
        target: this.getFieldService.fieldTarget(entityData)
      };
    }
    return ctaRelatedContent;
  }

  public getCtabyPreviewBlock(data, included): CtaBlock {
    const fieldAlternativeCta = data.attributes.field_alternative_cta_link;
    let cta: CtaBlock = null;
    if (!fieldAlternativeCta) {
      const entityPage = this.sharedService.composeEntityPage(data, included);
      cta = this.getCtaRelatedContentbyPreviewBlock(entityPage);
    } else {
      cta = {
        label: this.getFieldService.fieldCtaLabel(data),
        url: fieldAlternativeCta.uri,
        target: true
      };
    }
    return cta;
  }

  /** todo Omar remove todo after Martina check
   *
   * The method get cta of a home hero module, if the module has cta like reference single page content
   * @private
   * @param {*} data
   * @param {*} jsonapiIncluded
   * @returns
   * @memberof ModuleDataNormalizerService
   */

  public getAllCtaHomeHeroModule(data, jsonapiIncluded): Observable<Array<CtaBlock>> {
    return new Observable((observer) => {
      const allCtaHomeHero = [];
      const notHasCta = data.attributes.field_external_link.length === 0 && data.relationships.field_single_page_content.data.length === 0;
      if (data.attributes.field_external_link) {
        allCtaHomeHero.push(this.getFieldCtaExternalArray(data, 'field_external_link'));
      }
      if (data.relationships.field_single_page_content) {
        allCtaHomeHero.push(this.getCtabyRelatedContent(data, jsonapiIncluded));
      }
      if (!notHasCta) {
        /** @todo change this part */
        forkJoin(allCtaHomeHero).subscribe((allCtaHomeHeroArrays) => {
          let cta = null;
          if (allCtaHomeHeroArrays.length > 0) {
            cta = [].concat.apply([], allCtaHomeHeroArrays);
          }
          observer.next(cta);
          observer.complete();
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

}
