import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpClientModule, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpService } from './httpErrorHandler.service';
import { forEach } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { CtaBlock } from 'src/app/shared/blocks/block-cta/model-block-cta';
import { GetCtaService } from './get-cta.service';
import { StoreService } from './store.service';
import { Observable, of } from 'rxjs';
import { IconPlusText, ImgTextColumn } from 'src/app/shared/blocks/block-img-text-column/model-imgTextColumn';
import { GetMediaEntityService } from './get-media-entity.service';
import { ImgTextRow } from 'src/app/shared/blocks/block-img-text-row/model-imgTextRow';
import { LocalizeRouterService } from 'localize-router';
import CarouselContent from 'src/app/shared/modules/module-carousel/model-carouselcontent';
import { GetFieldService } from './get-field.service';
import { ImgAutoHeightTextColumn } from 'src/app/shared/blocks/block-img-auto-height-text-column/model-imgAutoHeightTextColumn';
import { TextImgColumn } from 'src/app/shared/blocks/block-text-img-column/model-textImgColumn';
import { RouteDataService } from './route-data.service';
import { get } from 'lodash';
import { DetailsService } from './details.service';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root',
})
export class ViewService {
    perPage = 12;
    previewBlockCtaLabel: string;
    constructor(public httpService: HttpService,
                public httpClientModule: HttpClientModule,
                public httpClient: HttpClient,
                private translateService: TranslateService,
                private getCtaService: GetCtaService,
                private storeService: StoreService,
                private getMediaEntityService: GetMediaEntityService,
                private localize: LocalizeRouterService,
                private getFieldService: GetFieldService,
                private detailsService: DetailsService,
                private commonService: CommonService
                ) { }

    /** @todo remove */
    getPerPageItems() {
        return this.perPage;
    }

    /** old @todo remove */
    getViewData(entityType: string, filtersType: string, filtersString: string = '', page: number = 1) {
        const start = (page - 1) * this.perPage;
        const end = start + this.perPage;
        return this.httpClient.get<any>(environment.apiURL + environment.localeKeyPrefix[this.translateService.currentLang] + '/jsonapi/'
        + entityType + '/' + filtersType +
        '?include=field_category,field_files&page%5Boffset%5D=' +
        start + '&page%5Blimit%5D=' +
        this.perPage +
        '&' + filtersString)
            .pipe(catchError(err => this.httpService.handleError(err)));
    }

    /** old @todo remove */
    getTaxonomyFilter(term: string) {
        return this.httpClient.get<any>(environment.apiURL +
            environment.localeKeyPrefix[this.translateService.currentLang] +
            '/jsonapi/taxonomy_term/'
        + term + '?include=vid&sort=name')
            .pipe(catchError(err => this.httpService.handleError(err)));
    }

    public getViewEndpoint(counter = 0) {
      const entityPage: any = this.storeService.getCurrentPage();
      let viewEndpoint = null;
      if (entityPage.data.attributes.view_module_details) {
          const viewModuleDetails = JSON.parse(entityPage.data.attributes.view_module_details);
          if (viewModuleDetails[counter] && viewModuleDetails[counter].view_api) {
              viewEndpoint = environment.beUrl + '/' + viewModuleDetails[counter].view_api;
          }
      }
      return viewEndpoint;
  }

    private getPreviewBlockType(block): string {
        return block.type;
    }

    public getViewTitle(data): string {
        let title = null;
        if (data.attributes.field_title_bold) {
            title = data.attributes.field_title_bold.value;
        }
        return title;
    }

    public getViewLayout(data): string {
        let layout = null;
        if (data.attributes.field_fe_layout) {
            layout = data.attributes.field_fe_layout;
        }
        return layout;
    }

    public getViewCtaLayout(data, included): CtaBlock {
        let cta = null;
        const ctaData = this.getCtaService.getCtabyRelatedContent(data, included);
        if (ctaData) {
            cta = ctaData[0];
        }
        return cta;
    }

    public checkIsLayoutWithCta(layout): boolean {
        let isLayoutWithCta = false;
        if (layout === 'simple' || layout === 'big_title') {
            isLayoutWithCta = true;
        }
        return isLayoutWithCta;
    }

    private viewListContentError(err: HttpErrorResponse): Observable<any> {
        if (err) {
            return of({error: 'no content'});
          }
        throw err;
    }

    public getViewListContent(viewEndpoint, filters: any[] = [], page: number = 0) {
        const params = filters.reduce((a, c) => {
            const paramName = c.filterId + '[]' ;
            if (!a[paramName]) {
                a[paramName] = [c.key];
            } else {
                a[paramName].push(c.key);
            }
            return a;
          }, {});
        params.page = page;
        return this.httpClient.get(viewEndpoint, { params, withCredentials: true })
            .pipe(catchError(err => this.viewListContentError(err)));
    }

    private getLabelCta() {
        this.translateService.get('more').subscribe( res => this.previewBlockCtaLabel = res);
    }

    private getFieldDate(date): string {
        return date;
    }

    private getIconPlusTextFieldDate(data): IconPlusText {
        const IconPlusTextFieldDate = {} as IconPlusText;
        IconPlusTextFieldDate.icon = null;
        let fieldData = (data.field_data) ? data.field_data : data.field_date;
        if (!fieldData && data.field_date_string) {
            fieldData = data.field_date_string;
        }
        if (fieldData) {
            IconPlusTextFieldDate.title = this.getFieldDate(fieldData);
            const ListIcons = this.setIconPreviewBlock();
            if (ListIcons && ListIcons['field_duration']) {
                IconPlusTextFieldDate.icon = ListIcons['field_duration'];
            }
        }
        return IconPlusTextFieldDate;
    }

    private getPreviewBlockCta(block): CtaBlock {
        this.getLabelCta();
        const language = this.localize.parser.currentLang;
        const cta = {
            url: language + '/' + block.field_slug,
            label: (block.field_cta_label_preview) ? block.field_cta_label_preview : this.previewBlockCtaLabel,
            target: false
        };
        return cta;
    }

    private setIconPreviewBlock(): object {
        const icons = {
            field_extra_ticket: 'far fa-ticket-alt',
            field_locations: 'far fa-map-marker-alt',
            field_booking: 'fal fa-star',
            field_duration: 'fal fa-calendar-alt',
            field_data: 'fal fa-calendar-alt',
            field_accessibility: 'far fa-universal-access',
            field_scienzabile: 'scienziabile-icon',
            field_tag: 'far fa-hashtag',
        };
        return icons;
    }

    public getIconPreviewBlock(data, field): IconPlusText {
        const IconTextOfferPreview = {} as IconPlusText;
        IconTextOfferPreview.icon = null;
        if (data) {
            IconTextOfferPreview.title = data;
            const ListIcons = this.setIconPreviewBlock();
            if (ListIcons && ListIcons[field]) {
                IconTextOfferPreview.icon = ListIcons[field];
            }
        }
        return IconTextOfferPreview;
    }

    private getPreviewBlockCategory(block): string {
        let previewBlockCategory = null;
        if (block.field_categories) {
            previewBlockCategory = block.field_categories;
        }
        return previewBlockCategory;
    }

    private getPreviewBlockAttachment(block): any {
        let attachmentDownload = null;
        if (block.field_attachment) {
            attachmentDownload = {
                title:  block.field_attachment.replace('.pdf', ''),
                url: block.field_attachment,
                target: true,
                download: true
            };
        }
        return attachmentDownload;
    }

    private getPreviewBlockTopics(block): string {
        let previewBlockTopics = null;
        if (block.field_topic) {
            const previewBlockTopicsArray = block.field_topic.split(',');
            previewBlockTopics = previewBlockTopicsArray[0];
        }
        return previewBlockTopics;
    }

    private getPreviewBlockArticleCta(block): CtaBlock {
        this.getLabelCta();
        const articleCta = {} as CtaBlock;
        const label = (block.field_call_to_action_label) ? block.field_call_to_action_label : this.previewBlockCtaLabel;
        if (block.field_alternative_cta_link) {
            articleCta.url = block.field_alternative_cta_link;
            articleCta.label = label;
            articleCta.target = true;
        } else {
            articleCta.url = this.localize.parser.currentLang + '/' + block.field_related_content;
            articleCta.label = label;
        }
        return articleCta;
    }

    private getTaxonomyOfferPreviewBlock(block) {
        let taxonomy = null;
        if (block.field_tag) {
            taxonomy = this.getIconPreviewBlock(block.field_tag, 'field_tag');
        } else if (block.field_subject) {
            taxonomy = this.getIconPreviewBlock(block.field_tag, 'field_subject');
        }
        return taxonomy;
    }

    private normalizeOfferPreviewBlock(block): ImgTextColumn {
        const fieldImage = block.field_preview_image_media;
        const cta = this.getPreviewBlockCta(block);
        const dateAndTime = this.getEventsDateByPreview(block);
        const offerPreviewBlock = {
            block_type: 'ImgTextColumn',
            previewLayout: (block.field_preview_layout) ? block.field_preview_layout.toLowerCase() : 'generical',
            title: this.commonService.decodeString(this.getFieldService.fieldTitleView(block)),
            new: (block.field_new === 'On') ? true : false,
            age: (block.field_age) ? block.field_age : null,
            abstractText: (block.field_abstract) ? block.field_abstract : null,
            typology: (block.field_typology) ? block.field_typology : null,
            img: (fieldImage) ? this.getMediaEntityService.getFieldImageUrlbyUrl(fieldImage) : null,
            cta,
            duration: this.getIconPreviewBlock(block.field_duration, 'field_duration'),
            // tslint:disable-next-line: max-line-length
            extraTicket: (block.field_extra_ticket === 'Yes' ) ? this.getIconPreviewBlock(block.field_extra_ticket, 'field_extra_ticket') : null,
            bookable: this.getIconPreviewBlock(block.field_booking, 'field_booking'),
            note: get(block, 'field_note'),
            tag: this.getTaxonomyOfferPreviewBlock(block),
            // dateAndTime,
            accessibility: (block.field_accessibility) ? true : false,
            scienziabile: (block.field_scienzabile) ? true : false,
            subject: this.getIconPreviewBlock(block.field_scienzabile, 'field_scienzabile'),
        };
        return offerPreviewBlock;
    }

    private normalizeMediaPreviewBlock(block): ImgAutoHeightTextColumn {
        const fieldImage = block.field_preview_image_media;
        const mediaPreviewBlock = {
            block_type: 'ImgAutoHeightTextColumn',
            title: this.commonService.decodeString(block.field_title_bold),
            img: (fieldImage) ? this.getMediaEntityService.getFieldImageUrlbyUrl(fieldImage) : null,
            content: (block.field_abstract) ? block.field_abstract : null,
            label: this.commonService.decodeString(this.getPreviewBlockTopics(block)),
            cta: this.getPreviewBlockCta(block),
            dateAndTime: this.getIconPlusTextFieldDate(block)
        };
        return mediaPreviewBlock;
    }

    private normalizeWysiwygPreviewBlock(block): TextImgColumn {
        const lang = this.localize.parser.currentLang;
        const attachment = this.getPreviewBlockAttachment(block);
        const wysiwygPreviewBlock = {
            block_type: 'TextImgColumn',
            title: this.commonService.decodeString(block.field_title_bold),
            content: (block.field_content) ? block.field_content : null,
            cta: (block.field_ref_rel_internal_content) ? lang + '/' + block.field_ref_rel_internal_content : null,
            attachment: attachment ? [attachment] : []
        };
        return wysiwygPreviewBlock;
    }

    private normalizeArticlePreviewBlock(block): ImgTextRow {
        const articlePreviewBlock = {
            block_type: 'ImgTextRow',
            title: this.commonService.decodeString(block.field_title_bold),
            label: (block.field_label) ? this.commonService.decodeString(block.field_label) : null,
            // tslint:disable-next-line: max-line-length
            img: (block.field_background_image_media) ? this.getMediaEntityService.getFieldImageUrlbyUrl(block.field_background_image_media) : null,
            cta: this.getPreviewBlockArticleCta(block),
            // dateAndTime: this.getIconPlusTextFieldDate(block),
            content: block.field_content
        };
        return articlePreviewBlock;
    }

    private getEventsDateByPreview(block) {
        let date = null;
        date = {
          title : get(block, 'field_incoming_events_string'),
          icon: 'far fa-calendar-alt'
        };
        const fieldIncomingEvents = get(block, 'field_incoming_events', '[]');
        if (!date.title && fieldIncomingEvents) {
          const datesArrays = JSON.parse(fieldIncomingEvents.replace(/&quot;/g, '"')).map(JSON.parse);
          if (datesArrays) {
            const allDates = this.detailsService.normalizeIncomingEvents(datesArrays);
            if (allDates.length > 0) {
              date.title = allDates[0].label;
            }
          }
        }
        return (date.title) ? date : null;
      }

    private normalizePresskitPreviewBlock(block): ImgTextRow {
        const fieldImage = block.field_preview_image_media;
        const presskitPreviewBlock = {
            block_type: 'ImgTextRow',
            title: block.field_title_bold,
            img: (fieldImage) ? this.getMediaEntityService.getFieldImageUrlbyUrl(fieldImage) : null,
            content: (block.field_abstract) ? block.field_abstract : null,
            label: (block.field_subtitle) ? block.field_subtitle : null,
            dateAndTime: this.getIconPlusTextFieldDate(block),
            cta: this.getPreviewBlockCta(block)
        };
        return presskitPreviewBlock;
    }

    private normalizeEditorialPreviewBlock(block): ImgTextColumn {
        const fieldImage = block.field_preview_image_media;
        const editorialPreviewBlock = {
            block_type: 'ImgTextColumn',
            previewLayout: (block.field_preview_layout) ? block.field_preview_layout.toLowerCase() : 'generical',
            title: this.getFieldService.fieldTitleView(block),
            typology: (block.field_subtitle) ? block.field_subtitle : null,
            img: (fieldImage) ? this.getMediaEntityService.getFieldImageUrlbyUrl(fieldImage) : null,
            abstractText: (block.field_abstract) ? block.field_abstract : null,
            cta: this.getPreviewBlockCta(block)
        };
        return editorialPreviewBlock;
    }

    public getModulePreviewBlocks(items, moduleLayout) {
        let modulePreviewBlocks = items;
        const isCarousel = moduleLayout === 'simple' || moduleLayout === 'big_title';
        if (isCarousel) {
            modulePreviewBlocks = new CarouselContent(false, items, 'simple-slider');
            modulePreviewBlocks.extendConfig({
            loop: false,
            autoHeight: false,
            items: 3,
            responsive: {
                0: {
                items: 1,
                dots: true
                },
                500: {
                items: 1
                },
                600: {
                margin: 10,
                items: 3
                }
            }
            });
        }
        return modulePreviewBlocks;
    }

    public getPreviewBlock(block) {
        const type = this.getPreviewBlockType(block);
        let previewBlock = null;
        switch (type) {
          case 'offer':
          case 'Offer':
          case 'offer_school':
              // block_type: ImgTextColumn
                previewBlock = this.normalizeOfferPreviewBlock(block);
                break;
          case 'editorial_page':
              // block_type: ImgTextColumn
                previewBlock = this.normalizeEditorialPreviewBlock(block);
                break;
          case 'press_kit':
          case 'article':
          case 'Article Preview Block':
              // block_type: ImgTextRow
                previewBlock = this.normalizePresskitPreviewBlock(block);
                break;
          case 'article_topic_person':
                previewBlock = this.normalizeArticlePreviewBlock(block);
                break;
          case 'media':
              // block_type: ImgAutoHeightTextColumn
                previewBlock = this.normalizeMediaPreviewBlock(block);
                break;
          case 'WYSIWYG Text':
          case 'wysiwyg_text':
              // block_type: extImgColumn
                previewBlock = this.normalizeWysiwygPreviewBlock(block);
                break;
            default:
                previewBlock = this.normalizeOfferPreviewBlock(block);
                break;
        }
        return previewBlock;
    }

    public getFilters(counter = 0) {
        let filters = null;
        const currentPage: any = this.storeService.getCurrentPage();
        if (currentPage) {
            // tslint:disable-next-line: max-line-length
            const filterData = (currentPage['data'].attributes.view_module_details) ? currentPage['data'].attributes.view_module_details : null;
            if (filterData) {
                const filterDataParse = JSON.parse(filterData);
                if (filterDataParse[counter].exposed_filters) {
                    filters = filterDataParse[counter].exposed_filters.map( n => {
                      const options = [];
                      forEach(n.data, (v, k) => {
                        options.push({
                          filterId: n.id,
                          value: v,
                          key: k
                        });
                      });
                      const optionSorted = options.sort((a, b) =>
                      (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : ((b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0));
                      n.options = optionSorted;
                      return n;
                    });
                }
            }
        }
        return filters;
    }

    public getListBlocks(responseView) {
        return responseView.map(block => {
            return this.getPreviewBlock(block);
        });
    }
}
