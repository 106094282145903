import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Accordion } from './model-accordion';

@Component({
  selector: 'app-module-accordion',
  templateUrl: './module-accordion.component.html',
  styleUrls: ['./module-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleAccordionComponent implements OnInit {
  @Input()model: Accordion;

  constructor() { }

  ngOnInit() {
  }

}
