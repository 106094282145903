import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetDataCsvService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public getCsv(url): Observable<Blob> {
    const headers = new HttpHeaders();
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
    } = {
      responseType: 'blob'
    };

    return this.httpClient
      .get(url, options)
      .pipe(
        map((file: Blob) => {
          return file;
        })
      );
  }
}
