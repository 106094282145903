import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-block-card',
  templateUrl: './block-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BlockCardComponent implements OnInit {

  @Input() card;

  constructor() { }

  ngOnInit() {
  }

}
