import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-module-carousel',
  templateUrl: './module-carousel.component.html',
  styleUrls: ['./module-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class ModuleCarouselComponent implements OnInit, AfterViewChecked {
  customOptions: OwlOptions;
  @Input() model;
  currentslide = 1;
  totalSlides = 1;
  showCarousel = false;
  innerWidth;
  maxNumberItems = 3;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.customOptions = this.model.config;
    if (window.innerWidth <= 767) {
      this.maxNumberItems = 1;
    }
  }

  constructor(private cdRef: ChangeDetectorRef) { }

  afterChange(event) {
    this.currentslide = event.startPosition + 1;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth <= 767) {
      this.maxNumberItems = 1;
    }
    this.customOptions = this.model.config;
  }

  ngAfterViewChecked() {
    //window.dispatchEvent(new Event('resize'));
  }

}
