import { Injectable } from '@angular/core';
const htmlDecode = require('js-htmlencode').htmlDecode;

function _window() : any {
  // return the global native browser window object
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }
  get nativeWindow() : any {
    return _window();
  }
/**
 * getDate
 * @param {Date} Date intial format date
 * @return {String} Data String
 */
  public getDate(Initialdate) {
    const date = new Date(Initialdate);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const minutes = date.getMinutes();
    const hours = date.getHours();
    const seconds = date.getSeconds();
    const FormattedDate = day + ' / ' + (monthIndex + 1) + ' / ' + year;
    // @todo : decide format date and remove comment
    //const FormattedDate = day + '-' + (monthIndex + 1) + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
    return FormattedDate;
  }

  public decodeString(str: string) {
    return htmlDecode(str);
  }
}
