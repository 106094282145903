import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Lightbox } from 'ngx-lightbox';
import { LightboxImage } from 'src/app/core/services/model.lightboximage';
import 'hammerjs';
import { MediaLightboxService } from 'src/app/core/services/media-lightbox.service';

@Component({
    selector: 'app-module-carousel-images',
    templateUrl: './module-carousel-images.component.html',
    styleUrls: ['./module-carousel-images.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class ModuleCarouselImagesComponent implements OnInit {
    @Input() model;
    customOptions: OwlOptions;

    currentslide = 1;
    totalSlides = 1;
    showCarousel = false;
    albums = [];

    constructor(private cdRef: ChangeDetectorRef,
                private lightbox: Lightbox,
                private mediaLightboxService: MediaLightboxService) { }

    afterChange(event) {
        this.currentslide = event.startPosition + 1;
    }

    ngOnInit() {
        this.customOptions = this.model.config;
        this.model.images.forEach(element => {
            const lbImage: LightboxImage = {
                src: element.image,
                caption: element.description
            };
            this.mediaLightboxService.setLightboxImage(lbImage);
        });
    }
    openLightbox(image) {
        const lbImage: LightboxImage = {
            src: image
        };
        const index = this.mediaLightboxService.getLightboxImageIndex(lbImage);
        this.albums = this.mediaLightboxService.getLightboxAlbums();
        this.lightbox.open(this.albums, index);
    }
}
