import { Component, Input, OnInit } from '@angular/core';
import { PreviewEvent } from './model-preview-event';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import 'moment/min/locales';

@Component({
    selector: 'app-block-preview-event',
    templateUrl: './block-preview-event.component.html',
    styleUrls: ['./block-preview-event.component.scss']
})
export class BlockPreviewEventComponent implements OnInit {
    @Input() model: PreviewEvent;

    dateLabels: {};
    fileUri: string;
    constructor(private translateService: TranslateService) {
        this.fileUri = '';
    }

    ngOnInit() {
        this.model.date.locale(this.translateService.currentLang);
        this.dateLabels = {
            day: this.model.date.format('DD'),
            month: this.model.date.format('MMMM'),
            year: this.model.date.format('YYYY')
        };
        if (this.model.file) {
            this.fileUri = environment.beUrl + this.model.file['attributes'].uri.url;
        }
    }

}
