import { Component, Input, OnInit } from '@angular/core';
import { SliderTextBoxes } from './model-slider-text-boxes';

@Component({
  selector: 'app-block-slider-text-boxes',
  templateUrl: './block-slider-text-boxes.component.html',
  styleUrls: ['./block-slider-text-boxes.component.scss']
})
export class BlockSliderTextBoxesComponent implements OnInit {
  @Input() model: SliderTextBoxes;

  constructor() { }

  ngOnInit() {
  }

}
