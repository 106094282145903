import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-module-back',
  templateUrl: './module-back.component.html',
  styleUrls: ['./module-back.component.scss']
})
export class ModuleBackComponent implements OnInit {

  constructor(private location: Location) { }

  navigationBack(){
    this.location.back();
  }

  ngOnInit() {
  }

}
