import { Component, OnInit } from '@angular/core';
import { ExternalLinkComponent } from '../../blocks/external-link/external-link.component';

@Component({
  selector: 'app-multi-purpose',
  templateUrl: './multi-purpose.component.html',
  styleUrls: ['./multi-purpose.component.scss']
})
export class MultiPurposeComponent implements OnInit {
  items: any;

  constructor() {
    this.items = [
      {
        size: 1,
        type: ExternalLinkComponent
      },
      {
        size: 2,
        // type:RepeatDownloadLinkComponent
      },
      {
        size: 3,
       // type:RepeatDownloadLinkComponent
      },
      {
        size: 1,
       // type:RepeatDownloadLinkComponent
      },
      {
        size: 1,
       // type:RepeatDownloadLinkComponent
      },
      {
        size: 1,
       // type:RepeatDownloadLinkComponent
      }
    ];

  }

  ngOnInit() {
  }

}
