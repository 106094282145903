import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { InfoBox } from './model-infobox';
import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';

@Component({
  selector: 'app-block-info-boxes',
  templateUrl: './block-info-boxes.component.html',
  styleUrls: ['./block-info-boxes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockInfoBoxesComponent implements OnInit {
  @Input() model: InfoBox;

  public scrollObservable: Observable<boolean>;
  public observer: Observer<boolean>;

  constructor() {
  }

  ngOnInit() {
    this.scrollObservable = new Observable<boolean>((observer: any) => this.observer = observer).pipe(share());
  }


  onIntersection(event) {
    this.observer.next(true);
  }
}
