import { Component, Input, OnInit } from '@angular/core';
import { ModelSpacer } from './model.spacer';

@Component({
  selector: 'app-module-spacer',
  templateUrl: './module-spacer.component.html',
  styleUrls: ['./module-spacer.component.scss']
})
export class ModuleSpacerComponent implements OnInit {
  @Input() model: ModelSpacer;
  constructor() { }

  ngOnInit() {
  }

}
