import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TextImgColumn } from './model-textImgColumn';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-block-text-img-column',
  templateUrl: './block-text-img-column.component.html',
  styleUrls: ['./block-text-img-column.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockTextImgColumnComponent implements OnInit {
  @Input() model: TextImgColumn;
  htmlContent: any;
  environment = environment;
  constructor() { }

  ngOnInit() {
    this.htmlContent = this.model.content.replace(/(src=")(\/besrv\/)(.*?)"/, '$1' + this.environment.beUrl + '/$3"');
  }

}
