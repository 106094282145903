import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'app-module-masonry-preview-blocks',
  templateUrl: './module-masonry-preview-blocks.component.html',
  styleUrls: ['./module-masonry-preview-blocks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleMasonryPreviewBlocksComponent implements OnInit {
  @Input() model;
  options: NgxMasonryOptions = {};
  constructor() { }

  ngOnInit() {
    this.options = {
      gutter: 40,
      transitionDuration: '0.5s',
      resize: true,
      horizontalOrder: true,
      fitWidth: false
    };
  }

}
