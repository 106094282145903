import { Component, Input, OnInit } from '@angular/core';
import { FooterSocials } from '../footersocials.model';
import { FooterService } from '../../services/footer.service';

@Component({
  selector: 'app-module-social-sidebar',
  templateUrl: './module-social-sidebar.component.html',
  styleUrls: ['./module-social-sidebar.component.scss']
})
export class ModuleSocialSidebarComponent implements OnInit {

  @Input()
  footerSocials: FooterSocials;

  constructor(private footerService: FooterService) { }

  ngOnInit() {
  }

}
