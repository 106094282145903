import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetOpeningHoursWeekService {
  BASE_URL = environment.beUrl;
  CALENDAR_URL = '/calendar';
  constructor(
    private translateService: TranslateService,
    private http: HttpClient
  ) {
    this.BASE_URL = environment.beUrl + '/' + this.translateService.currentLang;
  }

  private sanitizerHour(hour) {
    let sanitizerHour = hour;
    if (sanitizerHour.length === 4) {
      sanitizerHour = sanitizerHour.replace(':', '');
      sanitizerHour = '0' + sanitizerHour;
      sanitizerHour = sanitizerHour.slice(0, 2) + ':' + sanitizerHour.slice(2, 4);
    }
    return sanitizerHour;
  }

  private normalizeOpeningHours(dataDay) {
    const todayUnix = moment().startOf('day').unix();
    const isToday = todayUnix.toString() === dataDay.timestamp;
    this.sanitizerHour(dataDay.opening);
    const hourDay = {
      day: dataDay.labeDay + ' ' + dataDay.d,
      close: !dataDay.is_open,
      hours: this.sanitizerHour(dataDay.opening) + ' - ' + this.sanitizerHour(dataDay.closing),
      isToday
    };
    return hourDay;
  }

  private setOpeningHours(dayObject, dayKey) {
    const labelDay = moment.unix(dayKey).format('dddd');
    dayObject.labeDay = labelDay;
    dayObject.timestamp = dayKey;
    return this.normalizeOpeningHours(dayObject);
  }

  public getOpeningHours() {
    return new Promise((resolve) => {
      this.getCalendarEventsForCurrentWeek().subscribe((hours: any) => {
        const openingHours = Object.keys(hours).map(item => {
          return this.setOpeningHours(hours[item], item);
        });
        resolve(openingHours);
      });
    });

  }

  public getCalendarEventsForCurrentWeek() {
    const today = new Date();
    let weekStartDay = today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1);
    let currentYear = today.getFullYear();
    let currentMonth = today.getMonth() + 1;
    if (weekStartDay <= 0) {
      currentMonth = currentMonth - 1;
    }
    if (currentMonth === 0) {
      currentMonth = 12;
      currentYear = currentYear - 1;
    }
    if (weekStartDay <= 0) {
      weekStartDay = new Date(currentYear, currentMonth, 0).getDate() + weekStartDay;
    }

    const url = this.BASE_URL + this.CALENDAR_URL + '/' + currentYear + '/' + currentMonth + '/' + weekStartDay;
    return this.http.get(url);
  }
}
