import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RouteDataService } from '../services/route-data.service';
import { LocalizeRouterService } from 'localize-router';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { siteStructureSubject$, StoreService } from '../services/store.service';
import { get } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ContentTypeResolver implements Resolve<string> {
    constructor(
        private localize: LocalizeRouterService,
        private routeDataService: RouteDataService,
        private storeService: StoreService
    ) { }
    /**
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {(Observable<string> | Observable<never>)}
     * @memberof ContentTypeResolver
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Observable<never> {
        return new Observable((observer) => {
          const lang = this.localize.parser.currentLang;
          let routeSlug = route['_routerState'].url.replace('/' + lang + '/', '');
          const redirectHomePage = routeSlug === '/' + lang;
          if (redirectHomePage) {
            const siteStructure = siteStructureSubject$.value;
            const relationshipFePage = siteStructure['data']['relationships'].field_front_page.data;
            if (relationshipFePage) {
              // tslint:disable-next-line: max-line-length
              const dataFePage = siteStructure['included'].filter((singleInclude) => singleInclude.id === relationshipFePage.id);
              routeSlug = dataFePage[0].attributes.field_slug;
            } else {
              const emptycontent: any = [];
              observer.next(emptycontent);
              observer.complete();
            }
          }
          const pageData = this.storeService.getPageData(routeSlug);
          if (!pageData) {
            this.routeDataService.getItemRouteData(routeSlug).subscribe(routeData => {
              if (routeData && Object.keys(routeData).length > 0 && !('error' in routeData)) {
                BreadcrumbsService.parseBreadcrumbs(routeData);
              }
              observer.next(routeData);
              observer.complete();
            });
          } else {
            BreadcrumbsService.parseBreadcrumbs(pageData);
            observer.next(pageData);
            observer.complete();
          }
        });
    }
}
