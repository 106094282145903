import { Component, Input, OnDestroy, OnInit, ViewEncapsulation, HostListener, ViewChild, ElementRef } from '@angular/core';
import { calendarSubject$ } from '../../../core/subjects/must-subjects';
import * as moment from 'moment';
import { IModuleCalendarModel } from '../../models/module-calendar.model';
import { CalendarService } from '../../../core/services/calendar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-module-calendar',
  templateUrl: './module-calendar.component.html',
  styleUrls: ['./module-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleCalendarComponent implements OnInit, OnDestroy {
  @ViewChild('calendaResults', {static: false}) calendaResults: ElementRef;
  @Input()
  model: any;
  calendarData: IModuleCalendarModel = {};
  calendarSubjectSubscription;
  currentDay: Date;
  mobileOpen = false;
  innerWidth;
  mobileCalendar: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 991) {
      this.mobileCalendar = true;
    } else {
      this.mobileCalendar = false;
    }
  }

  constructor(
    private calendarService: CalendarService, 
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location) {
  }

  blocks: any[];

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.mobileCalendar = this.innerWidth <= 991;
    this.calendarData = { ...this.model};
    
    this.calendarSubjectSubscription = calendarSubject$.asObservable().subscribe( date => {
      if (!date) {
        return;
      }
      this.currentDay = date;
      this.calendarData.day = moment(date).format('DD');
      this.calendarData.dayNumber = moment(date).format('D');
      this.calendarData.month = moment(date).format('MMMM');
      this.calendarData.monthNumber = moment(date).format('MM');
      this.calendarData.year = moment(date).format('YYYY');

      let dateUrl = `${window.location.pathname}?date=${this.calendarData.year}-${this.calendarData.monthNumber}-${this.calendarData.day}`
  
      window.history.replaceState({path:dateUrl},'',dateUrl);

      this.calendarService.getEventsBlocks(this.currentDay).subscribe( blocks => {
        this.blocks = [...blocks];
      });
    });

    
  }

  ngOnDestroy(): void {
    this.calendarSubjectSubscription.unsubscribe();
    calendarSubject$.next(null);
  }

  scrollToCalendaResults(el: HTMLElement) {
    setTimeout(() => {
      window.scrollTo({top: this.calendaResults.nativeElement.offsetTop - 90, behavior: 'smooth'});
    }, 500);
  }
}
