import { Component, Input, OnInit } from '@angular/core';
import { PreviewOuterText } from './model-preview-outer-text';

@Component({
  selector: 'app-block-preview-outer-text',
  templateUrl: './block-preview-outer-text.component.html',
  styleUrls: ['./block-preview-outer-text.component.scss']
})
export class BlockPreviewOuterTextComponent implements OnInit {
  @Input() model: PreviewOuterText;
  constructor() { }

  ngOnInit() {
  }

}
