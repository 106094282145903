import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SharedService } from './shared.service';
import { BehaviorSubject } from 'rxjs';

export const siteStructureSubject$ = new BehaviorSubject(null);
export const menuDataSubject$ = new BehaviorSubject(null);
export const alternateLanguageSubject$ = new BehaviorSubject(null);
export const openingHoursSubject$ = new BehaviorSubject(null);

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private sharedService: SharedService
  ) { }

  pageStore: any = {};
  currentPage: any = {};

  /**
   * getMainMenu
   * @return {Object} Object with data of Main Menu
   */
  public getMenu() {
    return menuDataSubject$.value;
  }

  public getOpeningHours() {
    return openingHoursSubject$.value;
  }

  /**
   * getMainMenu
   * @return {Object} Object with data of Main Menu
   */
  public getMainMenu() {
    return menuDataSubject$.value['main_menu'];
  }

  /**
   * getMainMenu
   * @return {Object} Object with data of Main Menu
   */
  public getTopMenu() {
    return menuDataSubject$.value['top_menu'];
  }
  /**
   *
   *
   * @returns
   * @memberof StoreService
   */
  public getSiteLogo() {
    const siteStructure = siteStructureSubject$.value;
    const siteStructureData = siteStructure['data'];
    const siteStructureIncluded = siteStructure['included'];
    let imageUrl = '../../../assets/images/must.svg';

    if (siteStructureData) {
      const imageUploadId = siteStructureData.relationships['field_image_upload']['data'] ?
        siteStructureData.relationships['field_image_upload']['data']['id'] : null;

      if (imageUploadId) {
        const imageData = siteStructureIncluded.filter((singleInclude) => singleInclude.id === imageUploadId);
        // @TODO remove if condition and return data
        if (imageData) {
          imageUrl = environment.beUrl + imageData[0].attributes.uri.url;
        }
      }

    }

    return imageUrl;
  }

  /**
   *
   * @returns {Object} button main call to action
   *
   */
  public getSiteStructure() {
    return siteStructureSubject$.value;
  }

  /**
   * The function get websiteMap url by site structure in the store
   * @returns {string} websiteMap url
   * @memberof StoreService
   */
  public getWebsiteMap(): string {
    let websiteMap = null;
    const siteStructure: any = this.getSiteStructure();
    if (siteStructure.data.relationships.field_website_map.data) {
      const websiteMapData = this.sharedService.getRelatedContentPage(siteStructure, 'field_website_map');
      if (websiteMapData) {
        websiteMap = environment.beUrl + websiteMapData.attributes.uri.url;
      }
    }
    return websiteMap;
  }

  public getWebsiteConfigs(): object {
    let websiteConfigs = null;
    const siteStructure: any = this.getSiteStructure();
    if (siteStructure.data.attributes.configs) {
      const websiteConfigsData = JSON.parse(siteStructure.data.attributes.configs);
      if (websiteConfigsData) {
        websiteConfigs = websiteConfigsData;
      }
    }
    return websiteConfigs;
  }

  public getConfigImagesStyle(): object {
    let imagesStyle = null;
    const websiteConfigs = this.getWebsiteConfigs();
    if (websiteConfigs && websiteConfigs['image_styles']) {
      imagesStyle = websiteConfigs['image_styles'];
    }
    return imagesStyle;
  }

  public savePageData(slug: string, data: any) {
    this.pageStore[slug] = data;
  }

  public getPageData(slug: string) {
    return this.pageStore[slug];
  }

  public saveCurrentPage(currentPage: any) {
    this.currentPage = currentPage;
  }

  public getCurrentPage() {
    return this.currentPage;
  }
}




