import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DetailBlock } from './model-detail';


@Component({
  selector: 'app-block-detail',
  templateUrl: './block-detail.component.html',
  styleUrls: ['./block-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockDetailComponent implements OnInit {
  @Input() model: DetailBlock;

  constructor() { }

  ngOnInit() {
  }

}
