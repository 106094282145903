import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-module-thematic-blocks',
  templateUrl: './module-thematic-blocks.component.html',
  styleUrls: ['./module-thematic-blocks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleThematicBlocksComponent implements OnInit {
  @Input() model: any;

  constructor() { }

  ngOnInit() {

  }

}
