import { Component, Input, OnInit } from '@angular/core';
import { PreviewCard } from './model-preview-card';

@Component({
  selector: 'app-block-preview-card',
  templateUrl: './block-preview-card.component.html',
  styleUrls: ['./block-preview-card.component.scss']
})
export class BlockPreviewCardComponent implements OnInit {
  @Input() model: PreviewCard;
  constructor() { }

  ngOnInit() {
  }

}
