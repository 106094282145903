import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-module-key-fact',
  templateUrl: './module-key-fact.component.html',
  styleUrls: ['./module-key-fact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleKeyFactComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {
  }

}
