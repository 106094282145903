import { Component, Input, OnInit } from '@angular/core';
import { CtaModule } from './model-cta';

@Component({
  selector: 'app-module-cta',
  templateUrl: './module-cta.component.html',
  styleUrls: ['./module-cta.component.scss']
})
export class ModuleCtaComponent implements OnInit {
  @Input() model: CtaModule;
  constructor() { }

  ngOnInit() {
  }

}
