import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-press-release',
  templateUrl: './press-release.component.html',
  styleUrls: ['./press-release.component.scss']
})
export class PressReleaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
