import { Injectable } from '@angular/core';
import { LightboxImage } from './model.lightboximage';

@Injectable({
  providedIn: 'root'
})
export class MediaLightboxService {
  albums = [];
  constructor() { }

  public setLightboxImage(image: LightboxImage) {
    if (image && image.src) {
      this.albums.push(image);
    }
  }
  public getLightboxImageIndex(image: LightboxImage) {
    return this.albums.findIndex(el => el.src === image.src);
  }
  public getLightboxAlbums() {
    return this.albums;
  }
}
