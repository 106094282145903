import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-module-slider',
  templateUrl: './module-slider.component.html',
  styleUrls: ['./module-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleSliderComponent implements OnInit {
  customOptions: OwlOptions;

  @Input() model;
  currentslide = 1;
  totalSlides = 1;
  constructor() { }

  afterChange(event) {
    this.currentslide = event.startPosition + 1;
  }

  ngOnInit() {
    this.customOptions = this.model.config;
  }

}
