import { Injectable } from '@angular/core';
import { LocalizeRouterService } from 'localize-router';
import { CtaBlock } from 'src/app/shared/blocks/block-cta/model-block-cta';
import { environment } from 'src/environments/environment';
import { GetFieldService } from './get-field.service';

@Injectable({
  providedIn: 'root'
})
export class NormalizeCtaService {

  constructor(
    public localize: LocalizeRouterService,
    public getFieldService: GetFieldService
  ) { }

  public normalizeCtaRelatedContent(data, classStyle?):  CtaBlock{
    const language = this.localize.parser.currentLang;
    const label = this.getFieldService.fieldTitle(data);
    const url = environment.localeKeyPrefix[language] + '/' + data.attributes.field_slug;
    const cta: CtaBlock = {
      label,
      url,
      target: false,
      class: (classStyle) ? classStyle : null
    };
    return cta;
  }

  public normalizeFieldCtaExternal(data, classStyle?): CtaBlock {
    const label = data.title;
    const url = data.uri;
    const cta: CtaBlock = {
      label,
      url,
      target: true,
      class: (classStyle) ? classStyle : null
    };
    return cta;
  }

  public normalizeFieldExternalLink(data): CtaBlock {
    const label = data.title;
    const url = data.uri;
    const cta: CtaBlock = {
      label,
      url,
      target: true,
    };
    return cta;
  }
}
