import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ModuleVideo } from '../../shared/modules/module-video/video';
import CarouselImagesContent from 'src/app/shared/modules/module-carousel-images/model-carousel-imagescontent';
import { MediaDetails, MediaTitle } from 'src/app/feature/media/media.component';
import { SharedService } from './shared.service';
import { forkJoin, Observable } from 'rxjs';
import { DetailsService } from './details.service';
import { DetailBlock } from 'src/app/shared/blocks/block-detail/model-detail';
import { GetMediaEntityService } from './get-media-entity.service';
import { ModuleSingleImage } from 'src/app/shared/modules/module-single-image/model-single-image-module';
import { GetFieldService } from './get-field.service';

export interface ModuleTitle {
  title: string;
  subtitle?: string;
}

export interface ModuleGallery {
  images: Array<object>;
}

@Injectable({
  providedIn: 'root'
})

export class MediaService {
  constructor(
    private sharedService: SharedService,
    private detailsService: DetailsService,
    private getMediaEntityService: GetMediaEntityService,
    private getFieldService: GetFieldService
  ) { }

  /**
   * The function get title of media page, field date and concatenate field topic (taxonomy).
   * @param {*} mediaPage
   * @returns {Promise<MediaTitle>}
   * @memberof MediaService
   */
  public getMediaTitle(mediaPage): Promise<MediaTitle> {
    return new Promise((resolve, reject) => {
      const mediaData = {} as MediaTitle;
      if (mediaPage.data.attributes.field_title_bold) {
        mediaData.title = mediaPage.data.attributes.field_title_bold.value;
      }
      if (mediaPage.data.attributes.field_date_string) {
        mediaData.date = mediaPage.data.attributes.field_date_string.value;
      }
      if (mediaPage.data.relationships.field_topic.data) {
        let mediaPageTaxonomy = [];
        const relatedContent = this.sharedService.getRelatedContentArrayPage(mediaPage, 'field_topic');
        if (relatedContent) {
          mediaPageTaxonomy = relatedContent.map(content => content.attributes.name);
        }
        mediaData.taxonomy = mediaPageTaxonomy.toString();
      }
      resolve(mediaData);
    });
  }

  /**
   * getModuleGalleryOnlyImages gallery with configuration full with for carousel only images
   * @param {object} init_data Initial object data
   * @return {object} data gallery for module gallery
   */
  public getModuleGalleryOnlyImages(initdata) {
    return new Promise((resolve) => {
      let module = null;
      const moduleGallery: ModuleGallery = { images: [] };
      const fieldImagesList = initdata.data.relationships.field_images_list_media;
      if (fieldImagesList && fieldImagesList.data) {
        moduleGallery.images = fieldImagesList.data.map(field => ({
          image : this.getMediaEntityService.getFieldImageUrlbyMeta(field),
          description: this.getFieldService.getDescriptionImage(field),
          alt: this.getFieldService.getAltImage(field)
        }));
        if (moduleGallery.images) {
          const imagesContent = new CarouselImagesContent(true, moduleGallery.images, 'gallery');
          imagesContent.extendConfig({
            loop: true,
            center: true,
            margin: 20,
            responsive: {
              0: {
                items: 1.4
              },
              500: {
                items: 1.4,
                margin: 15
              },
              600: {
                margin: 20,
                items: 1.4
              }
            }
          });
          module = imagesContent;
        }
        resolve(module);
      }
    });
  }

  /**
   * getModuleGallery gallery_only_images_module
   * @param {object} init_data Initial object data
   * @return {object} data gallery for module gallery
   */
  public getModuleGallery(initdata) {
    return new Promise((resolve, reject) => {
      const moduleGallery: ModuleGallery = { images: [] };
      if (initdata.data && initdata.data.relationships.field_images_list && initdata.data.relationships.field_images_list.data) {
        initdata.data.relationships.field_images_list.data.forEach(imagelist => {
          const image = initdata.included.filter((singleinclude) => singleinclude.id === imagelist.id);
          moduleGallery.images.push({ image: environment.beUrl + image[0].attributes.uri.url });
        });
        resolve(moduleGallery);
      }
    });
  }

  /**
   * getModuleVideo
   * @param {object} init_data Initial object data
   * @return {object} data video for module video
   */
  public getModuleVideo(initdata) {
    return new Promise((resolve, reject) => {
      const moduleVideo: ModuleVideo = { title: '', url: '', hero: false };
      if (initdata.data.attributes.field_video) {
        const video = initdata.data.attributes.field_video;
        moduleVideo.title = video.title;
        moduleVideo.url = video.uri;
        resolve(moduleVideo);
      }
    });
  }

  /**
   * The function return one single detail block
   * @private
   * @param {object} mediaPage
   * @param {string} field
   * @param {string} [type]
   * @returns {DetailBlock}
   * @memberof MediaService
   */
  private getDetailBlock(mediaPage, field, type?): Observable<DetailBlock> {
    return new Observable((observer) => {
      const detailData = this.sharedService.getRelatedContentPage(mediaPage, field);
      if (detailData) {
        this.detailsService.setDetail(detailData, mediaPage, type).then(
          detail => {
            observer.next(detail);
            observer.complete();
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  /**
   * The function get detailblock object for field_ref_exhib_det, for field_asset_location and for field_asset_storage.
   * @param {*} mediaPage
   * @returns {Observable<MediaDetails>}
   * @memberof MediaService
   */
  public getMediaDetails(mediaPage): Observable<MediaDetails> {
    return new Observable((observer) => {
      const detailExhibition = this.getDetailBlock(mediaPage, 'field_ref_exhib_det');
      const detailLocation = this.getDetailBlock(mediaPage, 'field_asset_location', 'field_related_where');
      const detailStorage = this.getDetailBlock(mediaPage, 'field_asset_storage', 'field_storage');
      forkJoin(
        [ detailExhibition,
          detailLocation,
          detailStorage]
        ).subscribe((
        ([exhibition, location, storage]) => {
          const detailsObject = {} as MediaDetails;
          if (exhibition) {
            detailsObject['exhibition'] = exhibition;
          }
          if (location) {
            detailsObject['location'] = location;
          }
          if (storage) {
            detailsObject['storage'] = storage;
          }
          observer.next(detailsObject);
          observer.complete();
        }
      ));
    });
  }

  public getMediaSingleImage(mediaPage): ModuleSingleImage {
    const moduleSingleImage = {} as ModuleSingleImage;
    const fieldfExhibImage = mediaPage.data.relationships.field_exhib_image;
    if (fieldfExhibImage && fieldfExhibImage.data) {
      moduleSingleImage.image = this.getMediaEntityService.getFieldImageUrlbyMeta(fieldfExhibImage.data);
      moduleSingleImage.layoutStyle = 'stripe';
    }
    return moduleSingleImage;
  }

}
