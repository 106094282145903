import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ItemHeader } from '../../model/itemheader.model';
import { alternateLanguageSubject$, openingHoursSubject$ } from '../../../services/store.service';
import { GetConfigurationHeaderService } from '../../services/get-configuration-header.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { Router } from '@angular/router';
@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TopHeaderComponent implements OnInit {
    @Output() activateCountry = new EventEmitter<boolean>();
    @Input() model;
    searchModel = {};
    languages = [];
    countries = [];
    currentLanguage = '';
    currentCountry = {};
    itemActiveCountry: ItemHeader;
    countryNavActive = false;
    openingHours;
    openingHoursToday: object;
    alternativeLanguage;
    constructor(
        private getConfigurationHeaderService: GetConfigurationHeaderService,
        private translateService: TranslateService,
        private localize: LocalizeRouterService,
        private router: Router
    ) {
        this.searchModel['show'] = false;
        this.languages = Object.keys(environment.localeKeyPrefix);
        this.countries = [];
        this.currentLanguage = translateService.currentLang;
        this.currentCountry = {};
    }
    ngOnInit() {
        this.openingHours = openingHoursSubject$.value;
        this.alternativeLanguage = this.getConfigurationHeaderService.getAlternativeLanguage();
    }

    openSearch() {
        this.searchModel['show'] = true;
    }

    closeSearch() {
        this.searchModel['show'] = false;
    }

    openCountrySelect() {
        this.activateCountry.emit(true);
        this.countryNavActive = true;
    }

    closeCountrySelect() {
        this.activateCountry.emit(false);
        this.countryNavActive = false;
    }

    switchLanguage(lang) {
        /** @todo unire metodo con quello mobile */
        this.translateService.use(lang).subscribe(() => {
            this.currentLanguage = lang;
            this.router.navigateByUrl(lang);
        });
    }

    countrySwitch(event) {
        for (const key in environment.localeKey) {
            if (environment.localeKey.hasOwnProperty(key)) {
                const element = environment.localeKey[key];
                if (element === event.value) {
                    // Window.location.href is used to force indexdb rebuild
                    window.location.href = environment.localeKeyPrefix[key];
                    break;
                }
            }
        }
    }
}
