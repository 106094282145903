import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-repeat-numbers',
  templateUrl: './repeat-numbers.component.html',
  styleUrls: ['./repeat-numbers.component.scss']
})
export class RepeatNumbersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
