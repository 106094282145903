import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-module-title',
  templateUrl: './module-title.component.html',
  styleUrls: ['./module-title.component.scss'],
})
export class ModuleTitleComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {
  }

}
