import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-module-row-preview-blocks',
  templateUrl: './module-row-preview-blocks.component.html',
  styleUrls: ['./module-row-preview-blocks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModuleRowPreviewBlocksComponent implements OnInit {
  @Input() model;
  constructor() { }

  ngOnInit() {
  }

}
