import { Injectable } from '@angular/core';
import { LocalizeRouterService } from 'localize-router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class BlockDataNormalizerService {
    viewMoreLabel: string;
    constructor(
        public translateservice: TranslateService,
        public localize: LocalizeRouterService,
    ) {
        this.translateservice.get('view more').subscribe(
            label => {
                this.viewMoreLabel = label;
            });
    }

    private getRelatedImage(data, jsonapiIncluded) {
        let image;
        if (data.relationships.field_image && data.relationships.field_image.data) {
            image = jsonapiIncluded.filter((singleinclude) => singleinclude.id === data.relationships.field_image.data.id);
        }

        return image;
    }

    private getRelatedSingleCta(data, jsonapiIncluded) {
        const cta = {
            cta: '',
            url: ''
        };
        if (data.relationships.field_related_content && data.relationships.field_related_content.data) {
            const related = jsonapiIncluded.filter((singleinclude) =>
                singleinclude.id === data.relationships.field_related_content.data[0].id);
            cta.cta = (related[0].attributes.field_title_bold) ? related[0].attributes.field_title_bold.value : this.viewMoreLabel;
            cta.url = this.localize.parser.currentLang + '/' + related[0].attributes.fe_route;
        }
        return cta;
    }

    private getRelatedBackgroundImage(data, jsonapiIncluded) {
        let image: object;
        if (data.relationships.field_background_image && data.relationships.field_background_image.data) {
            image = jsonapiIncluded.filter((singleinclude) => singleinclude.id === data.relationships.field_background_image.data.id);
        }
        return image;
    }
    private getCtaEditorialBlocks(data, jsonapiIncluded) {
        const cta = {
            url: '',
            cta: ''
        };
        if (data.relationships.field_slides_editorial_blocks && data.relationships.field_slides_editorial_blocks.data) {
            data.relationships.field_slides_editorial_blocks.data.forEach(singleCta => {
                const ctaContent = jsonapiIncluded.filter((singleinclude) => singleinclude.id === singleCta.id);
                if (!ctaContent[0].field_alternative_cta_link) {
                    const internalCta = this.getRelatedSingleCta(ctaContent[0], jsonapiIncluded);
                    cta.url = internalCta.url;
                    cta.cta = internalCta.cta;
                } else {
                    cta.url = ctaContent[0].field_alternative_cta_link.uri;
                    cta.cta = ctaContent[0].field_alternative_cta_link.title;
                }
            });
        }
        return cta;
    }

    public get_blocks_data(data, jsonapiIncluded) {
    }
}
